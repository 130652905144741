import React, { Component } from "react";
import { connect } from "react-redux";
import {
  completePayment,
  createSelfTransfer,
  fetchProfileWallet,
  fetchProfileWallets,
  fetchWalletTransactions,
  initTopUpTxn,
  updateInheritTxnStage,
  updateWalletUtilityState,
} from "../store/actions/walletActions";
import { searchPProfiles } from "../store/actions/usersActions";
import SuccessAnim from "./SuccessAnim";
import FailedAnim from "./FailedAnim";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

class TopupWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    destProfiles: [],
    tProfile: {},
    txnAmount: 0.0,
    txnType: null,
    txnId: null,
    isLoading: false,
    otp: null,
    hasTransactionId: false,
    reference: "",
    originWallet: "TRADER",
    errMSG: "",
    hasError: false,
  };

  componentDidMount() {}

  initCardTopUp() {
    this.setState({ isLoading: true });
    let wId = this.getCoreId()[0].walletId;
    this.props
      .startTopUpTxn(wId, this.state.txnAmount)
      .then(() => {
        this.setState({ hasTransactionId: true, hasError: false });
      })
      .catch((err) => {
        this.setState({
          hasTransactionId: false,
          hasError: true,
          errMSG: err.response.data.err,
        });
        // this.props.updateTxnStage(-1);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  confirmTxn(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .verify(this.state.txnId, this.state.otp)
      .then(() => {
        this.setState({ destProfiles: [], otpStage: false, hasError: false });
        this.props.updateUtilityState({ isSuccess: true });
        this.props.getProfileWallet(this.props.wallet.walletId);
        this.props.getTransactions(this.props.wallet.walletId, 0, 10);
        // this.props.updateTxnStage(10);
      })
      .catch((err) => {
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: true,
          otpStage: false,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  selectTProfile(profile) {
    this.setState({ tProfile: profile });
    this.props.updateTxnStage(1);
  }

  getProfiles(number) {
    this.props.fetchPProfiles(number).then(() => {
      this.setState({ destProfiles: this.props.destProfiles });
    });
  }

  initTxn = (e) => {
    e.preventDefault();
    let origin = this.state.originWallet;
    if (this.props.wallet.walletId.walletType === this.state.originWallet) {
      origin = this.state.originWallet === "CORE" ? "TRADER" : "CORE";
    }

    this.setState({ isLoading: true });
    this.props
      .startTxn(
        origin,
        this.props.wallet.walletId.walletType,
        this.state.txnAmount,
        this.state.reference,
        this.props.profile.profileType
      )
      .then(() => {
        this.setState({ txnId: this.props.txnId, hasError: false });
        this.props.updateUtilityState({ isSuccess: true });
        this.props.getProfileWallet(this.props.wallet.walletId);
        this.props.getTransactions(this.props.wallet.walletId, 0, 10);
        this.props.updateUtilityState({
          otpStage: false,
        });
      })
      .catch((err) => {
        // this.props.updateTxnStage(-1);
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: true,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getCoreId() {
    return this.props.wallets.filter((w) => w.walletId.walletType === "CORE");
  }

  render() {
    return (
      <div id="topupmodal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-sm-0 pd-lg-50">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                {this.props.txnStage === 0 && !this.state.isLoading ? (
                  this.props.wallet.walletId ? (
                    this.props.wallet.walletId.walletType === "CORE" ? (
                      this.props.updateTxnStage(1)
                    ) : (
                      (this.props.updateTxnStage(2),
                      this.setState({ originWallet: "CORE" }))
                    )
                  ) : (
                    (this.props.updateTxnStage(2),
                    this.setState({ originWallet: "CORE" }))
                  )
                ) : (
                  <></>
                )}

                {this.props.txnStage === 1 ? (
                  <>
                    <div className="modal__container">
                      <div className=" d-none d-xl-block modal__featured">
                        <div className="modal__circle"></div>
                        <div className="modal__product hidden-xs">
                          <img
                            style={{
                              marginTop: "12em",
                              marginBottom: "2em",
                              maxWidth: "124%",
                            }}
                            alt=""
                            src="/assets/img/dumelapay-clear.png"
                          />
                          <p style={{ fontSize: "17px", color: "white" }}>
                            Top up your dumelaPay Wallet from your preferred
                            bank.
                          </p>
                        </div>
                      </div>
                      <div className="modal__content">
                        {this.props.isCardTopUp ? (
                          <>
                            <h2>Bank Card Topup</h2>
                            <form
                              method="post"
                              action="https://secure.paygate.co.za/payweb3/process.trans"
                              style={{ marginTop: "7em" }}
                            >
                              <ul className="form-list">
                                <input
                                  name="CHECKSUM"
                                  value={this.props.pg_checksum}
                                  hidden
                                />
                                <input
                                  type="text"
                                  name="PAY_REQUEST_ID"
                                  value={this.props.pg_requestId}
                                  hidden
                                  readOnly
                                />

                                {this.state.hasTransactionId ? (
                                  <div>
                                    <h4>Transaction summary</h4>
                                    <p>
                                      Transfer amount:
                                      <span style={{ fontSize: "33px" }}>
                                        P {this.state.txnAmount}
                                      </span>
                                    </p>
                                    <p>Reference: {this.props.txnId}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <p
                                  className="text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.hasError ? this.state.errMSG : ""}
                                </p>
                                <li
                                  className="form-list__row"
                                  style={
                                    this.state.hasTransactionId
                                      ? { display: "none" }
                                      : { display: "block" }
                                  }
                                >
                                  <label>Amount</label>
                                  <div
                                    id="input--cc"
                                    className="creditcard-icon"
                                  >
                                    <input
                                      className="payment_wizard_input"
                                      type="number"
                                      step=".01"
                                      name="p4"
                                      min={1}
                                      required
                                      value={this.state.txnAmount}
                                      onChange={(e) =>
                                        this.setState({
                                          txnAmount: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="summary-item">
                                    {this.state.hasTransactionId ? (
                                      <>
                                        <div className="form-group checkbox_input">
                                          <label className="container_checkbox mr-5">
                                            <input
                                              type="checkbox"
                                              name="termsandconditions"
                                              value={false}
                                              className="required mr-4"
                                              required
                                            />
                                            <span className="checkmark"></span>
                                            <NavLink to="/terms">
                                              Terms and Conditions
                                            </NavLink>
                                          </label>
                                        </div>
                                        <button
                                          type="submit"
                                          className="btn btn-oblong btn-primary btn-block mg-b-10"
                                          style={{
                                            paddingLeft: "2rem",
                                            paddingRight: "2rem",
                                          }}
                                        >
                                          Confirm transaction
                                        </button>
                                      </>
                                    ) : (
                                      <div
                                        onClick={() => this.initCardTopUp()}
                                        className="btn btn-oblong btn-primary btn-block mg-b-10"
                                        style={{
                                          paddingLeft: "2rem",
                                          paddingRight: "2rem",
                                        }}
                                      >
                                        submit
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </form>
                          </>
                        ) : this.props.isWalletTopUp ? (
                          <>
                            <h2>DumelaPay wallet Top up</h2>
                            {this.state.isLoading ? (
                              <div
                                className="pd-y-100"
                                style={{ display: "flex" }}
                              >
                                <div>
                                  <h4>Transaction details</h4>
                                  <p>Amount: P {this.state.txnAmount}</p>
                                  <p>
                                    Origin:
                                    {this.props.wallet.walletId.walletType ===
                                    "CORE"
                                      ? " TRADER "
                                      : " CORE "}
                                    Wallet
                                  </p>
                                  <p>
                                    Destination:{" "}
                                    {this.props.wallet.walletId.walletType}{" "}
                                    wallet
                                  </p>
                                </div>
                                <h3 className="tx-gray-800 tx-normal mg-b-5 pd-y-50 pd-x-50">
                                  Proccessing ....
                                </h3>
                              </div>
                            ) : this.props.isSuccess ? (
                              <div className="text-center">
                                <SuccessAnim />
                                <p>Successful</p>
                              </div>
                            ) : this.props.isFailure ? (
                              <div className="text-center">
                                <FailedAnim />
                                <p>Transaction Failed</p>
                              </div>
                            ) : this.props.otpStage ? (
                              <form
                                onSubmit={(e) => this.confirmTxn(e)}
                                style={{ marginTop: "7em" }}
                              >
                                <ul className="form-list">
                                  <li className="form-list__row">
                                    <label>OTP</label>
                                    <div id="input--otp">
                                      <input
                                        className="payment_wizard_input"
                                        type="number"
                                        required
                                        value={this.state.otp}
                                        onChange={(e) =>
                                          this.setState({
                                            otp: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="summary-item">
                                      <button
                                        type="submit"
                                        className="btn btn-oblong btn-primary btn-block mg-b-10"
                                        style={{
                                          paddingLeft: "2rem",
                                          paddingRight: "2rem",
                                        }}
                                      >
                                        submit
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </form>
                            ) : (
                              <form
                                onSubmit={(e) => this.initTxn(e)}
                                style={{ marginTop: "7em" }}
                              >
                                <ul className="form-list">
                                  {this.props.profile.profileType ===
                                  "personal" ? (
                                    this.props.profile.wallets.filter(
                                      (wallet) =>
                                        !["TRADER", "COMBI", "CORE"].includes(
                                          wallet.walletType
                                        )
                                    ).length > 0 ? (
                                      <>
                                        <li className="form-list__row">
                                          <label>From:</label>
                                          <div className="">
                                            <select
                                              id="originwallet"
                                              className="form-control select2 payment_wizard_input"
                                              name="wallet"
                                              onChange={(e) =>
                                                this.setState({
                                                  originWallet: e.target.value,
                                                })
                                              }
                                              required
                                            >
                                              {this.props.profile.wallets
                                                .filter(
                                                  (wallet) =>
                                                    wallet.walletType ===
                                                    "TRADER"
                                                )
                                                .map((wallet) => (
                                                  <option
                                                    value={wallet.walletType}
                                                  >
                                                    {wallet.walletType}
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                        </li>
                                        <li className="form-list__row">
                                          <label>Amount</label>
                                          <div
                                            id="input--cc"
                                            className="creditcard-icon"
                                          >
                                            <input
                                              className="payment_wizard_input"
                                              type="number"
                                              name="p4"
                                              step=".01"
                                              required
                                              min={1}
                                              value={this.state.txnAmount}
                                              onChange={(e) =>
                                                this.setState({
                                                  txnAmount: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </li>

                                        <li>
                                          <div className="summary-item">
                                            <button
                                              type="submit"
                                              className="btn btn-oblong btn-primary btn-block mg-b-10"
                                              style={{
                                                paddingLeft: "2rem",
                                                paddingRight: "2rem",
                                              }}
                                            >
                                              Confirm transaction
                                            </button>
                                          </div>
                                        </li>
                                      </>
                                    ) : (
                                      <h4 className="text-center text-warning">
                                        No wallets to top up from!!!
                                      </h4>
                                    )
                                  ) : (
                                    <>
                                      <li className="form-list__row">
                                        <label>From:</label>
                                        <div className="">
                                          <select
                                            id="originwallet"
                                            className="form-control select2 payment_wizard_input"
                                            name="wallet"
                                            onChange={(e) =>
                                              this.setState({
                                                originWallet: e.target.value,
                                              })
                                            }
                                            required
                                          >
                                            {this.props.profile.wallets
                                              .filter(
                                                (wallet) =>
                                                  wallet.walletType === "TRADER"
                                              )
                                              .map((wallet) => (
                                                <option
                                                  value={wallet.walletType}
                                                >
                                                  {wallet.walletType}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </li>
                                      <li className="form-list__row">
                                        <label>Amount</label>
                                        <div
                                          id="input--cc"
                                          className="creditcard-icon"
                                        >
                                          <input
                                            className="payment_wizard_input"
                                            type="number"
                                            name="p4"
                                            step=".01"
                                            required
                                            min={1}
                                            value={this.state.txnAmount}
                                            onChange={(e) =>
                                              this.setState({
                                                txnAmount: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </li>

                                      <li>
                                        <div className="summary-item">
                                          <button
                                            type="submit"
                                            className="btn btn-oblong btn-primary btn-block mg-b-10"
                                            style={{
                                              paddingLeft: "2rem",
                                              paddingRight: "2rem",
                                            }}
                                          >
                                            Confirm transaction
                                          </button>
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </form>
                            )}
                          </>
                        ) : (
                          <div className="pd-x-30 pd-y-10">
                            <div className="form-group pd-y-60 pd-xl-x-30">
                              <h4 className="tx-gray-800 tx-normal mg-b-5">
                                Top up from DumelaPay Wallet
                              </h4>
                              <br />
                              {/* <input type="text" name="search" className="form-control pd-y-12 mg-b-10" style={{borderRadius: "50px"}} placeholder="Search" onChange={e => this.getBProfiles(e.target.value)}/> */}
                              <button
                                className="btn btn-primary pd-y-12 btn-block btn-oblong"
                                onClick={() =>
                                  this.props.updateUtilityState({
                                    isCardTopUp: false,
                                    isWalletTopUp: true,
                                  })
                                }
                              >
                                DumelaPay Wallet
                              </button>
                              <br />
                              <hr />
                              <br />
                              <h4 className="tx-gray-800 tx-normal mg-b-5">
                                Top up from your Bank Card
                              </h4>
                              <p>
                                Use your visa or Mastercard debit/credit card
                              </p>
                              <br />
                              <button
                                className="btn btn-primary btn-outline-primary pd-y-12 btn-block btn-oblong"
                                onClick={() =>
                                  this.props.updateUtilityState({
                                    isCardTopUp: true,
                                    isWalletTopUp: false,
                                  })
                                }
                              >
                                Bank Card
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {this.props.txnStage === 2 ? (
                  <div className="modal__container">
                    <div className="modal__featured d-none d-xl-block">
                      <div className="modal__circle"></div>
                      <div className="modal__product hidden-xs">
                        <img
                          style={{
                            marginTop: "12em",
                            marginBottom: "2em",
                            maxWidth: "124%",
                          }}
                          alt="dumela pay logo"
                          src="/assets/img/dumelapay-clear.png"
                        />
                        <p style={{ fontSize: "17px", color: "white" }}>
                          This transaction will transafer from your <br />
                          {this.props.wallet.walletId
                            ? this.props.wallet.walletId.walletType
                            : ""}{" "}
                          wallet into your current wallet
                        </p>
                      </div>
                    </div>
                    <div className="modal__content">
                      <h2>Wallet Topup</h2>
                      {this.state.isLoading ? (
                        <div className="pd-y-100" style={{ display: "flex" }}>
                          <div>
                            <h4>Transaction details</h4>
                            <p>Amount: P {this.state.txnAmount}</p>
                            <p>Origin: Core Wallet</p>
                            <p>
                              Destination:{" "}
                              {this.props.wallet.walletId
                                ? this.props.wallet.walletId.walletType
                                : ""}{" "}
                              wallet
                            </p>
                          </div>
                          <h3 className="tx-gray-800 tx-normal mg-b-5 pd-y-50 pd-x-50">
                            Proccessing ....
                          </h3>
                        </div>
                      ) : this.props.isSuccess ? (
                        <div className="text-center">
                          <SuccessAnim />
                          <p>Successful</p>
                        </div>
                      ) : this.props.isFailure ? (
                        <div className="text-center">
                          <FailedAnim />
                          <p>Transaction Failed</p>
                        </div>
                      ) : this.props.otpStage ? (
                        <form
                          onSubmit={(e) => this.confirmTxn(e)}
                          style={{ marginTop: "7em" }}
                        >
                          <ul className="form-list">
                            <li className="form-list__row">
                              <label>OTP</label>
                              <div id="input--otp">
                                <input
                                  className="payment_wizard_input"
                                  type="number"
                                  required
                                  value={this.state.otp}
                                  onChange={(e) =>
                                    this.setState({
                                      otp: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                            <li>
                              <div className="summary-item">
                                <button
                                  type="submit"
                                  className="btn btn-oblong btn-primary btn-block mg-b-10"
                                  style={{
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                  }}
                                >
                                  submit
                                </button>
                              </div>
                            </li>
                          </ul>
                        </form>
                      ) : (
                        <form
                          onSubmit={(e) => this.initTxn(e)}
                          style={{ marginTop: "7em" }}
                        >
                          <ul className="form-list">
                            <li className="form-list__row">
                              <label>Reference</label>
                              <div id="input--reference">
                                <input
                                  className="payment_wizard_input"
                                  type="text"
                                  required
                                  value={this.state.reference}
                                  onChange={(e) =>
                                    this.setState({
                                      reference: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Amount</label>
                              <div id="input--cc" className="creditcard-icon">
                                <input
                                  className="payment_wizard_input"
                                  type="number"
                                  step=".01"
                                  required
                                  min={1}
                                  value={this.state.txnAmount}
                                  onChange={(e) =>
                                    this.setState({
                                      txnAmount: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>

                            <li>
                              <div className="summary-item">
                                <button
                                  type="submit"
                                  className="btn btn-oblong btn-primary btn-block mg-b-10"
                                  style={{
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                  }}
                                >
                                  submit
                                </button>
                              </div>
                            </li>
                          </ul>
                        </form>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.props.txnStage === 10 && !this.state.isLoading ? (
                  <div className="pd-x-100 pd-y-100">
                    <h3 className="tx-gray-800 tx-normal mg-b-5">
                      Transaction Successful....
                    </h3>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.walletData.wallet,
    wallets: state.walletData.wallets,
    destProfiles: state.account.destProfiles,
    profile: state.account.targetProfile,
    txnId: state.walletData.txnId,
    pg_checksum: state.walletData.pg_checksum,
    pg_requestId: state.walletData.pg_requestID,
    txnStage: state.walletData.txnStage,
    isSuccess: state.walletData.isSuccess,
    isFailure: state.walletData.isFailure,
    isCardTopUp: state.walletData.isCardTopUp,
    isWalletTopUp: state.walletData.isWalletTopUp,
    otpStage: state.walletData.otpStage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (pType) => dispatch(fetchProfileWallets(pType)),
    getProfileWallet: (wallet) => dispatch(fetchProfileWallet(wallet)),
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    startTxn: (origin, destination, amount, reference, profileType) =>
      dispatch(
        createSelfTransfer(origin, destination, amount, reference, profileType)
      ),
    startTopUpTxn: (walletId, amount) =>
      dispatch(initTopUpTxn(walletId, amount)),
    verify: (txnId, txnCode) => dispatch(completePayment(txnId, txnCode)),
    updateTxnStage: (stage) => dispatch(updateInheritTxnStage(stage)),
    updateUtilityState: (state) => dispatch(updateWalletUtilityState(state)),
    getTransactions: (wId, idx, size) =>
      dispatch(fetchWalletTransactions(wId, idx, size)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopupWizard);
