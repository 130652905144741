import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { getProfileInfo } from "../store/actions/usersActions";
import { txnCheck } from "../api/transactions";

class VcsApproved extends Component {
  state = {
    result: {},
    targetProfile: {},
    renderState: "loading",
    txnState: "verifying",
    txnAmount: 0,
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props
        .fetchProfile(profileInfo.tprofile._id)
        .then(() => {
          let search = this.props.history.location.search.replace("?", "");
          search = search.split("&");
          var dict = {};
          search.map((i) => {
            let x = i.split("=");
            dict[[x[0]]] = x[1].split("+").join(" ").trim();
          });

          console.log(dict);
          this.setState({
            targetProfile: this.props.targetProfile,
            renderState: "safe",
            result: dict,
          });
        })
        .then(() => {
          // check transaction state
          txnCheck({ txnId: this.state.result.txn })
            .then((res) => {
              console.log(res);
              this.setState({
                txnState: res.data.status,
                txnAmount: res.data.amount,
              });
            })
            .catch((err) => {
              this.setState({
                txnState: "failed",
              });
            });
        })
        .catch((err) => {
          this.setState({
            txnState: "failed",
          });
        });
    } else {
      this.setState({ renderState: "failed" });
    }
  }

  // TODO: use render state for loading, error, success states
  render() {
    return (
      <div className="container approved-container">
        <div className="modal__container" style={{ minHeight: "32rem" }}>
          <div className="modal__featured d-none d-sm-block">
            <div className="modal__circle"></div>
            <div className="modal__product hidden-xs">
              <img
                style={{
                  marginTop: "12em",
                  marginBottom: "2em",
                  maxWidth: "124%",
                }}
                src="/assets/img/dumelapay-clear.png"
              />
              <p style={{ fontSize: "17px", color: "white" }}>
                Top up your dumelaPay Wallet from your preferred bank
              </p>
            </div>
          </div>
          <div className="modal__content text-center">
            <h2 className="mb-5">Card TopUp</h2>

            <h3>Amount: </h3>
            <p style={{ fontSize: "3rem" }}>BWP {this.state.txnAmount}</p>
            <p>Ref: {this.state.result.txn}</p>
            <h3 className="mb-5">{this.state.txnState} </h3>
            <NavLink
              to={
                this.state.targetProfile.profileType == "merchant"
                  ? "/merchant/transactions"
                  : "/personal/transactions"
              }
            >
              View transactions
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    targetProfile: state.account.targetProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: (pid) => dispatch(getProfileInfo(pid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VcsApproved);
