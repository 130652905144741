import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { initTopUpTxn } from "../store/actions/walletActions";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      result: {},
      targetProfile: {},
      renderState: "loading",
      checkIdx: 0,
      txnAmount: "",
      txnId: "",
      wId: {},
      hasTransactionId: false,
      errMSG: "",
      hasError: false,
    };
  }

  componentDidMount() {
    const c = Cookies.get("dpp");
    let txnInfo = c;
    console.log(c);
    if (typeof c === "string")
      try {
        txnInfo = JSON.parse(c);
      } catch (error) {}

    if (txnInfo) {
      this.setState({ wId: txnInfo.wID });
      const search = this.props.location.search;

      if (search && search !== "") {
        const hasAmount =
          search.split("&").filter((a) => a.split("amount").length > 1).length >
          0;
        if (hasAmount) {
          const amount = search
            .split("&")
            .filter((a) => a.split("amount").length > 1)[0]
            .split("=")[1];
          this.setState({ txnAmount: amount });

          this.initCardTopUp(txnInfo.wID, amount);
        }
      }
      this.setState({ renderState: "loaded" });
    } else {
      this.setState({ renderState: "failed" });
    }
  }

  initCardTopUp(wId, amount) {
    console.log(this.state);
    this.setState({ isLoading: true });
    const _self = this;
    this.props
      .startTopUpTxn(wId || this.state.wId, amount || this.state.txnAmount)
      .then((res) => {
        // console.log(res);
        _self.txnID = res.txnId;
        _self.setState({ hasTransactionId: true, txnId: res.txnId });
      })
      .catch((err) => {
        _self.setState({
          hasTransactionId: false,
          hasError: true,
          errMSG: err.response.data.err,
        });
      })
      .finally(() => {
        _self.setState({ isLoading: false });
      });
  }

  // TODO: use render state for loading, error, success states
  render() {
    return (
      <div className="container approved-container">
        {this.state.renderState === "failed" ? (
          <></>
        ) : (
          <div className="modal__container" style={{ minHeight: "28rem" }}>
            <div className="modal__featured d-none d-sm-block">
              <div className="modal__circle"></div>
              <div className="modal__product hidden-xs">
                <img
                  style={{
                    marginTop: "10rem",
                    marginBottom: "0em",
                    maxWidth: "124%",
                  }}
                  src="/assets/img/dumelapay-clear.png"
                  alt="logo"
                />
                <p style={{ fontSize: "17px", color: "white" }}>
                  Top up your dumelaPay Wallet from your preferred bank
                </p>
              </div>
            </div>
            <div className="modal__content text-center">
              <h2 className="text-info">Bank Card Topup</h2>

              <form
                method="post"
                action="https://secure.paygate.co.za/payweb3/process.trans"
              >
                <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                <ul className="form-list">
                  <input
                    name="CHECKSUM"
                    value={this.props.pg_checksum}
                    hidden
                  />
                  <input
                    type="text"
                    name="PAY_REQUEST_ID"
                    value={this.props.pg_requestId}
                    hidden
                    readOnly
                  />
                  {/* <input className="payment_wizard_input" type="number" name="CustomerID" value={this.props.profile._id}  hidden/> */}
                  {/* <input
                    type="text"
                    name="p2"
                    value={this.state.txnId}
                    hidden
                    onChange={() => {}}
                  /> */}
                  {this.state.hasTransactionId ? (
                    <div>
                      {/* <h6 className="text-uppercase text-info">Transaction Summary</h6> */}

                      <p>
                        Transfer Amount:
                        <br />
                        <span
                          className="text-dark"
                          style={{ fontSize: "33px" }}
                        >
                          P{this.state.txnAmount}
                        </span>
                      </p>
                      <p>
                        Reference:
                        <br />{" "}
                        <font className="text-dark">{this.state.txnId}</font>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <p className="text-center" style={{ color: "red" }}>
                    {this.state.hasError ? this.state.errMSG : ""}
                  </p>
                  <li
                    className="form-list__row"
                    style={
                      this.state.hasTransactionId
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label>Amount</label>
                    <div id="input--cc" className="creditcard-icon">
                      <input
                        className="payment_wizard_input"
                        type="number"
                        name="p4"
                        step=".01"
                        required=""
                        value={this.state.txnAmount}
                        onChange={(e) =>
                          this.setState({ txnAmount: e.target.value })
                        }
                      />
                    </div>
                  </li>

                  <li>
                    <div className="summary-item">
                      {this.state.hasTransactionId ? (
                        <>
                          <div className="form-group checkbox_input">
                            <label className="container_checkbox mr-5">
                              <input
                                type="checkbox"
                                name="termsandconditions"
                                value={false}
                                className="required mr-4"
                                required
                              />
                              <span className="checkmark"></span>
                              <NavLink to="/terms">
                                Terms and Conditions
                              </NavLink>
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-oblong btn-primary btn-block mg-b-10"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                            }}
                          >
                            Confirm Transaction
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => this.initCardTopUp()}
                          className="btn btn-oblong btn-primary btn-block mg-b-10"
                          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    targetProfile: state.account.targetProfile,
    pg_checksum: state.walletData.pg_checksum,
    pg_requestId: state.walletData.pg_requestID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTopUpTxn: (walletId, amount) =>
      dispatch(initTopUpTxn(walletId, amount)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
