import React, { Component } from "react";

class EmptyAnim extends Component {
  render() {
    return (
      <>
        <lottie-player
          src="https://assets1.lottiefiles.com/packages/lf20_vnnr8lra.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px", margin: "0 auto" }}
          autoplay
        ></lottie-player>
      </>
    );
  }
}
export default EmptyAnim;
