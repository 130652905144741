import React, { Component } from "react";
import { connect } from "react-redux";
import { checkOTP, pushOTPResend } from "../store/actions/authActions";
import { getCurrentUser } from "../store/actions/usersActions";

class OTP extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    otp: null,
    isLoading: false,
    hasFailed: false,
    sendingOtp: false,
  };

  componentDidMount() {}

  resendOTP = () => {
    let body = {
      user: this.props.user,
    };

    this.setState({ sendingOtp: true });
    this.props
      .otpResend(body)
      .then(() => {})
      .catch((err) => {
        this.setState({ hasFailed: true });
        console.log(err);
      })
      .finally(() => {
        this.setState({ sendingOtp: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // VERIFY OTP
    let body = {
      number: this.props.number,
      otp: this.state.otp,
    };
    this.setState({ isLoading: true });
    this.props
      .verifyOTP(body)
      .then(() => {
        this.props.fetchUser().then((profile) => {
          console.log(profile);
          if (profile) {
            if (profile.profileType === "personal") {
              window.location.href = `/personal/transactions`;
            } else if (profile.profileType === "merchant") {
              window.location.href = `/merchant/transactions`;
            } else {
              window.location.href = `/signup/profile`;
            }
          } else {
            window.location.href = `/signup/profile`;
          }
        });
        // window.location.href = `/acc/transactions`
      })
      .catch((err) => {
        this.setState({ hasFailed: true });
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div className="signin-box">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <h2 className="signin-title-primary">OTP</h2>
          <h5 className="signin-title-secondary">
            {this.state.isLoading
              ? "Verifying"
              : this.state.hasFailed
              ? "Verificiation failed"
              : "Enter code to continue."}
          </h5>

          <div className="form-group">
            <input
              type="number"
              className="form-control"
              required
              placeholder="Enter OTP"
              onChange={(e) => this.setState({ otp: e.target.value })}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block btn-signin"
          >
            Sign In
          </button>
          {this.state.sendingOtp ? (
            <p style={{ color: "#0f7abf" }}> Sending OTP </p>
          ) : (
            <p
              style={{ cursor: "pointer", color: "#0f7abf" }}
              onClick={() => this.resendOTP()}
            >
              {" "}
              Click here to resend OTP{" "}
            </p>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyOTP: (body) => dispatch(checkOTP(body)),
    fetchUser: () => dispatch(getCurrentUser()),
    otpResend: (body) => dispatch(pushOTPResend(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OTP);
