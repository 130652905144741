import React, { Component } from "react";
import { connect } from "react-redux";
import {
  completePayment,
  createBillPayment,
  createPayment,
  fetchProfileWallet,
  fetchProfileWallets,
  fetchWalletTransactions,
  updateDestProfile,
  updateInheritTxnStage2,
} from "../store/actions/walletActions";
import {
  clearDestProfiles,
  searchBProfiles,
} from "../store/actions/usersActions";
import { BASE_URL } from "../api/client";
import { fetchProPic } from "../api/account";
import {
  getAllUtilities,
  searchUtilities,
} from "../store/actions/utilityActions";
import Select from "react-select";
import AsyncSelect from "react-select/async";

class PaymentWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    destProfiles: [],
    destUtilities: [],
    paymentType: "",
    tProfile: {},
    txnStage: 0,
    txnAmount: 0.0,
    txnId: null,
    isLoading: false,
    otp: null,
    accNumber: null,
    isSearching: false,
    isEmpty: false,
  };

  componentDidMount() {
    window.$(function () {
      // Select2 by showing the search
      window.$("#dest").select2({
        containerCssClass: "select2-full-color select2-primary",
        dropdownCssClass: "select2-drop-color select2-drop-primary",
        // minimumResultsForSearch: Infinity // disabling search
      });
    });
  }

  confirmTxn(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .verify(this.state.txnId, this.state.otp)
      .then(() => {
        this.props.getProfileWallet(this.props.wallet.walletId);
        this.props.getTransactions(this.props.wallet.walletId, 0, 10);
        this.props.resetDestProfiles();
        this.updateState(10);
      })
      .catch((err) => {
        this.updateState(-1);
        this.props.resetDestProfiles();
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  selectTProfile(profile, eventType) {
    this.updateState(1);
    this.props.updateTProfile(profile);
  }

  getBProfiles(number) {
    if (number && number.length >= 6) {
      this.props.fetchBProfiles(number).finally(() => {
        this.props.destProfiles.length < 1
          ? this.setState({ isSearching: false, isEmpty: true })
          : this.setState({ isSearching: false, isEmpty: false });
      });
    } else {
      this.setState({ isSearching: false });
      this.props.resetDestProfiles();
    }
  }

  getUtilities(searchInput) {
    this.setState({ isSearching: true });
    this.props
      .fetchUtilities(searchInput)
      .then(() => {
        this.setState({ destUtilities: this.props.destUtilities });
      })
      .finally(() => {
        this.props.destUtilities.length < 1
          ? this.setState({ isSearching: false, isEmpty: true })
          : this.setState({ isSearching: false, isEmpty: false });
      });
  }

  initTxn = (e) => {
    e.preventDefault();
    let wId = this.getCoreId()[0].walletId;
    this.setState({ isLoading: true });
    if (this.state.paymentType === "bill") {
      this.props
        .startUtilityTxn(
          wId,
          this.props.tProfile.billId,
          this.state.txnAmount,
          this.state.reference,
          this.state.accNumber
        )
        .then(() => {
          this.setState({ txnId: this.props.txnId });
          this.updateState(2);
        })
        .catch((err) => {
          this.updateState(-1);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.props
        .startTxn(
          wId,
          this.props.tProfile.number,
          this.state.txnAmount,
          this.state.reference
        )
        .then(() => {
          this.setState({ txnId: this.props.txnId });
          this.updateState(2);
        })
        .catch((err) => {
          this.updateState(-1);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  closeModal() {
    this.setState({
      txnStage: 0,
      isLoading: false,
      tProfile: {},
      txnAmount: 0,
      paymentType: "",
      txnId: null,
      accNumber: null,
      otp: null,
      destProfiles: [],
      destUtilities: [],
    });
  }

  updateState = (stage) => {
    this.props.updateTxnStage2(stage);
  };
  selectPaymentType(paymentType, txnStage) {
    this.setState({ paymentType: paymentType, txnStage: txnStage });
    this.updateState(txnStage);
    if (paymentType === "bill") {
      this.props.fetchAllUtilities().then(() => {
        var utils = [];
        for (
          let index = 0;
          index < this.props.destUtilities.groups.length;
          index++
        ) {
          const key = this.props.destUtilities.groups[index];
          this.props.destUtilities.data[key].map((info) => {
            utils.push({
              ...info,
              value: info.billId,
              label: info.name,
            });
          });
        }

        this.setState({ destUtilities: utils });
      });
    }
  }

  getCoreId() {
    return this.props.wallets.filter((w) => w.walletId.walletType === "CORE");
  }

  render() {
    return (
      <div id="paymentmodal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-sm-0 pd-lg-50">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div
                    className="modal__featured d-none d-xl-block"
                    style={{ minWidth: "600px" }}
                  >
                    <div className="modal__circle"></div>
                    <div
                      className="modal__product hidden-xs"
                      style={{ left: "39%", maxWidth: "100%" }}
                    >
                      <img
                        style={{
                          marginTop: "5em",
                          marginBottom: "2em",
                          maxWidth: "65%",
                        }}
                        alt="Dumela Pay logo"
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p className="tx-white op-7 mg-b-30">
                        Lets help you send money.
                      </p>

                      <div className="row" style={{ width: "130%" }}>
                        {this.props.txnStage === 1 ||
                        this.props.txnStage === 2 ||
                        this.props.txnStage === 10 ? (
                          <div
                            className="col-lg-12 p-1"
                            style={{ textAlign: "center" }}
                          >
                            <h3 className="tx-white mg-t-20 mg-b-20">
                              {this.props.tProfile.businessName}
                            </h3>
                            <p
                              className="tx-white op-7 mg-b-30"
                              style={{ fontSize: "2em" }}
                            >
                              P{" "}
                              {this.props.tProfile.fixedAmount > 0
                                ? this.props.tProfile.fixedAmount
                                : this.state.txnAmount}
                            </p>
                            <p className="tx-white op-7 mg-b-30">
                              {this.props.tProfile.description
                                ? this.props.tProfile.description
                                : ""}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* <!-- screen 1 --> */}
                        {this.props.txnStage === 0.5 ? (
                          this.props.destProfiles.map((profile, idx) => (
                            <div
                              key={idx}
                              className="col-lg-6 p-1"
                              onClick={() =>
                                this.selectTProfile(profile, "pay")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  padding: "0.2em 1em",
                                  paddingLeft: "0.2em",
                                  backgroundColor: "white",
                                  borderRadius: "50px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "2.6rem",
                                    height: "2.6rem",
                                    margin: "auto 0",
                                  }}
                                >
                                  {profile.propic ? (
                                    <img
                                      src={
                                        BASE_URL +
                                        fetchProPic() +
                                        `?picId=${profile.propic}&size=480x480`
                                      }
                                      className="img-fluid rounded-circle"
                                      alt=""
                                      style={{
                                        objectFit: "cover",
                                        width: "2.6rem",
                                        height: "2.6rem",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/img0.jpg"}
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div
                                  className="mg-l-15"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="tx-bold tx-black"
                                    style={{
                                      marginTop: "0",
                                      marginBottom: "0",
                                      whiteSpace: "nowrap",
                                      width: "14rem",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {profile.profileType === "merchant"
                                      ? profile.businessName
                                      : profile.account.firstName +
                                        " " +
                                        profile.account.lastName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}

                        <div
                          className="col-lg-12 p-1"
                          style={{ textAlign: "center" }}
                        >
                          <h3 className="tx-white mg-b-20">
                            {this.props.tProfile.name}
                          </h3>
                          {/* <p className="tx-white op-7 mg-b-30">{this.state.tProfile.description ? this.state.tProfile.description : ""}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal__content">
                    {this.state.isLoading ? (
                      <div className="pd-x-100 pd-y-100">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Processing ....
                        </h3>
                        {/* <p>Proccessing ....</p> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 0 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <div className="form-group pd-y-60 pd-xl-x-30">
                          <h3 className="tx-gray-800 tx-normal mg-b-5">
                            Pay Your Bills
                          </h3>
                          <p>Search for Utility Provider</p>
                          <br />
                          {/* <input type="text" name="search" className="form-control pd-y-12 mg-b-10" style={{borderRadius: "50px"}} placeholder="Search" onChange={e => this.getBProfiles(e.target.value)}/> */}
                          <button
                            className="btn btn-primary pd-y-12 btn-block btn-oblong"
                            onClick={() => this.selectPaymentType("bill", 0.6)}
                          >
                            Utility Bills
                          </button>
                          <br />
                          <hr />
                          <br />
                          <h3 className="tx-gray-800 tx-normal mg-b-5">
                            Make Other Payments
                          </h3>
                          <p>Search for company cellphone number</p>
                          <br />
                          <button
                            className="btn btn-primary btn-outline-primary pd-y-12 btn-block btn-oblong"
                            onClick={() => this.selectPaymentType("other", 0.5)}
                          >
                            Others
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 0.5 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Find Receipent
                        </h3>
                        <p>Search for mobile number</p>
                        <br />
                        <div className="form-group pd-y-60 pd-xl-x-30 ">
                          {this.state.isSearching ? (
                            <p>Searching...</p>
                          ) : this.state.isEmpty ? (
                            <p>Nothing found ...</p>
                          ) : (
                            ""
                          )}
                          <input
                            type="text"
                            name="search"
                            className="form-control pd-y-12 mg-b-10"
                            style={{ borderRadius: "50px" }}
                            placeholder="Search"
                            onChange={(e) => this.getBProfiles(e.target.value)}
                          />
                          {/* <select
                            id="dest"
                            className="form-control select2 pd-y-12 mg-b-10 d-xl-none d-md-none d-lg-none"
                            data-placeholder="destination"
                            data-live-search="true"
                            style={{ borderRadius: "50px" }}
                            name="destination"
                            required
                          >
                            <option value=""></option>
                          </select> */}
                          <button className="btn btn-primary pd-y-12 btn-block btn-oblong">
                            Search
                          </button>
                        </div>
                        <div className="row  mt-4 d-lg-none">
                          {this.props.destProfiles.map((profile, idx) => (
                            <div
                              key={idx}
                              className="col-lg-6 p-1"
                              onClick={() =>
                                this.selectTProfile(profile, "pay")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  padding: "0.2em 1em",
                                  paddingLeft: "0.2em",
                                  backgroundColor: "white",
                                  border: "solid 1px #0f7abf",
                                  borderRadius: "50px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "2.6rem",
                                    height: "2.6rem",
                                    margin: "auto 0",
                                  }}
                                >
                                  {profile.propic ? (
                                    <img
                                      src={
                                        BASE_URL +
                                        fetchProPic() +
                                        `?picId=${profile.propic}&size=480x480`
                                      }
                                      className="img-fluid rounded-circle"
                                      alt=""
                                      style={{
                                        objectFit: "cover",
                                        width: "2.6rem",
                                        height: "2.6rem",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/img0.jpg"}
                                      className="img-fluid rounded-circle"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div
                                  className="mg-l-15"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="tx-bold tx-black"
                                    style={{
                                      marginTop: "0",
                                      marginBottom: "0",
                                      whiteSpace: "nowrap",
                                      width: "14rem",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {profile.profileType === "merchant"
                                      ? profile.businessName
                                      : profile.account.firstName +
                                        " " +
                                        profile.account.lastName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 0.6 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Find Utility
                        </h3>
                        <p>Search Utility name</p>
                        <br />
                        <div className="form-group pd-y-60 pd-xl-x-30 hidden-xs">
                          {this.state.isSearching ? (
                            <p>Searching...</p>
                          ) : this.state.isEmpty ? (
                            <p>Nothing found ...</p>
                          ) : (
                            ""
                          )}
                          <Select
                            options={this.state.destUtilities}
                            onChange={(e) => this.selectTProfile(e, "pay")}
                            placeholder="Select service"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <!-- Screen  2 --> */}
                    {this.props.txnStage === 1 && !this.state.isLoading ? (
                      <form
                        onSubmit={(e) => this.initTxn(e)}
                        className="pd-x-30 pd-y-10"
                      >
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Payment Amount
                        </h3>
                        <br />
                        <div className="form-group pd-y-60 pd-xl-x-30">
                          {/* <label>Account Number</label> */}
                          {this.props.tProfile.fixedAmount > 0 ? (
                            <></>
                          ) : (
                            <input
                              type="number"
                              step=".01"
                              name="amount"
                              required
                              className="form-control pd-y-12 mg-b-10  tx-48"
                              style={{ border: "none", textAlign: "center" }}
                              placeholder="0.00"
                              min={0}
                              max={4000}
                              onChange={(e) =>
                                this.setState({ txnAmount: e.target.value })
                              }
                            />
                          )}

                          {this.state.paymentType === "bill" ? (
                            <>
                              <p className="text-center">
                                {this.props.tProfile.name}
                              </p>
                              <input
                                type="text"
                                name="account number"
                                className="form-control pd-y-12 mg-b-20 "
                                style={{
                                  borderRadius: "50px",
                                  textAlign: "center",
                                }}
                                placeholder="Account number"
                                required
                                onChange={(e) =>
                                  this.setState({ accNumber: e.target.value })
                                }
                              />
                            </>
                          ) : (
                            ""
                          )}
                          <button
                            type="submit"
                            className="btn btn-primary pd-y-12 btn-block btn-oblong"
                          >
                            Pay
                          </button>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 2 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <form onSubmit={(e) => this.confirmTxn(e)}>
                          <h3 className="tx-gray-800 tx-normal mg-b-5">
                            Please verify transaction
                          </h3>
                          <h4>Enter OTP</h4>
                          <br />
                          <div className="form-group pd-y-60 pd-xl-x-30">
                            <input
                              type="text"
                              required
                              className="form-control pd-y-12 mg-b-10"
                              style={{ borderRadius: "50px" }}
                              placeholder="verification OTP"
                              onChange={(e) =>
                                this.setState({ otp: e.target.value })
                              }
                            />
                            <button
                              className="btn btn-primary pd-y-12 btn-block btn-oblong"
                              type="submit"
                            >
                              Verify
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === -1 && !this.state.isLoading ? (
                      <div className="pd-x-55 pd-y-55">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Transaction failed....
                        </h4>
                        <lottie-player
                          src="https://assets2.lottiefiles.com/packages/lf20_ed9D2z.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "300px", height: "300px" }}
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 10 && !this.state.isLoading ? (
                      <div className="pd-x-55 pd-y-55">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Transaction Successful....
                        </h4>
                        <lottie-player
                          src="https://assets1.lottiefiles.com/packages/lf20_vuliyhde.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "300px", height: "300px" }}
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.walletData.wallet,
    wallets: state.walletData.wallets,
    destProfiles: state.account.destProfiles,
    tProfile: state.walletData.destProfile,
    destUtilities: state.utilitiesData.destUtilities,
    txnId: state.walletData.txnId,
    txnStage: state.walletData.txnStage2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (pType) => dispatch(fetchProfileWallets(pType)),
    fetchBProfiles: (number) => dispatch(searchBProfiles(number)),
    fetchUtilities: (searchInput) => dispatch(searchUtilities(searchInput)),
    fetchAllUtilities: () => dispatch(getAllUtilities()),
    startTxn: (walletId, dNumber, amount, reference) =>
      dispatch(createPayment(walletId, dNumber, amount, reference)),
    startUtilityTxn: (walletId, billId, amount, reference, accNumber) =>
      dispatch(
        createBillPayment(walletId, billId, amount, reference, accNumber)
      ),
    verify: (txnId, txnCode) => dispatch(completePayment(txnId, txnCode)),
    updateTxnStage2: (stage) => dispatch(updateInheritTxnStage2(stage)),
    updateTProfile: (profile) => dispatch(updateDestProfile(profile)),
    resetDestProfiles: () => dispatch(clearDestProfiles()),
    getTransactions: (wId, idx, size) =>
      dispatch(fetchWalletTransactions(wId, idx, size)),
    getProfileWallet: (wallet) => dispatch(fetchProfileWallet(wallet)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWizard);
