import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProPic } from "../api/account";
import { BASE_URL } from "../api/client";
import {
  updateAcc,
  updateProfilePic,
  getProfileInfo,
  resetSignatoryState,
} from "../store/actions/usersActions";
import Cookies from "js-cookie";
import SignatoryWizard from "../components/SignatoryWizard";
import { fetchProfileWallets } from "../store/actions/walletActions";

class Settings extends Component {
  state = {
    profile: {},
    acc: {},
    profilePicture: "/assets/img/img0.jpg",
    profileFile: {},
    isUpdatingPersonalInfo: false,
    isUpdatingPersonalAddress: false,
    isUpdatingAccountInfo: false,
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileWallets(
        profileInfo.tprofile.profileType || this.props.profile.profileType
      );
    }

    this.setState({ profile: this.props.profile, acc: this.props.acc });
  }

  firstNameOnchange(value) {
    var acc = this.state.acc;
    acc.firstName = value;

    this.setState({ acc: acc });
  }

  lastNameOnchange(value) {
    var acc = this.state.acc;
    acc.lastName = value;

    this.setState({ acc: acc });
  }

  dobOnchange(value) {
    var acc = this.state.acc;
    acc.dob = value;

    this.setState({ acc: acc });
  }

  emailOnchange(value) {
    var acc = this.state.acc;
    acc.email = value;

    this.setState({ acc: acc });
  }

  personalInfoUpdate(e) {
    e.preventDefault();

    var body = {
      firstName: this.state.acc.firstName,
      lastName: this.state.acc.lastName,
      dob: this.state.acc.dob,
      nationality: this.state.acc.nationality,
      nationalId: this.state.acc.nationalId,
      passportNumber: this.state.acc.passportNumber,
    };

    this.setState({ isUpdatingPersonalInfo: true });
    this.props
      .updateUserInfo(this.props.acc._id, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isUpdatingPersonalInfo: false });
      });
  }

  nationalityOnchange(value) {
    var acc = this.state.acc;
    acc.nationality = value;

    this.setState({ acc: acc });
  }

  nationalIdOnchange(value) {
    var acc = this.state.acc;
    acc.nationalId = value;

    this.setState({ acc: acc });
  }

  passportOnchange(value) {
    var acc = this.state.acc;
    acc.passportNumber = value;

    this.setState({ acc: acc });
  }

  merchantInfo(e) {
    // e.preventDefault();
    // var body = {
    //   nationality: this.state.acc.nationality,
    //   nationalId: this.state.acc.nationalId,
    //   passportNumber: this.state.acc.passportNumber,
    // };
    // this.props
    //   .updateUserInfo(this.props.profile._id, body)
    //   .then(() => {})
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  countryOnchange(value) {
    var acc = this.state.acc;
    acc.country = value;

    this.setState({ acc: acc });
  }

  cityOnchange(value) {
    var acc = this.state.acc;
    acc.city = value;

    this.setState({ acc: acc });
  }

  addressOnchange(value) {
    var profile = this.state.profile;
    profile.address = value;

    this.setState({ profile: profile });
  }

  contactInfoUpdate(e) {
    e.preventDefault();

    var body = {
      address: this.state.profile.address,
      city: this.state.acc.city,
      country: this.state.acc.country,
      email: this.state.acc.email,
    };

    this.setState({ isUpdatingPersonalAddress: true });
    this.props
      .updateUserInfo(this.props.acc._id, body)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isUpdatingPersonalAddress: false });
      });
  }

  readURL = (input) => {
    if (input.target.files && input.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          profilePicture: e.target.result,
          profileFile: input.target.files[0],
        });
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  };

  savePropic = () => {
    var body = new FormData();
    body.append("file", this.state.profileFile);
    body.append("pId", this.props.profile._id);
    this.setState({ isUpdatingPersonalInfo: true });
    this.props
      .updateprofilePicture(body)
      .then(() => {
        this.setState({
          profilePicture:
            BASE_URL +
            fetchProPic() +
            `?picId=${this.props.profile.propic}&size=480x480`,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ isUpdatingPersonalInfo: false });
      });
  };

  businessName = (name) => {
    var profile = this.state.profile;
    profile.businessName = name;

    // this.setState({ profile: profile });
  };

  updateRegNumber = (number) => {
    var profile = this.state.profile;
    profile.regNumber = number;

    // this.setState({ profile: profile });
  };

  updateProfileCountry = (country) => {
    var profile = this.state.profile;
    profile.country = country;

    // this.setState({ profile: profile });
  };

  updateProfileAddress = (address) => {
    var profile = this.state.profile;
    profile.address = address;

    // this.setState({ profile: profile });
  };

  render() {
    return (
      <>
        <div className="col-md-12 ">
          <div className="section-wrapper mg-b-20">
            <div
              className={
                this.state.isUpdatingPersonalInfo ? "d-block" : "d-none"
              }
              style={{
                position: "absolute",
                background: "#ffffffd1",
                width: "97%",
                height: "95%",
                zIndex: 3,
                left: "15px",
                top: "0",
                borderRadius: "5px",
                textAlign: "center",
                padding: "14em",
              }}
            >
              <p
                style={{ fontWeight: "bold", color: "black", fontSize: "17px" }}
              >
                Updating ...
              </p>
            </div>
            <label className="section-title mt-0">Personal Information</label>
            <form onSubmit={(e) => this.personalInfoUpdate(e)}>
              <div className="form-layout form-layout-4 row">
                <div className="col-7">
                  <div className="row">
                    <label className="col-sm-4 form-control-label">
                      Firstname: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter firstname"
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) => this.firstNameOnchange(e.target.value)}
                        value={this.state.acc.firstName}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Lastname: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter lastname"
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) => this.lastNameOnchange(e.target.value)}
                        value={this.state.acc.lastName}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Date of Birth: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => this.dobOnchange(e.target.value)}
                        value={this.state.acc.dob}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Nationality: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) =>
                          this.nationalityOnchange(e.target.value)
                        }
                        value={this.state.acc.nationality}
                      />
                    </div>
                  </div>
                  {this.state.profile.country === "botswana" ? (
                    <div className="row mg-t-20">
                      <label className="col-sm-4 form-control-label">
                        National ID: <span className="tx-danger">*</span>
                      </label>
                      <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.nationalIdOnchange(e.target.value)
                          }
                          value={this.state.acc.nationalId}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row mg-t-20">
                      <label className="col-sm-4 form-control-label">
                        Passport Number: <span className="tx-danger">*</span>
                      </label>
                      <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.passportOnchange(e.target.value)
                          }
                          value={this.state.acc.passportNumber}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className="form-layout-footer mg-t-30"
                    style={{ textAlign: "end" }}
                  >
                    <button
                      className="btn btn-primary bd-0  mg-r-20"
                      style={{ borderRadius: "25px" }}
                    >
                      Submit Form
                    </button>
                  </div>
                </div>

                <div className="col-5">
                  <div className="container">
                    <div className="picture-container">
                      <div className="picture mb-4">
                        <img
                          src={this.state.profilePicture}
                          className="picture-src"
                          id="wizardPicturePreview"
                          title=""
                          alt="profile"
                        />
                        <input
                          type="file"
                          id="wizard-picture"
                          className=""
                          onChange={(e) => this.readURL(e)}
                        />
                      </div>
                      <h6 className="">Click picture to update</h6>
                      <div
                        className="form-layout-footer mg-t-30"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="btn btn-primary bd-0"
                          onClick={() => this.savePropic()}
                          style={{ borderRadius: "25px" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 col-md-3  mg-t-40 mg-md-t-0">
          <div className="section-wrapper mg-b-20">
            <div
              className={
                this.state.isUpdatingPersonalAddress ? "d-block" : "d-none"
              }
              style={{
                position: "absolute",
                background: "#ffffffd1",
                width: "94%",
                height: "94%",
                zIndex: 3,
                left: "15px",
                top: "0",
                borderRadius: "5px",
                textAlign: "center",
                padding: "12em",
              }}
            >
              <p
                style={{ fontWeight: "bold", color: "black", fontSize: "17px" }}
              >
                Updating ...
              </p>
            </div>
            <label className="section-title">
              Customer Contact Information
            </label>
            <form onSubmit={(e) => this.contactInfoUpdate(e)}>
              <div className="form-layout form-layout-4">
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    Primary Email: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Primary email"
                      onChange={(e) => this.emailOnchange(e.target.value)}
                      value={this.state.acc.email}
                    />
                  </div>
                </div>
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    Country: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => this.countryOnchange(e.target.value)}
                      value={this.state.acc.country}
                    />
                  </div>
                </div>
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    City/Town/Village: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => this.cityOnchange(e.target.value)}
                      value={this.state.acc.city}
                    />
                  </div>
                </div>
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    Address: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      style={{ textTransform: "capitalize" }}
                      onChange={(e) => this.addressOnchange(e.target.value)}
                      value={this.state.profile.address}
                      // account address
                    />
                  </div>
                </div>

                <div
                  className="form-layout-footer mg-t-30"
                  style={{ textAlign: "end" }}
                >
                  <button
                    className="btn btn-primary bd-0 mg-r-20"
                    style={{ borderRadius: "25px" }}
                  >
                    Submit Form
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {this.state.profile.profileType === "merchant" ? (
          <div className="col-md-6 col-md-3  mg-t-40 mg-md-t-0">
            <div className="section-wrapper mg-b-20">
              <div
                className={
                  this.state.isUpdatingAccountInfo ? "d-block" : "d-none"
                }
                style={{
                  position: "absolute",
                  background: "#ffffffd1",
                  width: "94%",
                  height: "79%",
                  zIndex: 3,
                  left: "15px",
                  top: "0",
                  borderRadius: "5px",
                  textAlign: "center",
                  padding: "10em",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "17px",
                  }}
                >
                  Updating ...
                </p>
              </div>
              <label className="section-title">Merchant Info</label>
              <form onSubmit={(e) => this.merchantInfo(e)}>
                <div className="form-layout form-layout-4">
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Business Name: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        disabled
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) => this.businessName(e.target.value)}
                        value={this.state.profile.businessName}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Registration Number: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        disabled
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) => this.updateRegNumber(e.target.value)}
                        value={this.state.profile.regNumber}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Country: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        disabled
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) =>
                          this.updateProfileCountry(e.target.value)
                        }
                        value={this.state.profile.country}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20">
                    <label className="col-sm-4 form-control-label">
                      Physical Address: <span className="tx-danger">*</span>
                    </label>
                    <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        placeholder=""
                        style={{ textTransform: "capitalize" }}
                        onChange={(e) =>
                          this.updateProfileAddress(e.target.value)
                        }
                        value={this.state.profile.address}
                      />
                    </div>
                  </div>

                  <div
                    className="form-layout-footer mg-t-30"
                    style={{ textAlign: "end" }}
                  >
                    {/* <button
                    className="btn btn-primary bd-0 mg-r-20"
                    style={{ borderRadius: "25px" }}
                  >
                    Submit Form
                  </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-12 mg-t-40 mg-md-t-0">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">Signatories</label>
            <ul className="signatory_list" style={{ listStyle: "none" }}>
              {this.props.profile ? (
                this.props.profile.signatories.length < 1 ? (
                  <p
                    className="
                  mt-3"
                    style={{ textTransform: "capitalize" }}
                  >
                    No signatories found
                  </p>
                ) : (
                  this.props.profile.signatories.map((signatory) => (
                    <li
                      key={signatory._id}
                      className="mb-1 mt-1"
                      style={{ borderBottom: "solid 1px #c9c9c9" }}
                    >
                      <div
                        style={{ display: "flex", textTransform: "capitalize" }}
                      >
                        <p className="wd-250 mb-2 mt-2">
                          Name: {signatory.firstName + " " + signatory.lastName}
                        </p>
                        <p className="wd-250 mb-2 mt-2">
                          Number: {signatory.number}
                        </p>
                      </div>
                    </li>
                  ))
                )
              ) : (
                ""
              )}
            </ul>

            <div
              className="form-layout-footer mg-t-30"
              style={{ textAlign: "end" }}
            >
              <button
                onClick={() => this.props.setDefaultSigState()}
                className="btn btn-primary bd-0 mg-r-20"
                style={{ borderRadius: "25px" }}
                data-toggle="modal"
                data-target="#signatoryModal"
              >
                Add a signatory
              </button>
            </div>
            <SignatoryWizard />
            {/* <form onSubmit={(e) => this.contactInfoUpdate(e)}>
              <div className="form-layout form-layout-4">
                <div className="row">
                  <label className="col-sm-4 form-control-label">
                    Country: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      onChange={(e) => this.countryOnchange(e.target.value)}
                      value={this.state.profile.country}
                    />
                  </div>
                </div>
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    City/Town/Village: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => this.cityOnchange(e.target.value)}
                      value={this.state.profile.city}
                    />
                  </div>
                </div>
                <div className="row mg-t-20">
                  <label className="col-sm-4 form-control-label">
                    Address: <span className="tx-danger">*</span>
                  </label>
                  <div className="col-sm-8 mg-t-10 mg-sm-t-0">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => this.addressOnchange(e.target.value)}
                      value={this.state.profile.address}
                    />
                  </div>
                </div>

                <div
                  className="form-layout-footer mg-t-30"
                  style={{ textAlign: "end" }}
                >
                  <button
                    className="btn btn-primary bd-0 mg-r-20"
                    style={{ borderRadius: "25px" }}
                  >
                    Submit Form
                  </button>
                  <button
                    className="btn btn-secondary bd-0"
                    style={{ borderRadius: "25px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profile: state.account.targetProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    updateUserInfo: (acc, body) => dispatch(updateAcc(acc, body)),
    updateprofilePicture: (body) => dispatch(updateProfilePic(body)),
    fetchProfile: (id) => dispatch(getProfileInfo(id)),
    setDefaultSigState: () => dispatch(resetSignatoryState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
