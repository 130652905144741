import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import deviceReducer from "./deviceReducer";
import kycReducer from "./kycReducer";
import loansReducer from "./loansReducer";
import utilitiesReducer from "./utilitiesReducer";
import walletReducer from "./walletReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  walletData: walletReducer,
  loanData: loansReducer,
  utilitiesData: utilitiesReducer,
  kycData: kycReducer,
  deviceData: deviceReducer,
});

export default rootReducer;
