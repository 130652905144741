import { getPWallets, getPWallet } from "../../api/wallet";

import {
  getWalletTransactions,
  initBillPaymentTransaction,
  initPTransaction,
  initTopUpTransaction,
  initTTransaction,
  verifyTransaction,
  initTSelfTransaction,
  scheduleSingle,
  scheduleBatchTxn,
  verifyScheduleTxn,
  getProfileScheduleGroups,
  getProfileScheduleGroup,
} from "../../api/transactions";

export function fetchProfileWallets(pType) {
  return (dispatch) => {
    return getPWallets(pType)
      .then((response) => {
        let wallets;
        try {
          wallets =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          wallets = response.data;
        } finally {
          dispatch({
            type: "GET_WALLETS",
            data: wallets,
          });
          return Promise.resolve(wallets);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function fetchProfileWallet(walletId) {
  return (dispatch) => {
    return getPWallet(walletId)
      .then((response) => {
        let wallet;
        try {
          wallet =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          wallet = response.data;
        } finally {
          dispatch({
            type: "GET_WALLET",
            data: wallet,
          });
          return Promise.resolve(wallet);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function switchTargetWallet(wallet) {
  return (dispatch) => {
    dispatch({
      type: "SWITCH_WALLET_CONTEXT",
      data: wallet,
    });
    return Promise.resolve(wallet);
  };
}

export function fetchWalletTransactions(walletId, idx, size) {
  return (dispatch) => {
    return getWalletTransactions({ wID: walletId, idx: idx, size: size })
      .then((response) => {
        let transactions;
        try {
          transactions =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transactions = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTIONS",
            data: transactions,
          });
          return Promise.resolve(transactions);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createPayment(walletId, dNumber, amount, reference) {
  return (dispatch) => {
    return initPTransaction({
      wID: walletId,
      destination: dNumber,
      amount: amount,
      reference: reference,
      txnChannel: "web",
    })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTION_RECORD",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createBillPayment(
  walletId,
  billId,
  amount,
  reference,
  accNumber
) {
  return (dispatch) => {
    return initBillPaymentTransaction({
      wID: walletId,
      destination: billId,
      amount: amount,
      reference: reference,
      txnChannel: "web",
      accNumber: accNumber,
    })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTION_RECORD",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createTransfer(walletId, dNumber, amount, reference) {
  return (dispatch) => {
    return initTTransaction({
      wID: walletId,
      destination: dNumber,
      amount: amount,
      reference: reference,
      txnChannel: "web",
    })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTION_RECORD",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createSelfTransfer(
  origin,
  destination,
  amount,
  reference,
  profileType
) {
  return (dispatch) => {
    return initTSelfTransaction({
      origin: origin,
      destination: destination,
      amount: amount,
      reference: reference,
      txnChannel: "web",
      accType: profileType,
    })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTION_RECORD",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function initTopUpTxn(walletId, amount) {
  return (dispatch) => {
    return initTopUpTransaction({
      wID: walletId,
      amount: amount,
      txnChannel: "web",
    })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          dispatch({
            type: "GET_TRANSACTION_RECORD",
            data: {
              txnId: transaction.txnId,
              pg_requestID: transaction.requestID,
              pg_checksum: transaction.checksum,
            }, //transaction
          });
        } catch (error) {
          transaction = response.data;
        } finally {
          // dispatch({
          //     type: "GET_TRANSACTION_RECORD",
          //     data: {txnId:response.data.txnId}//transaction
          // })
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function completePayment(txnId, txnCode) {
  return (dispatch) => {
    return verifyTransaction({ txnId: txnId, txnCode: txnCode })
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_TRANSACTION",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function updateInheritTxnStage(txnStage) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_TXN_STAGE",
      data: txnStage,
    });
  };
}

export function updateInheritTxnStage2(txnStage) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_TXN_STAGE2",
      data: txnStage,
    });
  };
}
export function resetWalletUtilityState() {
  return (dispatch) => {
    dispatch({
      type: "RESET_W_UTILITY_STATE",
    });
  };
}

export function updateWalletUtilityState(data) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_W_UTILITY_STATE",
      data: data,
    });
  };
}

export function changeScheduleStage(data) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SCHEDULE_STAGE",
      data: data,
    });
  };
}

export function updateDestProfile(profile) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_DEST_PROFILE",
      data: profile,
    });
  };
}

export function scheduleSingleTxn(body) {
  return (dispatch) => {
    return scheduleSingle(body)
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "GET_SCHEDULED_TXN",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err.response.data));
  };
}

export function scheduleTxnBatch(body) {
  return (dispatch) => {
    return scheduleBatchTxn(body)
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "TRANSACTION_SCHEDULE_BATCH",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err.response.data));
  };
}

export function authScheduleTxn(body) {
  return (dispatch) => {
    return verifyScheduleTxn(body)
      .then((response) => {
        let transaction;
        try {
          transaction =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transaction = response.data;
        } finally {
          dispatch({
            type: "TRANSACTION_SCHEDULE",
            data: transaction,
          });
          return Promise.resolve(transaction);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function fetchScheduleTxns(profile) {
  return (dispatch) => {
    return getProfileScheduleGroups(profile)
      .then((response) => {
        let transactions;
        try {
          transactions =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transactions = response.data;
        } finally {
          dispatch({
            type: "GET_SCHEDULED_TXNS",
            data: transactions,
          });
          return Promise.resolve(transactions);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function fetchScheduleTxnGroup(profile, scheduleLink) {
  return (dispatch) => {
    return getProfileScheduleGroup(profile, scheduleLink)
      .then((response) => {
        let transactions;
        try {
          transactions =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          transactions = response.data;
        } finally {
          dispatch({
            type: "GET_SCHEDULED_TXN",
            data: transactions,
          });
          return Promise.resolve(transactions);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
