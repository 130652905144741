const initState = {
  utility: {},
  utilities: [],
  destUtilities: { groups: [], data: [] },
};

const utilitiesReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_DEST_UTILITIES":
      let groups = Object.keys(action.data);

      return {
        ...state,
        destUtilities: { groups: groups, data: action.data },
      };
    case "RESET_W_UTILITY_STATE":
      return {
        ...state,
        destUtilities: { groups: [], data: [] },
        utilities: [],
        utility: {},
      };
    default:
      break;
  }
  return state;
};

export default utilitiesReducer;
