import React, { Component } from "react";


class UploadAnim extends Component {


    render() {
        return (
          <>
          <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_z7DhMX.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}} loop autoplay></lottie-player>
          <h4>Uploading  ...</h4>
          </>
        );
    }
}
export default UploadAnim;
