// get wallets
// create wallet
import { client, BASE_URL } from "./client";
import store from "../index";

export function uploadKyc(body) {
  let options = {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      store.dispatch({
        type: "UPLOAD_PROGRESS_EVENT",
        data: { percent: percent, total: total, uploaded: loaded },
      });
    },
  };

  return client.post(`/api/kyc/v1/file`, body, options);
}

export function getProfileKyc(profile) {
  return client.get(`/api/kyc/v1/files/profile?profile=${profile}`);
}

export function downloadFile(id) {
  window.location.href = `${BASE_URL}/api/kyc/v1/file/download?fileId=${id}`;
}
