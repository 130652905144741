const initState = {
    loan:{
      provider:"letshego",
      programName:"Letshego loans",
      externalLink:"",
      summary:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
    },
    loans:[{
      provider:"letshego",
      programName:"Letshego loans",
      externalLink:"",
      summary:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
    },
    {
      provider:"FNB",
      programName:"Personal loans",
      externalLink:"",
      summary:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
    },
    {
      provider:"LOAN PROVIDER NAME",
      programName:"Loan program name",
      externalLink:"",
      summary:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
    }]
}

const loansReducer = (state = initState, action) => {
  switch (action.type) {
    case  "GET_LOANS":
        return{
            ...state,
            loans:action.data,
            
        }
    case "GET_LOAN":
      return {
        ...state,
        loan:action.data
      };
    
    default:
      break;
  }
  return state;
};

export default loansReducer;