import { getProfileKyc, uploadKyc } from "../../api/kyc";

export function pushKyc(body) {
  return (dispatch) => {
    return uploadKyc(body)
      .then((response) => {
        let kycinfo;
        try {
          kycinfo =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "KYC_UPLOAD",
            data: kycinfo,
          });
          return Promise.resolve(kycinfo);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getFiles(profile) {
  return (dispatch) => {
    return getProfileKyc(profile)
      .then((response) => {
        let kycFiles;
        try {
          kycFiles =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_KYC",
            data: kycFiles,
          });
          return Promise.resolve(kycFiles);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
