// get wallets
// create wallet
import { client } from "./client";

export function searchBillingUtilities(utility) {
  return client.get(`/api/zeus/v1/search/utilities/tagged?utility=${utility}`);
}

export function getAllBillingUtilities() {
  return client.get(`/api/zeus/v1/bills/serviceproviders/tags`);
}
