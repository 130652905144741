import React, { Component } from "react";

class SuccessAnim extends Component {
  render() {
    return (
      <>
        <lottie-player
          src="https://assets1.lottiefiles.com/packages/lf20_vuliyhde.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px", margin: "0 auto" }}
          autoplay
        ></lottie-player>
      </>
    );
  }
}
export default SuccessAnim;
