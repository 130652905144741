import React, { Component } from "react";


class KycAnim extends Component {


  render() {
    return (
      <>
        <p>Help us know who you are</p>
        <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_d97uxr.json" background="transparent" speed="1" loop style={{ width: "300px", height: "300px" }} autoplay></lottie-player>
        <p>Upload your KYC documents</p>
      </>

    );
  }
}
export default KycAnim;
