import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileWallets } from "../store/actions/walletActions";
import {
  bindSupportAgent,
  clearDestProfiles,
  createNewSupportAgent,
  searchPProfiles,
  unbindSupportAgent,
} from "../store/actions/usersActions";
import SuccessAnim from "./SuccessAnim";
import FailedAnim from "./FailedAnim";
import { BASE_URL } from "../api/client";
import { fetchProPic } from "../api/account";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

class SupportAgentWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    tProfile: {},
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    countries: [
      { value: "botswana", label: "Botswana" },
      { value: "south_africa", label: "South Africa" },
      { value: "namibia", label: "Namibia" },
      { value: "india", label: "India" },
      { value: "zimbabwe", label: "Zimbabwe" },
      { value: "china", label: "China" },
    ],
    nationality: "botswana",
    dob: "",
    number: "",
    firstName: "",
    lastName: "",
    gender: "",
    nationalId: "",
    passportNumber: "",
    searchResults: [],
  };

  componentDidMount() {}

  updateNumber = (number) => {
    this.setState({ number: number });
    if (number && number.length >= 10) {
      this.props.fetchPProfiles(number.replace("+", "").trim());
    } else {
      this.props.emptySearchResults();
    }
  };

  selectProfile = (profile) => {
    console.log(profile);
    this.setState({ isLoading: true });
    this.props
      .linkNewSupportAgent({ userId: profile.account._id })
      .then(() => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false, isFailure: true, isSuccess: false });
      })
      .finally(() => {
        this.setState({
          number: "",
          email: "",
          firstName: "",
          lastName: "",
          gender: "male",
          dob: "",
          nationalId: "",
          passportNumber: "",
          searchResults: [],
          nationality: "botswana",
        });
      });
  };

  createSupportAgent = (e) => {
    e.preventDefault();

    let body = {
      number: this.state.number,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      dob: this.state.dob,
      nationality: this.state.nationality,
      passportNumber: this.state.passportNumber,
      nationalId: this.state.nationalId,
    };

    this.setState({ isLoading: true });
    this.props
      .pushNewSupportAgent(body)
      .then(() => {
        this.setState({
          isLoading: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false, isFailure: true, isSuccess: false });
      })
      .finally(() => {
        this.setState({
          number: "",
          email: "",
          firstName: "",
          lastName: "",
          gender: "male",
          dob: "",
          nationalId: "",
          passportNumber: "",
          searchResults: [],
          nationality: "botswana",
        });
      });
  };

  render() {
    return (
      <div id="supportAgentWizard" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-0" style={{ padding: "5em" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div className="modal__featured d-none d-xl-block">
                    <div className="modal__circle"></div>
                    <div className="modal__product hidden-xs">
                      <img
                        style={{
                          marginTop: "12em",
                          marginBottom: "2em",
                          maxWidth: "124%",
                        }}
                        alt="dumelapayLogo"
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p style={{ fontSize: "17px", color: "white" }}>
                        Lets help you help yourself.
                      </p>
                    </div>
                  </div>
                  <div className="modal__content">
                    {this.state.isLoading ? (
                      <p>processing</p>
                    ) : this.state.isSuccess ? (
                      <SuccessAnim />
                    ) : this.state.isFailure ? (
                      <div className="text-center">
                        <FailedAnim />
                      </div>
                    ) : (
                      <>
                        <h2>Employee Details</h2>
                        <form
                          onSubmit={(e) => this.createSupportAgent(e)}
                          style={{ marginTop: "3em" }}
                        >
                          <ul className="form-list">
                            <li className="form-list__row">
                              <label>Mobile Number</label>
                              <div id="input--number">
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="BW"
                                  countries={["BW"]}
                                  value={this.state.number}
                                  onChange={(e) => this.updateNumber(e)}
                                />
                              </div>
                            </li>

                            {this.props.destProfiles.length > 0 ? (
                              <li className="form-list__row row">
                                {this.props.destProfiles.map((profile) => (
                                  <div
                                    className="col-lg-6 p-1"
                                    onClick={() => this.selectProfile(profile)}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        padding: "0.2em 1em",
                                        paddingLeft: "0.2em",
                                        backgroundColor: "white",
                                        borderRadius: "50px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "2.6rem",
                                          height: "2.6rem",
                                          margin: "auto 0",
                                        }}
                                      >
                                        {profile.propic ? (
                                          <img
                                            src={
                                              BASE_URL +
                                              fetchProPic() +
                                              `?picId=${profile.propic}&size=480x480`
                                            }
                                            className="img-fluid rounded-circle"
                                            alt=""
                                            style={{
                                              objectFit: "cover",
                                              width: "2.6rem",
                                              height: "2.6rem",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={"/assets/img/img0.jpg"}
                                            className="img-fluid rounded-circle"
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div
                                        className="mg-l-15"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p
                                          className="tx-bold tx-black"
                                          style={{
                                            marginTop: "0",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {profile.profileType === "merchant"
                                            ? profile.businessName
                                            : profile.account.firstName +
                                              " " +
                                              profile.account.lastName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </li>
                            ) : (
                              <>
                                <li className="form-list__row row">
                                  <div className="col-6">
                                    <label>FirstName</label>
                                    <div id="input--firstName">
                                      <input
                                        className="payment_wizard_input"
                                        type="text"
                                        required
                                        value={this.state.firstName}
                                        onChange={(e) =>
                                          this.setState({
                                            firstName: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label>LastName</label>
                                    <div id="input--lastName">
                                      <input
                                        className="payment_wizard_input"
                                        type="text"
                                        required
                                        value={this.state.lastName}
                                        onChange={(e) =>
                                          this.setState({
                                            lastName: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </li>
                                <li className="form-list__row row">
                                  <div className="col-6">
                                    <label>Gender</label>
                                    <div id="input--gender">
                                      <select
                                        id="gender"
                                        className="form-control select2 payment_wizard_input"
                                        onChange={(e) =>
                                          this.setState({
                                            gender: e.target.value,
                                          })
                                        }
                                        required
                                      >
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label>Date of birth</label>
                                    <div id="input--dob">
                                      <input
                                        className="payment_wizard_input"
                                        type="text"
                                        required
                                        value={this.state.dob}
                                        onChange={(e) =>
                                          this.setState({
                                            dob: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </li>
                                <li className="form-list__row row">
                                  <div className="col-6">
                                    <label>Nationality</label>
                                    <div id="input--nationality">
                                      <select
                                        id="nationality"
                                        className="form-control select2 payment_wizard_input"
                                        onChange={(e) =>
                                          this.setState({
                                            nationality: e.target.value,
                                          })
                                        }
                                        required
                                      >
                                        {this.state.countries.map((country) => (
                                          <option
                                            key={country.value}
                                            value={country.value}
                                          >
                                            {country.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  {this.state.nationality === "botswana" ? (
                                    <div className="col-6">
                                      <label>National ID</label>
                                      <div id="input--nId">
                                        <input
                                          className="payment_wizard_input"
                                          type="number"
                                          required
                                          value={this.state.nId}
                                          onChange={(e) =>
                                            this.setState({
                                              nId: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-6">
                                      <label>Passport Number</label>
                                      <div id="input--passportNumber">
                                        <input
                                          className="payment_wizard_input"
                                          type="text"
                                          required
                                          value={this.state.passportNumber}
                                          onChange={(e) =>
                                            this.setState({
                                              passportNumber: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </li>
                              </>
                            )}

                            <li>
                              <div className="summary-item">
                                <button
                                  type="submit"
                                  className="btn btn-oblong btn-primary btn-block mg-b-10"
                                  style={{
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                  }}
                                >
                                  submit
                                </button>
                              </div>
                            </li>
                          </ul>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destProfiles: state.account.destProfiles,
    profile: state.account.targetProfile,
    isSuccess: state.walletData.isSuccess,
    isFailure: state.walletData.isFailure,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (pType) => dispatch(fetchProfileWallets(pType)),
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    emptySearchResults: () => dispatch(clearDestProfiles()),
    pushNewSupportAgent: (body) => dispatch(createNewSupportAgent(body)),
    linkNewSupportAgent: (body) => dispatch(bindSupportAgent(body)),
    unlinkNewSupportAgent: (body) => dispatch(unbindSupportAgent(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportAgentWizard);
