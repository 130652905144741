import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import TransactionsListSkeleton from "../components/TransactionsListSkeleton";
import SupportAgentList from "../components/SupportAgentList";
import SupportAgentWizard from "../components/SupportAgentWizard";
import { fetchProfileSupportAgents } from "../store/actions/usersActions";

class AgentDock extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isFetchingSupportAgents: true,
    profileNumber: "",
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileSupportAgents(profileInfo.tprofile._id).finally(() =>
        this.setState({
          isFetchingSupportAgents: false,
          profileNumber: profileInfo.tprofile.number,
        })
      );
    } else {
      window.location.href = "/signup";
    }
  }

  render() {
    return (
      <>
        <div className="col-md-8" style={{ justifyContent: "flex-start" }}>
          {this.state.isFetchingSupportAgents ? (
            <TransactionsListSkeleton />
          ) : (
            <SupportAgentList parent={this.state.profileNumber} />
          )}
        </div>
        <div className="col-md-4">
          <div
            id="topup-btn"
            data-toggle="modal"
            // onClick={() => this.updateState()}
            data-target="#supportAgentWizard"
            style={{
              border: "3px dashed #0F7ABF",
              color: "#0F7ABF",
              width: "10em",
              height: "10em",
              borderRadius: "50%",
              cursor: "pointer",
              margin: "auto",
              marginTop: "13%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginTop: "37%",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              Enroll Support Employee
            </p>
          </div>
        </div>
        <SupportAgentWizard />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supportAgents: state.account.supportAgents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileSupportAgents: (profile) =>
      dispatch(fetchProfileSupportAgents(profile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentDock);
