import React, { Component } from "react";
import { connect } from "react-redux";
import {
  completePayment,
  createTransfer,
  fetchProfileWallet,
  fetchProfileWallets,
  fetchWalletTransactions,
  updateInheritTxnStage2,
  updateWalletUtilityState,
} from "../store/actions/walletActions";
import {
  clearDestProfiles,
  searchPProfiles,
} from "../store/actions/usersActions";
import { BASE_URL } from "../api/client";
import { fetchProPic } from "../api/account";
import { updateDestProfile } from "../store/actions/walletActions";

class TransferWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    destProfiles: [],
    tProfile: {},
    txnStage: 0,
    txnAmount: 0.0,
    txnId: null,
    isLoading: false,
    otp: null,
    isSearching: false,
    isEmpty: false,
    number: "",
  };

  componentDidMount() {}

  getProfiles(number) {
    if (number && number.length >= 6) {
      this.setState({ number: number });
      this.props.fetchPProfiles(number).finally(() => {
        this.props.destProfiles.length < 1
          ? this.setState({ isSearching: false, isEmpty: true })
          : this.setState({ isSearching: false, isEmpty: false });
      });

      if (this.validateNumber(number)) {
        this.props.updateUtilityState({ isValidNumber: true });
      } else {
        this.props.updateUtilityState({ isValidNumber: false });
      }
    } else {
      this.setState({ isSearching: false });
      this.props.resetDestProfiles();
    }
  }

  validateNumber = (number) => {
    return number.length === 8;
  };

  selectTProfile(profile) {
    this.updateState(1);
    this.props.updateTProfile(profile);
  }
  initTxn = (e) => {
    e.preventDefault();
    let wId = this.getCoreId()[0].walletId;
    this.setState({ isLoading: true });
    this.props
      .startTxn(
        wId,
        this.props.tProfile.number || "267" + this.state.number,
        this.state.txnAmount,
        this.state.reference
      )
      .then(() => {
        this.setState({ txnId: this.props.txnId });
        this.updateState(2);
      })
      .catch((err) => {
        this.updateState(-1);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  closeModal() {
    this.setState({
      txnStage: 0,
      isLoading: false,
      tProfile: {},
      txnAmount: 0,
      paymentType: "",
      txnId: null,
      accNumber: null,
      otp: null,
      destProfiles: [],
      isSearching: false,
    });
  }

  getCoreId() {
    return this.props.wallets.filter((w) => w.walletId.walletType === "CORE");
  }

  confirmTxn(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .verify(this.state.txnId, this.state.otp)
      .then(() => {
        this.props.getProfileWallet(this.props.wallet.walletId);
        this.props.getTransactions(this.props.wallet.walletId, 0, 10);
        this.props.resetDestProfiles();
        this.updateState(10);
      })
      .catch((err) => {
        this.props.resetDestProfiles();
        this.updateState(-1);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  updateState = (stage) => {
    this.props.updateTxnStage2(stage);
  };

  render() {
    return (
      <div id="transfermodal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-sm-0 pd-lg-50">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div
                    className="modal__featured d-none d-xl-block"
                    style={{ minWidth: "600px" }}
                  >
                    <div className="modal__circle"></div>
                    <div
                      className="modal__product hidden-xs"
                      style={{ left: "39%", maxWidth: "100%" }}
                    >
                      <img
                        style={{
                          marginTop: "5em",
                          marginBottom: "2em",
                          maxWidth: "65%",
                        }}
                        alt="Dumela Pay logo"
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p style={{ fontSize: "17px", color: "white" }}>
                        Lets help you send money.
                      </p>
                      <div className="row" style={{ width: "130%" }}>
                        {this.props.txnStage === 0 ? (
                          this.props.destProfiles.map((profile, idx) => (
                            <>
                              <div
                                key={idx}
                                className="col-lg-6 p-1"
                                onClick={() => this.selectTProfile(profile)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    padding: "0.2em 1em",
                                    paddingLeft: "0.2em",
                                    backgroundColor: "white",
                                    borderRadius: "50px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "2.6rem",
                                      height: "2.6rem",
                                      margin: "auto 0",
                                    }}
                                  >
                                    {profile.propic ? (
                                      <img
                                        src={
                                          BASE_URL +
                                          fetchProPic() +
                                          `?picId=${profile.propic}&size=480x480`
                                        }
                                        className="img-fluid rounded-circle"
                                        alt=""
                                        style={{
                                          objectFit: "cover",
                                          width: "2.6rem",
                                          height: "2.6rem",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={"/assets/img/img0.jpg"}
                                        className="img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="mg-l-15"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      className="tx-bold tx-black"
                                      style={{
                                        marginTop: "0",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {profile.profileType === "merchant"
                                        ? profile.businessName
                                        : profile.account.firstName +
                                          " " +
                                          profile.account.lastName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <div
                            className="col-lg-12 p-1"
                            style={{ textAlign: "center" }}
                          >
                            <h1 className="tx-white mg-b-20">
                              {this.props.tProfile.account
                                ? this.props.tProfile.account.firstName
                                  ? this.props.tProfile.account.firstName +
                                    " " +
                                    this.props.tProfile.account.lastName
                                  : ""
                                : ""}
                            </h1>
                            <p
                              className="tx-bold tx-white"
                              style={{
                                marginTop: "0",
                                marginBottom: "0",
                                fontSize: "2em",
                              }}
                            >
                              P {this.state.txnAmount}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal__content">
                    {this.state.isLoading ? (
                      <div className="pd-x-100 pd-y-100">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Proccessing ....
                        </h4>
                        {/* <p>Proccessing ....</p> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 0 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Find Receipent
                        </h3>
                        <p>Search for mobile number</p>
                        <br />
                        <div className="form-group pd-y-60 pd-xl-x-30">
                          <div style={{ display: "flex" }}>
                            <p
                              className="mb-0 mr-1 pt-2"
                              style={{ fontSize: "15px" }}
                            >
                              267
                            </p>

                            <input
                              type="text"
                              name="search"
                              className="form-control pd-y-12 mg-b-10"
                              style={{ borderRadius: "50px" }}
                              placeholder="Search"
                              onChange={(e) => this.getProfiles(e.target.value)}
                            />
                          </div>
                          {this.state.isSearching ? (
                            <p>Searching...</p>
                          ) : this.state.isEmpty && this.props.isValidNumber ? (
                            <p className="text-danger">
                              Not a dumela pay user, would like to send to a non
                              dumela pay user?
                            </p>
                          ) : this.state.isEmpty ? (
                            <p>No users found ...</p>
                          ) : (
                            ""
                          )}
                          {this.state.isEmpty && this.props.isValidNumber ? (
                            <button
                              onClick={() => this.updateState(1)}
                              className="btn btn-primary pd-y-12 btn-block btn-oblong"
                            >
                              Continue
                            </button>
                          ) : (
                            <button className="btn btn-primary pd-y-12 btn-block btn-oblong">
                              Search
                            </button>
                          )}
                          <div className="row  mt-4 d-xl-none">
                            {this.props.destProfiles.map((profile, idx) => (
                              <div
                                key={idx}
                                className="col-lg-12 p-1"
                                onClick={() => this.selectTProfile(profile)}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    padding: "0.2em 1em",
                                    paddingLeft: "0.2em",
                                    backgroundColor: "white",
                                    border: "solid 1px #0f7abf",
                                    borderRadius: "50px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "2.6rem",
                                      height: "2.6rem",
                                      margin: "auto 0",
                                    }}
                                  >
                                    {profile.propic ? (
                                      <img
                                        src={
                                          BASE_URL +
                                          fetchProPic() +
                                          `?picId=${profile.propic}&size=480x480`
                                        }
                                        className="img-fluid rounded-circle"
                                        alt=""
                                        style={{
                                          objectFit: "cover",
                                          width: "2.6rem",
                                          height: "2.6rem",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={"/assets/img/img0.jpg"}
                                        className="img-fluid rounded-circle"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="mg-l-15"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      className="tx-bold tx-black"
                                      style={{
                                        marginTop: "0",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {profile.profileType === "merchant"
                                        ? profile.businessName
                                        : profile.account.firstName +
                                          " " +
                                          profile.account.lastName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 1 && !this.state.isLoading ? (
                      <form
                        onSubmit={(e) => this.initTxn(e)}
                        className="pd-x-30 pd-y-10"
                      >
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Transfer Amount
                        </h3>
                        <br />
                        <div className="form-group pd-y-60 pd-xl-x-30">
                          <input
                            type="number"
                            name="amount"
                            step=".01"
                            min={1}
                            required
                            className="form-control pd-y-12 mg-b-10  tx-48"
                            style={{ border: "none", textAlign: "center" }}
                            placeholder="0.00"
                            onChange={(e) =>
                              this.setState({ txnAmount: e.target.value })
                            }
                          />
                          <button
                            className="btn btn-primary pd-y-12 btn-block btn-oblong"
                            type="submit"
                          >
                            send
                          </button>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 2 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <form onSubmit={(e) => this.confirmTxn(e)}>
                          <h3 className="tx-gray-800 tx-normal mg-b-5">
                            Please verify transaction
                          </h3>
                          <p>Enter OTP</p>
                          <br />
                          <div className="form-group pd-y-60 pd-xl-x-30">
                            <input
                              type="text"
                              required
                              className="form-control pd-y-12 mg-b-10"
                              style={{ borderRadius: "50px" }}
                              placeholder="verification pin"
                              onChange={(e) =>
                                this.setState({ otp: e.target.value })
                              }
                            />
                            <button
                              className="btn btn-primary pd-y-12 btn-block btn-oblong"
                              type="submit"
                            >
                              Verify
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === -1 && !this.state.isLoading ? (
                      <div className="pd-x-55 pd-y-55">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Transaction failed....
                        </h4>
                        <lottie-player
                          src="https://assets2.lottiefiles.com/packages/lf20_ed9D2z.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "300px", height: "300px" }}
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 10 && !this.state.isLoading ? (
                      <div className="pd-x-55 pd-y-55">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Transaction Successful....
                        </h4>
                        <lottie-player
                          src="https://assets1.lottiefiles.com/packages/lf20_vuliyhde.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "300px", height: "300px" }}
                          autoplay
                        ></lottie-player>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.walletData.wallet,
    wallets: state.walletData.wallets,
    destProfiles: state.account.destProfiles,
    tProfile: state.walletData.destProfile,
    txnId: state.walletData.txnId,
    txnStage: state.walletData.txnStage2,
    isValidNumber: state.walletData.isValidNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (pType) => dispatch(fetchProfileWallets(pType)),
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    startTxn: (walletId, dNumber, amount, reference) =>
      dispatch(createTransfer(walletId, dNumber, amount, reference)),
    verify: (txnId, txnCode) => dispatch(completePayment(txnId, txnCode)),
    updateTxnStage2: (stage) => dispatch(updateInheritTxnStage2(stage)),
    updateTProfile: (profile) => dispatch(updateDestProfile(profile)),
    resetDestProfiles: () => dispatch(clearDestProfiles()),
    updateUtilityState: (state) => dispatch(updateWalletUtilityState(state)),
    getTransactions: (wId, idx, size) =>
      dispatch(fetchWalletTransactions(wId, idx, size)),
    getProfileWallet: (wallet) => dispatch(fetchProfileWallet(wallet)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferWizard);
