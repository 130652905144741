import React, { Component } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import {
  addPersonalProfile,
  getCurrentUser,
} from "../store/actions/usersActions";

import { countries } from "../constants";
class SignupWizard extends Component {
  state = {
    firstname: null,
    lastname: null,
    dob: null,
    gender: "male",
    pEmail: null,
    sEmail: null,
    nationality: "botswana",
    nationalId: null,
    passportNumber: null,
    country: null,
    countries: countries.map((country) => {
      return {
        value: country[0].toLowerCase() + country.slice(1),
        label: country,
      };
    }),
    address: null,
    city: null,
    livingAddress: null,
    postalAddress: null,
    isSubmiting: false,
    isSubmitted: false,
    isIdValid: false,
    errorMessage: "",
  };

  constructor(props) {
    super(props);
    this.updateFirstName = this.updateFirstName.bind(this);
  }

  getMaxDate = () => {
    let today = new Date();
    let year = today.getFullYear() - 10;
    let month = today.getMonth();
    let day = today.getDay();

    var maxYear = year + "-" + month + "-" + day;
    return maxYear;
  };

  componentDidMount() {
    this.props.fetchUser();
    const _this = this;
    window.$(document).ready(function () {
      if (window.$("#nationality").val() === "botswana") {
        window.$("#passportnumbercol").css("display", "none");
        window.$("#nIDCol").css("display", "block");
      } else {
        window.$("#passportnumbercol").css("display", "block");
        window.$("#nIDCol").css("display", "none");
      }

      window.$(document.body).on("change", "#nationality", function (e) {
        _this.setState({ nationality: window.$("#nationality").val() });
        if (window.$("#nationality").val() === "botswana") {
          window.$("#passportnumbercol").css("display", "none");
          window.$("#nIDCol").css("display", "block");
        } else {
          window.$("#passportnumbercol").css("display", "block");
          window.$("#nIDCol").css("display", "none");
        }
      });

      window.$(document.body).on("change", "#gender", function (e) {
        _this.setState({ gender: window.$("#gender").val() });
        if (window.$("#nationality").val() === "botswana") {
          if (window.$("#gender").val() === "male") {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 1) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          } else {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 2) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          }
        }
      });

      window.$(document.body).on("change", "#nID", function (e) {
        if (window.$("#nationality").val() === "botswana") {
          if (window.$("#gender").val() === "male") {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 1) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          } else {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 2) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          }
        }
      });
    });
    window.$("#wizard2").steps({
      headerTag: "h3",
      bodyTag: "section",
      autoFocus: true,
      titleTemplate:
        '<span class="number">#index#</span> <span class="title">#title#</span>',
      onStepChanging: function (event, currentIndex, newIndex) {
        if (currentIndex < newIndex) {
          // Step 1 form validation
          if (currentIndex === 0) {
            var fname = window.$("#firstname").val();
            var lname = window.$("#lastname").val();
            var email = window.$("#email").val();
            var semail = window.$("#semail").val();
            var dob = window.$("#dob").val();
            var nId = window.$("#nID").val();
            var gender = window.$("#gender").val();

            var country = window.$("#country").val();
            var passportNumber = window.$("#passportnumber").val();

            if (country == "botswana") {
              if (!_this.state.isIdValid) {
                return false;
              }
            } else {
              if (passportNumber === "") {
                return false;
              }
            }

            if (
              fname === "" ||
              lname === "" ||
              // email === "" ||
              // semail === "" ||
              dob === "" ||
              gender === ""
            ) {
              return false;
            } else {
              _this.setState({
                firstname: fname,
                lastname: lname,
                pEmail: email,
                sEmail: semail,
                dob: dob,
                nationalId: nId,
                gender: gender,
              });
              return true;
            }
          }

          // Step 2 form validation
          if (currentIndex === 1) {
            var country = window.$("#country").val();
            var city = window.$("#city").val();
            var address = window.$("#address").val();

            if (country === "" || city === "" || address === "") {
              return false;
            } else {
              _this.setState({
                address: address,
                city: city,
                livingAddress: country,
              });
              return true;
            }
          }
          // Always allow step back to the previous step even if the current step is not valid.
        } else {
          return true;
        }
        return true;
      },
      onFinishing: function (event, currentIndex) {
        var country = window.$("#country").val();
        var city = window.$("#city").val();
        var address = window.$("#address").val();
        var postalAddress = window.$("#postaladdress").val();

        if (
          country === "" ||
          city === "" ||
          address === "" ||
          postalAddress === ""
        ) {
          return false;
        } else {
          _this.setState({
            address: address,
            city: city,
            livingAddress: country,
            postalAddress: postalAddress,
          });
          // fire submission event
          _this.handleReg();

          // fire loading animation
          window.$("#wizard2").css("display", "none");
          window.$("#submissionAnim").css("display", "block");
          return true;
        }
      },
    });
    window.$("#wizard3").steps({
      headerTag: "h4",
      bodyTag: "section",
      autoFocus: true,
      titleTemplate:
        '<span class="number">#index#</span> <span class="title">#title#</span>',
      onStepChanging: function (event, currentIndex, newIndex) {
        console.log(newIndex, event);
        return true;
      },
    });
    $(function () {
      // Select2 by showing the search
      // $('#select10').select2({
      //   containerCssClass: 'select2-full-color select2-primary',
      //   dropdownCssClass: 'select2-drop-color select2-drop-primary',
      //   minimumResultsForSearch: Infinity // disabling search
      // });
    });
    this.setState({ country: this.state.countries[0].value });
  }

  changeNationality(e) {
    console.log(e);
  }

  updateFirstName(e) {
    // console.log(e)
    this.setState({ firstname: e.target.value });
  }

  handleReg() {
    let body = {
      accountId: this.props.acc._id,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      dob: this.state.dob,
      email: this.state.pEmail,
      nationality: this.state.nationality,
      nationalId: this.state.nationalId,
      passportNumber: this.state.passportNumber,
      country: this.state.country,
      city: this.state.city,
      address: this.state.address,
      postalAddress: this.state.postalAddress,
    };

    this.props
      .addProfile(body)
      .then(() => {
        console.log("reg  complete");
        window.location.href = "/personal/transactions";
      })
      .catch((err) => {
        this.setState({ errorMessage: err.data });
        window.$("#submissionAnim").css("display", "none");
        window.$("#submissionFail").css("display", "block");
        console.log(err);
      });
  }
  resetState = () => {
    this.setState({
      errorMessage: "",
    });
    window.$("#submissionAnim").css("display", "none");
    window.$("#submissionFail").css("display", "none");
    window.$("#wizard2").css("display", "block");
  };

  render() {
    return (
      <div className="signin-box signup">
        <div
          id="submissionAnim"
          style={{
            width: "100%",
            height: "100vh",
            paddingTop: "40vh",
            textAlign: "center",
            display: "none",
          }}
        >
          <p>Please wait as we setup your profile....</p>
        </div>
        <div
          id="submissionFail"
          style={{
            width: "100%",
            height: "100vh",
            paddingTop: "40vh",
            textAlign: "center",
            display: "none",
          }}
        >
          <p>{this.state.errorMessage}</p>
          <button
            onClick={() => this.resetState()}
            className="btn btn-primary btn-block btn-signin"
            type="submit"
          >
            Try Again
          </button>
        </div>
        <div id="wizard2">
          <h3>Personal Information</h3>
          <section style={{ backgroundColor: "white" }}>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Firstname: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="firstname"
                    className="form-control"
                    name="firstname"
                    placeholder="Firstname"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Lastname: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="lastname"
                    className="form-control"
                    name="lastname"
                    placeholder="Lastname"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Date Of Birth: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="dob"
                    className="form-control"
                    name="dob"
                    type="date"
                    data-format="MMMM DD YYYY"
                    max={this.getMaxDate().toString()}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Gender: <span className="tx-danger">*</span>
                  </label>
                  <select
                    id="gender"
                    className="form-control select2"
                    data-placeholder="Nationality"
                    name="nationality"
                    required
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Primary Email: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Primary Email"
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Nationality: <span className="tx-danger">*</span>
                  </label>
                  <select
                    id="nationality"
                    className="form-control select2"
                    data-placeholder="Nationality"
                    name="nationality"
                    required
                  >
                    <option key="botswana" value="botswana">
                      Botswana
                    </option>
                    {this.state.countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6" id="nIDCol">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    National ID: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="nID"
                    className="form-control"
                    name="nID"
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6" id="passportnumbercol">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Passport Number: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="passportnumber"
                    className="form-control"
                    name="passportnumber"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </section>
          <h3>Location Information</h3>
          <section style={{ backgroundColor: "white" }}>
            <div
              className="row row-xs mg-b-11"
              style={{ width: "50%", margin: "auto" }}
            >
              <div className="col-md-12">
                {/* <div className="form-group ">
                  <label className="form-control-label tx-semibold">Operation Region <span className="tx-danger">*</span></label>
                  <div className="row row-xs mg-b-10">
                    <div className="col-lg-6 mg-t-20 mg-lg-t-0">
                      <label className="ckbox">
                        <input id=""type="checkbox" checked onChange={() => {}}/><span>Africa</span>
                      </label>
                    </div>
                    <div className="col-lg-6 mg-t-20 mg-lg-t-0">
                      <label className="ckbox">
                        <input type="checkbox" checked onChange={() => {}}/><span>Region 2</span>
                      </label>
                    
                    </div>
                    <div className="col-lg-6 mg-t-20 mg-lg-t-0">
                      <label className="ckbox">
                        <input type="checkbox" checked onChange={() => {}}/><span>Region 2</span>
                      </label>
                    </div>
                  </div>
                  
                </div> */}
              </div>
              <div className="col-md-12">
                <label className="form-control-label tx-semibold ">
                  Country<span className="tx-danger">*</span>
                </label>
                <div className="wd-0">
                  <select
                    id="country"
                    className="form-control"
                    data-placeholder="Choose one"
                  >
                    <option key="botswana" value="botswana">
                      Botswana
                    </option>
                    {this.state.countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <label className="form-control-label tx-semibold ">
                  City/Town/Village<span className="tx-danger">*</span>
                </label>
                <input
                  id="city"
                  className="form-control"
                  name="lastname"
                  placeholder="town"
                  type="text"
                  required
                />
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Physical Address: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="address"
                    className="form-control"
                    name="address"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Postal Address: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="postaladdress"
                    className="form-control"
                    name="postaladdress"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(getCurrentUser()),
    addProfile: (body) => dispatch(addPersonalProfile(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupWizard);
