import React, { Component } from "react";
import { connect } from "react-redux";

import { updateSignatoryState } from "../store/actions/usersActions";
import SuccessAnim from "./SuccessAnim";
import FailedAnim from "./FailedAnim";

import {
  changeProfileDevice,
  createProfileSupportDevice,
  updateDevicesState,
} from "../store/actions/deviceActions";

class SupportDeviceUpdateWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    reason: "",
  };

  componentDidMount() {}

  pushDevice = (e) => {
    e.preventDefault();
    this.props.updateState({ devices_state: 4 });
    let body = {
      status: !this.props.device.status,
      identifier: this.props.device._id,
      reason: this.state.reason,
    };

    this.props
      .updateDevice(body)
      .then(() => {
        this.props.updateState({
          devices_state: 3,
        });
      })
      .catch((err) => {
        this.props.updateState({
          devices_state: 2,
        });
      });
  };

  render() {
    return (
      <div id="supportDeviceUpdateModal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-0" style={{ padding: "5em" }}>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div className="modal__featured d-none d-sm-block">
                    <div className="modal__circle"></div>
                    <div className="modal__product hidden-xs">
                      <img
                        style={{
                          marginTop: "12em",
                          marginBottom: "2em",
                          maxWidth: "124%",
                        }}
                        alt=""
                        src="/assets/img/dumelapay-clear.png"
                      />
                    </div>
                  </div>
                  <div className="modal__content">
                    <h2>Modify support device</h2>
                    {this.props.devices_state == 4 ? (
                      <div className="text-center mt-5">
                        <p>Proccessing</p>
                      </div>
                    ) : this.props.devices_state == 3 ? (
                      <div className="text-center">
                        <SuccessAnim />
                        <p>Successful</p>
                      </div>
                    ) : this.props.devices_state == 2 ? (
                      <div className="text-center">
                        <FailedAnim />
                        <p>Failed to add device</p>
                      </div>
                    ) : (
                      <form
                        onSubmit={(e) => this.pushDevice(e)}
                        style={{ marginTop: "7em" }}
                      >
                        <ul className="form-list">
                          <li className="form-list__row">
                            <label>
                              {this.props.device.status
                                ? "Disable Reason"
                                : "Enable Reason"}
                              :
                            </label>
                            <div id="input--signatory">
                              <input
                                className="payment_wizard_input"
                                type="text"
                                required
                                value={this.state.reason}
                                onChange={(e) =>
                                  this.setState({
                                    reason: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </li>
                          <li>
                            <div className="summary-item">
                              <button
                                type="submit"
                                className="btn btn-oblong btn-primary btn-block mg-b-10"
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </li>
                        </ul>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.account.targetProfile,
    devices_state: state.deviceData.devices_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDevice: (body) => dispatch(createProfileSupportDevice(body)),
    updateState: (body) => dispatch(updateDevicesState(body)),
    updateDevice: (body) => dispatch(changeProfileDevice(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportDeviceUpdateWizard);
