// import { relativeTimeThreshold } from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../index";
// import FileDrop from "../components/FileDrop";
import Cookies from "js-cookie";
import { getFiles, pushKyc } from "../store/actions/kycActions";
import FailedAnim from "../components/FailedAnim";
import UploadAnim from "../components/UploadAnim";
import KycAnim from "../components/KycAnim";
import SuccessAnim from "../components/SuccessAnim";
import moment from "moment";
import { downloadFile } from "../api/kyc";
import { fetchProfileWallets } from "../store/actions/walletActions";

class Kyc extends Component {
  state = {
    category: {
      name: "idcard / passport",
      messsage:
        "Make sure that both side of the id card or passport are clearly visible on the document",
    },
    categories: [
      {
        name: "idcard / passport",
        messsage:
          "Make sure that both side of the National id card are clearly visible on the document",
      },
      { name: "passport", messsage: "" },
      { name: "proofofresidence", messsage: "" },
      { name: "proofofincome", messsage: "" },
      { name: "businesscertificate", messsage: "" },
      { name: "additional docs", messsage: "" },
    ],
    file: {},
    isUploadFailed: false,
    isUploading: false,
    isUploadComplete: false,
  };

  componentDidMount() {
    this.props.pullFiles(this.props.profile._id);
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileWallets(
        profileInfo.tprofile.profileType || this.props.profile.profileType
      );
    }
  }

  download = (id) => {
    downloadFile(id);
  };
  updateCategory = (category) => {
    console.log(category);
    let selectedCategory = this.state.categories.filter(
      (cat) => cat.name === category
    );
    this.setState({ category: selectedCategory[0], isUploadComplete: false });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  changeFile(file, ctx) {
    if (!ctx) return;

    ctx.setState({
      file: {
        path: file.path,
        file: file,
        name: file.name,
      },
    });
  }

  uploadFile = (e) => {
    e.preventDefault();
    if (!this.state.file.file) return;

    var body = new FormData();
    body.append("file", this.state.file.file, this.state.file.path);
    body.append("category", this.state.category.name);
    body.append("pId", this.props.profile._id);
    // let body = {
    //   file: this.state.file.file,
    //   category: this.state.category.name,
    //   pId: this.props.profile._id
    // }

    this.setState({
      isUploadFailed: false,
      isUploading: true,
      isUploadComplete: false,
    });

    this.props
      .pushFile(body)
      .then(() => {
        this.props.pullFiles(this.props.profile._id);
        this.setState({
          isUploadFailed: false,
          isUploading: false,
          isUploadComplete: true,
        });
      })
      .catch((err) => {
        this.setState({
          isUploading: false,
          isUploadFailed: true,
          isUploadComplete: true,
        });
      })
      .finally(() => {
        store.dispatch({
          type: "UPLOAD_PROGRESS_EVENT",
          data: { percent: 0, total: 0, uploaded: 0 },
        });
      });
  };

  render() {
    return (
      <>
        <div className="col-12 card  card-body" style={{ border: "none" }}>
          <div className="row">
            <div className="col-8">
              <h4 className="mb-4">Required documents </h4>
              <p>Select kind of document</p>
              <div className="form-group row mb-2">
                <div className="col-md-3 col-sm-12 col-xs-12 mb-4">
                  <div
                    className={
                      this.state.category.name === "idcard / passport"
                        ? "card card-body kyc-card kyc-card-active"
                        : "card card-body kyc-card"
                    }
                    onClick={() => this.updateCategory("idcard / passport")}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginBottom: "0px",
                      }}
                    >
                      ID Card/Passport
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12 mb-4">
                  <div
                    className={
                      this.state.category.name === "proofofresidence"
                        ? "card card-body kyc-card kyc-card-active"
                        : "card card-body kyc-card"
                    }
                    onClick={() => this.updateCategory("proofofresidence")}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginBottom: "0px",
                      }}
                    >
                      Proof of residence
                    </p>
                  </div>
                </div>
                {this.props.profile.profileType === "personal" ? (
                  <div className="col-md-3 col-sm-12 mb-4">
                    <div
                      className={
                        this.state.category.name === "proofofincome"
                          ? "card card-body kyc-card kyc-card-active"
                          : "card card-body kyc-card"
                      }
                      onClick={() => this.updateCategory("proofofincome")}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          marginBottom: "0px",
                        }}
                      >
                        Proof of income
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-3 col-sm-12 mb-4">
                    <div
                      className={
                        this.state.category.name === "proofofincome"
                          ? "card card-body kyc-card kyc-card-active"
                          : "card card-body kyc-card"
                      }
                      onClick={() => this.updateCategory("businesscertificate")}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          marginBottom: "0px",
                        }}
                      >
                        Business Certificate / License
                      </p>
                    </div>
                  </div>
                )}
                <div className="col-md-3 col-sm-12 mb-4">
                  <div
                    className={
                      this.state.category.name === "additional docs"
                        ? "card card-body kyc-card kyc-card-active"
                        : "card card-body kyc-card"
                    }
                    onClick={() => this.updateCategory("additional docs")}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginBottom: "0px",
                      }}
                    >
                      Additional docs
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>{this.state.category.messsage}</p>
                  <form>
                    <div className="wrapper mb-2">
                      <div className="file-upload">
                        <input
                          type="file"
                          required
                          onChange={(e) =>
                            this.changeFile(e.target.files[0], this)
                          }
                        />
                        <div style={{ textAlign: "center" }}>
                          <i
                            className="icon-upload-cloud"
                            style={{
                              display: "block",
                              fontSize: "28px",
                              color: "#0F7ABF",
                            }}
                          ></i>
                          <span style={{ display: "block" }}>
                            {" "}
                            Click or drag file to upload
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>{this.state.file.name}</p>
                    <div>
                      <button
                        onClick={(e) => this.uploadFile(e)}
                        className="btn btn-outline-primary mg-b-10"
                        style={{
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                          float: "right",
                          borderRadius: "20px",
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4 pt-5" style={{ textAlign: "center" }}>
              {this.state.isUploading ? (
                <UploadAnim />
              ) : this.state.isUploadFailed ? (
                <FailedAnim />
              ) : this.state.isUploadComplete ? (
                <SuccessAnim />
              ) : (
                <KycAnim />
              )}
            </div>
          </div>
        </div>
        <div className="col-12 card-body mt-5" style={{ border: "none" }}>
          <h4 className="mb-4">Your KYC Documentation</h4>
          <div className="list" id="kycdoclist">
            <ul className="p-0">
              <li
                className="row"
                style={{
                  background: "white",
                  listStyle: "none",
                  padding: "1em",
                  marginBottom: ".5em",
                  cursor: "pointer",
                }}
              >
                <div className="col-3">Upload Date</div>
                <div className="col-3">Category</div>
                <div className="col-3">Status </div>
                <div className="col-3">Download link</div>
              </li>
              {this.props.kycFiles.map((file) => (
                <li
                  key={file._id}
                  className="row"
                  style={{
                    background: "white",
                    listStyle: "none",
                    padding: "1em",
                    marginBottom: ".5em",
                    cursor: "pointer",
                  }}
                >
                  <div className="col-3">
                    {moment(file.createdAt).format("ddd D MMM, YYYY")}
                  </div>
                  <div
                    className="col-3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {file.category}
                  </div>
                  <div
                    className="col-3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {file.isVerified ? "Verified" : "Not verified"}
                  </div>
                  <div className="col-3">
                    <div
                      style={{ color: "#1b84e7" }}
                      onClick={() => this.download(file._id)}
                    >
                      download link
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profile: state.account.targetProfile,
    uploadProgress: state.kycData.uploadProgress,
    kycFiles: state.kycData.files,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    pushFile: (body) => dispatch(pushKyc(body)),
    pullFiles: (profile) => dispatch(getFiles(profile)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kyc);
