import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import EmptyAnim from "./EmptyAnim";

class ScheduledTxnList extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isLoading: true,
  };

  componentDidMount() {}

  render() {
    return (
      <>
        {this.props.txnGroup.items.length > 0 ? (
          <>
            <div className="transaction-block">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
              <ul className="transaction-list">
                {this.props.txnGroup.items.map((txn) => (
                  <li key={txn._id}>
                    <div className="wd-150 t-destination">
                      {txn.txn_reciever.name}
                    </div>
                    <div className="wd-150 t-reference">
                      {txn.txn_reciever.number}
                    </div>
                    <div className="wd-150 t-reference">
                      Amount: {txn.amount}
                    </div>
                    <div className="wd-150 t-reference">
                      Status: {txn.status}
                    </div>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          </>
        ) : (
          <div>
            <label className="section-title mt-0">Transactions</label>

            <div
              className="transaction-block"
              style={{ textAlign: "center", padding: "3em" }}
            >
              <EmptyAnim />
              Looking for transactions
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    txnGroup: state.walletData.targetScheduledTxn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduledTxnList));
