import {
  createBasicAccount,
  createMerchantProfile,
  createPersonalProfile,
  getUser,
  searchBusiness,
  searchPersonal,
  updateAccount,
  fetchProfile,
  updatePropic,
  fetchProPic,
  getProfileSupportAgents,
  newSupportAgent,
  linkSupportAgent,
  unlinkSupportAgent,
  pushSignatory,
  pushSignatoryRequest,
  verifySignatory,
} from "../../api/account";

import Cookies from "js-cookie";

/**
 * get current user
 *
 */
export function issueNewBasicAccount(body) {
  return (dispatch) => {
    return createBasicAccount(body)
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          acc = response.data;
        } finally {
          dispatch({
            type: "CREATE_USER_ACCOUNT",
            data: acc,
          });
          return Promise.resolve(acc);
        }
      })
      .catch((err) => {
        if (err.response) {
          return Promise.reject({ data: err.response.data.err });
        }
        return Promise.reject({ data: err });
      });
  };
}

/**
 * @GET update user account
 */
export const updateAcc = (acc, userDetails) => {
  return (dispatch) => {
    return updateAccount({ acc: acc, updateInfo: userDetails })
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          acc = response.data;
        } finally {
          dispatch({
            type: "UPDATE_ACC",
            data: acc,
          });
          return Promise.resolve(acc);
        }
      })
      .catch((err) => Promise.reject(err));
  };
};

/**
 * get user profile by id
 *
 * @param  {String} userId user id
 */
export function getUserProfile(userId) {}

/**
 * get current user
 *
 */
export function getCurrentUser(profile) {
  return (dispatch) => {
    return getUser()
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;

          let profiles = acc.profiles.map((profile) => {
            return {
              profileType: profile.profileType,
              _id: profile._id,
              number: profile.number,
              email: profile.email,
            };
          });
          let current = Cookies.get("dpp");
          let tprofile = {};

          if (current) {
            current = JSON.parse(current);
            current = current.tprofile;
            if (current) {
              profiles = profiles.filter((profile) => {
                return profile.profileType === current.profileType;
              });
            }
          }
          tprofile = profiles[0];

          let cookieObj = { profiles: profiles, tprofile: tprofile };

          Cookies.set("dpp", JSON.stringify(cookieObj));

          dispatch({
            type: "GET_CURRENT_USER",
            data: acc,
          });
          return Promise.resolve(tprofile);
        } catch (err) {
          return Promise.reject(err);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

/**
 * get current profile info
 *
 */
export function getProfileInfo(profileId) {
  return (dispatch) => {
    return fetchProfile(profileId)
      .then((response) => {
        let profile =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;

        dispatch({
          type: "GET_PROFILE",
          data: profile,
        });
      })
      .catch((err) => Promise.reject(err));
  };
}

export function addPersonalProfile(body) {
  return (dispatch) => {
    return createPersonalProfile(body)
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;

          //update profile list
          let current = Cookies.get("dpp");
          current = JSON.parse(current);

          let profiles = current.profiles;
          let updatedProfiles = [acc, ...profiles];
          let cookieObj = { ...current, profiles: updatedProfiles };

          Cookies.set("dpp", JSON.stringify(cookieObj));

          dispatch({
            type: "CREATE_PROFILE",
            data: acc,
          });
          return Promise.resolve(acc);
        } catch (error) {
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          return Promise.reject({ data: error.response.data.error });
        } else {
          return Promise.reject({ data: error });
        }
      });
  };
}

export function addMerchantProfile(body) {
  return (dispatch) => {
    return createMerchantProfile(body)
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "CREATE_PROFILE",
            data: acc,
          });
          return Promise.resolve(acc);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
export function searchPProfiles(number) {
  return (dispatch) => {
    return searchPersonal(number)
      .then((response) => {
        let profiles;
        try {
          profiles =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_DEST_PROFILES",
            data: profiles,
          });
          return Promise.resolve(profiles);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function clearDestProfiles() {
  return (dispatch) => {
    return dispatch({
      type: "CLEAR_DEST_PROFILES",
      data: [],
    });
  };
}

export function searchBProfiles(number) {
  return (dispatch) => {
    return searchBusiness(number)
      .then((response) => {
        let profiles;
        try {
          profiles =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_DEST_PROFILES",
            data: profiles,
          });
          return Promise.resolve(profiles);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function updateProfilePic(body) {
  return (dispatch) => {
    return updatePropic(body)
      .then((response) => {
        let profile;
        try {
          profile =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          // Cookies.set("dpp", JSON.stringify(cookieObj));
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_PROFILE",
            data: profile,
          });
          return Promise.resolve(profile);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getPropic(pid, size) {
  return (dispatch) => {
    return fetchProPic(pid, size)
      .then((response) => {
        try {
          dispatch({
            type: "GET_PROFILE_PIC",
            data: response.data,
          });
        } catch (error) {
          return Promise.reject(error);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function fetchProfileSupportAgents(profile) {
  return (dispatch) => {
    return getProfileSupportAgents(profile)
      .then((response) => {
        let supportAgents;
        try {
          supportAgents =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_SUPPORT_AGENTS",
            data: supportAgents,
          });
          return Promise.resolve(supportAgents);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createNewSupportAgent(body) {
  return (dispatch) => {
    return newSupportAgent(body)
      .then((response) => {
        let supportAgent;
        try {
          supportAgent =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "CREATE_SUPPORT_AGENT",
            data: supportAgent,
          });
          return Promise.resolve(supportAgent);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function bindSupportAgent(body) {
  return (dispatch) => {
    return linkSupportAgent(body)
      .then((response) => {
        let supportAgent;
        try {
          supportAgent =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "LINK_SUPPORT_AGENT",
            data: supportAgent,
          });
          return Promise.resolve(supportAgent);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function unbindSupportAgent(body) {
  return (dispatch) => {
    return unlinkSupportAgent(body)
      .then((response) => {
        let supportAgent;
        try {
          supportAgent =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "REMOVE_SUPPORT_AGENT",
            data: supportAgent,
          });
          return Promise.resolve(supportAgent);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function updateSignatorySearch(body) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATE_SIGNATORY_SEARCH",
      data: body,
    });
  };
}

export function resetSignatoryState() {
  return (dispatch) => {
    return dispatch({
      type: "RESET_SIGNATORY_STATE",
      data: {
        signatory_results: [],
        signatories_state: 0,
        isSearchingSignatory: false,
        isSigSearchEmpty: false,
        signatory_r_id: "",
      },
    });
  };
}

export function updateSignatoryState(body) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATE_SIGNATORY_STATE",
      data: body,
    });
  };
}

export function updateProfileSignatoryRequest(body) {
  return (dispatch) => {
    return pushSignatoryRequest(body)
      .then((response) => {
        let sig_request;
        try {
          sig_request =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "UPDATE_PROFILE_SIGNATORY_REQUEST",
            data: sig_request,
          });
          return Promise.resolve(sig_request);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function updateProfileSignatory(body) {
  return (dispatch) => {
    return verifySignatory(body)
      .then((response) => {
        let profile;
        try {
          profile =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
        } finally {
          dispatch({
            type: "UPDATE_PROFILE_SIGNATORY",
            data: profile,
          });
          return Promise.resolve(profile);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
