import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import TransactionsListSkeleton from "../components/TransactionsListSkeleton";
import ScheduledTxnGroupList from "../components/ScheduledTxnGroupList";
import SchedulePaymentWizard from "../components/SchedulePaymentWizard";
import {
  changeScheduleStage,
  fetchProfileWallets,
  fetchScheduleTxns,
  updateWalletUtilityState,
} from "../store/actions/walletActions";

class ScheduledTxn extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isFetchingTxnInfo: true,
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileWallets(
        profileInfo.tprofile.profileType || this.props.profile.profileType
      );
      this.props
        .getScheduledTxnGroups(profileInfo.tprofile._id)
        .finally(() => this.setState({ isFetchingTxnInfo: false }));
    } else {
      window.location.href = "/signup";
    }
  }

  resetState = () => {
    this.props.updateUtilityState({
      isSuccess: false,
      isFailure: false,
    });
    this.props.updateScheduleStage(0);
  };

  render() {
    return (
      <>
        <div className="col-md-8" style={{ justifyContent: "flex-start" }}>
          {this.state.isFetchingTxnInfo ? (
            <TransactionsListSkeleton />
          ) : (
            <ScheduledTxnGroupList />
          )}
        </div>
        <div className="col-md-4">
          <div
            id="topup-btn"
            data-toggle="modal"
            onClick={() => this.resetState()}
            data-target="#SchedulePaymentWizard"
            style={{
              border: "3px dashed #0F7ABF",
              color: "#0F7ABF",
              width: "10em",
              height: "10em",
              borderRadius: "50%",
              cursor: "pointer",
              margin: "auto",
              marginTop: "13%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginTop: "37%",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              Add New
            </p>
          </div>
        </div>
        <SchedulePaymentWizard />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallets: state.walletData.wallets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    getScheduledTxnGroups: (profile) => dispatch(fetchScheduleTxns(profile)),
    updateScheduleStage: (stage) => dispatch(changeScheduleStage(stage)),
    updateUtilityState: (state) => dispatch(updateWalletUtilityState(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTxn);
