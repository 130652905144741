import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addMerchantProfile,
  getCurrentUser,
} from "../store/actions/usersActions";
import { countries } from "../constants";

class MerchantSignupWizard extends Component {
  state = {
    firstname: null,
    lastname: null,
    dob: null,
    gender: "male",
    // mobileNumber: null,
    pEmail: null,
    sEmail: null,
    nationality: "botswana",
    nationalId: null,
    passportNumber: null,
    country: null,
    countries: countries.map((country) => {
      return {
        value: country[0].toLowerCase() + country.slice(1),
        label: country,
      };
    }),
    address: null,
    city: null,
    cName: null,
    cRNumber: null,
    industry: null,
    description: null,
    oPCountry: null,
    cCity: null,
    cAddress: null,
    cRegion: null,
    cPOBox: null,
    cEmail: null,
    livingAddress: null,
    isSubmiting: false,
    isSubmitted: false,
    isIdValid: false,
  };

  componentDidMount() {
    this.props.fetchUser();
    const _this = this;

    window.$(document).ready(function () {
      if (window.$("#nationality").val() === "botswana") {
        window.$("#passportnumbercol").css("display", "none");
        window.$("#nIDCol").css("display", "block");
      } else {
        window.$("#passportnumbercol").css("display", "block");
        window.$("#nIDCol").css("display", "none");
      }

      window.$(document.body).on("change", "#nationality", function (e) {
        _this.setState({ nationality: window.$("#nationality").val() });
        if (window.$("#nationality").val() === "botswana") {
          window.$("#passportnumbercol").css("display", "none");
          window.$("#nIDCol").css("display", "block");
        } else {
          window.$("#passportnumbercol").css("display", "block");
          window.$("#nIDCol").css("display", "none");
        }
      });

      window.$(document.body).on("change", "#gender", function (e) {
        _this.setState({ gender: window.$("#gender").val() });
        if (window.$("#nationality").val() === "botswana") {
          if (window.$("#gender").val() === "male") {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 1) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          } else {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 2) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          }
        }
      });

      window.$(document.body).on("change", "#nID", function (e) {
        if (window.$("#nationality").val() === "botswana") {
          if (window.$("#gender").val() === "male") {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 1) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          } else {
            if (window.$("#nID").val().length < 9) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else if (window.$("#nID").val().charAt(4) != 2) {
              window.$("#nID").css("border-color", "red");
              _this.setState({ isIdValid: false });
            } else {
              window.$("#nID").css("border-color", "gray");
              _this.setState({ isIdValid: true });
            }
          }
        }
      });
    });
    window.$("#wizard2").steps({
      headerTag: "h3",
      bodyTag: "section",
      autoFocus: true,
      titleTemplate:
        '<span class="number">#index#</span> <span class="title">#title#</span>',
      onStepChanging: function (event, currentIndex, newIndex) {
        if (currentIndex < newIndex) {
          // Step 1 form validation
          if (currentIndex === 0) {
            var fname = window.$("#firstname").val();
            var lname = window.$("#lastname").val();
            // var number = window.$("#mobileNumber").val();
            var email = window.$("#email").val();
            var semail = window.$("#semail").val();
            var dob = window.$("#dob").val();
            var nId = window.$("#nID").val();
            var gender = window.$("#gender").val();
            var passportNumber = window.$("#passportnumber").val();
            var address = window.$("#address").val();
            var city = window.$("#city").val();
            var country = window.$("#country").val();

            if (country == "botswana") {
              if (!_this.state.isIdValid) {
                return false;
              }
            } else {
              if (passportNumber === "") {
                return false;
              }
            }

            if (
              fname === "" ||
              lname === "" ||
              // number === "" ||
              // email === "" ||
              // semail === "" ||
              dob === ""
            ) {
              return false;
            } else {
              _this.setState({
                firstname: fname,
                lastname: lname,
                // mobileNumber: number,
                pEmail: email,
                sEmail: semail,
                dob: dob,
                nationalId: nId,
                country: country,
                passportNumber: passportNumber,
                address: address,
                city: city,
                gender: gender,
              });
              return true;
            }
          }

          if (currentIndex === 1) {
            var cName = window.$("#companyname").val();
            var cRNumber = window.$("#registrationNumber").val();
            var industry = window.$("#industry").val();
            var description = window.$("#description").val();
            var cEmail = window.$("#cemail").val();

            if (
              cName === "" ||
              cRNumber === "" ||
              industry === "" ||
              description === ""
            ) {
              return false;
            } else {
              _this.setState({
                cName: cName,
                cRNumber: cRNumber,
                industry: industry,
                description: description,
                cEmail: cEmail,
              });
              return true;
            }
          }

          // Always allow step back to the previous step even if the current step is not valid.
        } else {
          return true;
        }
      },
      onFinishing: function (event, currentIndex) {
        var oPCountry = window.$("#cCountry").val();
        var cCity = window.$("#cCity").val();
        var cAddress = window.$("#cAddress").val();
        var cPOBox = window.$("#cPostalcode").val();
        var cRegion = window.$("#cRegion").val();

        if (
          oPCountry === "" ||
          cCity === "" ||
          cAddress === "" ||
          cPOBox === "" ||
          cRegion === ""
        ) {
          // return false
          return true;
        } else {
          _this.setState({
            oPCountry: oPCountry,
            cCity: cCity,
            cAddress: cAddress,
            cPOBox: cPOBox,
            cRegion: cRegion,
          });
          // fire submission event
          _this.handleReg();

          // fire loading animation
          window.$("#wizard2").css("display", "none");
          window.$("#submissionAnim").css("display", "block");
          return true;
        }
      },
    });

    window.$("#wizard3").steps({
      headerTag: "h4",
      bodyTag: "section",
      autoFocus: true,
      titleTemplate:
        '<span class="number">#index#</span> <span class="title">#title#</span>',
      onStepChanging: function (event, currentIndex, newIndex) {
        console.log(newIndex, event);
        return true;
      },
    });
  }

  handleReg() {
    let body = {
      cAddress: this.state.cAddress,
      cNumber: this.state.cNumber,
      cCountry: this.state.oPCountry,
      accountId: this.props.acc._id,
      businessName: this.state.cName,
      regNumber: this.state.cRNumber,
      cEmail: this.state.cEmail,
      cDescription: this.state.description,

      firstName: this.state.firstname,
      lastName: this.state.lastname,
      dob: this.state.dob,
      // number: this.state.mobileNumber,
      nationality: this.state.nationality,
      nationalId: this.state.nationalId,
      passportNumber: this.state.passportNumber,
      country: this.state.country,
      city: this.state.city,
      address: this.state.address,
      postalAddress: this.state.cPOBox,
      region: this.state.cRegion,
      industry: this.state.industry,
    };

    this.props
      .addProfile(body)
      .then(() => {
        //  console.log("reg  complete")
        window.location.href = "/merchant/transactions";
      })
      .catch((err) => {
        this.setState({ errorMessage: err.data });
        window.$("#submissionAnim").css("display", "none");
        window.$("#submissionFail").css("display", "block");
      });
  }

  resetState = () => {
    this.setState({
      errorMessage: "",
    });
    window.$("#submissionAnim").css("display", "none");
    window.$("#submissionFail").css("display", "none");
    window.$("#wizard2").css("display", "block");
  };

  render() {
    return (
      <div className="signin-box signup">
        <div
          id="submissionAnim"
          style={{
            width: "100%",
            height: "100vh",
            paddingTop: "40vh",
            textAlign: "center",
            display: "none",
          }}
        >
          <p>Please wait as we setup your profile....</p>
        </div>
        <div
          id="submissionFail"
          style={{
            width: "100%",
            height: "100vh",
            paddingTop: "40vh",
            textAlign: "center",
            display: "none",
          }}
        >
          <p>{this.state.errorMessage}</p>
          <button
            onClick={() => this.resetState()}
            className="btn btn-primary btn-block btn-signin"
            type="submit"
          >
            Try Again
          </button>
        </div>
        <div id="wizard2">
          <h3>Personal Information</h3>
          <section style={{ backgroundColor: "white" }}>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Firstname: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="firstname"
                    className="form-control"
                    name="firstname"
                    placeholder="Firstname"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Lastname: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="lastname"
                    className="form-control"
                    name="lastname"
                    placeholder="Lastname"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Date Of Birth: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="dob"
                    className="form-control"
                    name="dob"
                    date-format="MMMM DD YYYY"
                    type="date"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Gender: <span className="tx-danger">*</span>
                  </label>
                  <select
                    id="gender"
                    className="form-control select2"
                    data-placeholder="gender"
                    name="gender"
                    required
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Primary Email: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Primary Email"
                    type="email"
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Mobile Number: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="mobileNumber"
                    className="form-control"
                    name="mobileNumber"
                    type="number"
                    required
                  />
                </div>
              </div> */}
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Nationality: <span className="tx-danger">*</span>
                  </label>
                  <select
                    id="nationality"
                    className="form-control select2"
                    data-placeholder="Nationality"
                    name="nationality"
                    required
                  >
                    <option key="botswana" value="botswana">
                      Botswana
                    </option>
                    {this.state.countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6" id="nIDCol">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    National ID: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="nID"
                    className="form-control"
                    name="nID"
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6" id="passportnumbercol">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Passport Number: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="passportnumber"
                    className="form-control"
                    name="passportnumber"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Country: <span className="tx-danger">*</span>
                  </label>
                  <select
                    id="country"
                    className="form-control select2"
                    data-placeholder="Country"
                    name="country"
                    required
                  >
                    <option key="botswana" value="botswana">
                      Botswana
                    </option>
                    {this.state.countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Address: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="address"
                    className="form-control"
                    name="address"
                    placeholder="Address"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    City: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="city"
                    className="form-control"
                    name="city"
                    placeholder="city"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </section>
          <h3>Company Information</h3>
          <section style={{ backgroundColor: "white" }}>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Company Name: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="companyname"
                    className="form-control"
                    name="companyname"
                    placeholder="Company Name"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Company Registration Number:{" "}
                    <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="registrationNumber"
                    className="form-control"
                    name="registrationNumber"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-xs mg-b-10">
              <div className="col-md-6 mg-b-10">
                <label className="form-control-label tx-semibold">
                  Industry / Company type: <span className="tx-danger">*</span>
                </label>
                <input
                  id="industry"
                  className="form-control"
                  name="industry"
                  type="text"
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Company Email: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="cemail"
                    className="form-control"
                    name="cemail"
                    placeholder="Secondary Email"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label tx-semibold">
                    Description: <span className="tx-danger">*</span>
                  </label>
                  <textarea
                    id="description"
                    rows="3"
                    className="form-control"
                    placeholder="Company  Description"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </section>
          <h3>Location Information</h3>
          <section style={{ backgroundColor: "white" }}>
            <div
              className="row row-xs mg-b-11"
              style={{ width: "50%", margin: "auto" }}
            >
              <div className="col-lg-12 mg-b-11">
                <label className="form-control-label tx-semibold">
                  Country<span className="tx-danger">*</span>
                </label>
                <div className="wd-0">
                  <select
                    id="cCountry"
                    className="form-control select2"
                    data-placeholder="Nationality"
                    name="nationality"
                    required
                  >
                    <option key="botswana" value="botswana">
                      Botswana
                    </option>
                    {this.state.countries.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-12">
                <label className="form-control-label tx-semibold">
                  City/Town/Village<span className="tx-danger">*</span>
                </label>
                <input
                  id="cCity"
                  className="form-control"
                  name="cCity"
                  placeholder="City/Town/Village"
                  type="text"
                  required
                />
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Address: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="cAddress"
                    className="form-control"
                    name="address"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Postal Address: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="cPostalcode"
                    className="form-control"
                    name="postalcode"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="form-control-label tx-semibold">
                    Region: <span className="tx-danger">*</span>
                  </label>
                  <input
                    id="cRegion"
                    className="form-control"
                    name="Region"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(getCurrentUser()),
    addProfile: (body) => dispatch(addMerchantProfile(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantSignupWizard);
