import React, { Component } from "react";
import { connect } from "react-redux";
import {
  completePayment,
  createTransfer,
  fetchProfileWallets,
  updateInheritTxnStage2,
} from "../store/actions/walletActions";
import {
  clearDestProfiles,
  searchPProfiles,
} from "../store/actions/usersActions";
import { updateDestProfile } from "../store/actions/walletActions";
import { QRCodeSVG } from "qrcode.react";
import Pdf from "react-to-pdf";

const ref = React.createRef();
class ReceivePaymentWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    destProfiles: [],
    tProfile: {},
    txnStage: 0,
    txnAmount: 0.0,
    txnId: null,
    isLoading: false,
    otp: null,
    isSearching: false,
    isEmpty: false,
    originWallet: "COMBI",
  };

  componentDidMount() {}

  getProfiles(number) {
    if (number && number.length >= 6) {
      this.props.fetchPProfiles(number).finally(() => {
        this.props.destProfiles.length < 1
          ? this.setState({ isSearching: false, isEmpty: true })
          : this.setState({ isSearching: false, isEmpty: false });
      });
      this.setState({ isSearching: true });
    } else {
      this.setState({ isSearching: false });
      this.props.resetDestProfiles();
    }
  }

  selectTProfile(profile) {
    this.updateState(1);
    this.props.updateTProfile(profile);
  }
  initTxn = () => {
    let wId = this.getCoreId()[0].walletId;
    this.setState({ isLoading: true });
    this.props
      .startTxn(
        wId,
        this.props.tProfile.number,
        this.state.txnAmount,
        this.state.reference
      )
      .then(() => {
        this.setState({ txnId: this.props.txnId });
        this.updateState(2);
      })
      .catch((err) => {
        this.updateState(-1);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  closeModal() {
    this.setState({
      txnStage: 0,
      isLoading: false,
      tProfile: {},
      txnAmount: "",
      paymentType: "",
      txnId: null,
      accNumber: null,
      otp: null,
      destProfiles: [],
      isSearching: false,
    });
  }

  getCoreId() {
    return this.props.wallets.filter((w) => w.walletId.walletType === "CORE");
  }

  confirmTxn(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .verify(this.state.txnId, this.state.otp)
      .then(() => {
        this.props.resetDestProfiles();
        this.updateState(10);
      })
      .catch((err) => {
        this.props.resetDestProfiles();
        this.updateState(-1);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  updateState = (stage) => {
    this.props.updateTxnStage2(stage);
  };

  generateQrCode = (e) => {
    e.preventDefault();
    this.setState({
      qrcodeValue: `{ ${
        this.state.txnAmount === "" ? `` : `"amount":"${this.state.txnAmount}"`
      }, "accountType":"${
        this.props.wallet.walletId.accountType
      }", "cellphone":"${
        this.props.wallet.walletId.cellphone
      }",  "walletType": "${
        this.props.profile.profileType === "personal" ? "CORE" : "TRADER"
      }", "origin": "${
        this.state.originWallet ? this.state.originWallet : "CORE"
      }"}`,
    });
    this.updateState(2);
  };

  generateQrCode2 = (e) => {
    e.preventDefault();
    this.setState({
      qrcodeValue: `{"accountType":"${
        this.props.wallet.walletId.accountType
      }", "cellphone":"${
        this.props.wallet.walletId.cellphone
      }",  "walletType": "${
        this.props.profile.profileType === "personal" ? "CORE" : "TRADER"
      }", "origin": "${
        this.state.originWallet ? this.state.originWallet : "CORE"
      }"}`,
    });
    this.updateState(2);
  };
  z;
  render() {
    return (
      <div id="receivepaymentmodal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-sm-0 pd-lg-50">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div
                    className="modal__featured d-none d-xl-block"
                    style={{ minWidth: "600px" }}
                  >
                    <div className="modal__circle"></div>
                    <div
                      className="modal__product hidden-xs"
                      style={{ left: "39%", maxWidth: "100%" }}
                    >
                      <img
                        style={{
                          marginTop: "5em",
                          marginBottom: "2em",
                          maxWidth: "65%",
                        }}
                        alt="Dumela Pay logo"
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p style={{ fontSize: "17px", color: "white" }}>
                        Please provide the amount you expect to receive
                      </p>
                    </div>
                  </div>
                  <div className="modal__content">
                    {this.state.isLoading ? (
                      <div className="pd-x-100 pd-y-100">
                        <h4 className="tx-gray-800 tx-normal mg-b-5">
                          Proccessing ....
                        </h4>
                        {/* <p>Proccessing ....</p> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 0 && !this.state.isLoading ? (
                      <div className="pd-x-30 pd-y-10">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Payment details
                        </h3>
                        <br />
                        <br />

                        <p>Would you like to fix the payment amount ?</p>
                        <button
                          onClick={() => this.updateState(1)}
                          className="btn btn-primary pd-y-12 btn-block btn-oblong"
                        >
                          Yes
                        </button>
                        <br />
                        <hr />
                        <br />
                        <button
                          onClick={() => this.updateState(3)}
                          className="btn btn-outline-primary pd-y-12 btn-block btn-oblong"
                        >
                          No
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 3 && !this.state.isLoading ? (
                      <div className="form-group pd-y-60 pd-xl-x-30">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Payment details
                        </h3>
                        <br />
                        <br />
                        <form onSubmit={(e) => this.generateQrCode2(e)}>
                          <p>Select QR CODE type</p>
                          <select
                            id="originwallet"
                            className="form-control select2 payment_wizard_input mb-3"
                            name="wallet"
                            onChange={(e) =>
                              this.setState({
                                originWallet: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="COMBI">TRANSPORT</option>
                            <option value="NORMAL">NORMAL</option>
                          </select>
                          <button
                            type="submit"
                            className="btn btn-primary pd-y-12 btn-block btn-oblong"
                          >
                            Generate QRCode
                          </button>
                        </form>

                        <div className="row  mt-4 d-lg-none"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.txnStage === 1 && !this.state.isLoading ? (
                      <div className="form-group pd-y-60 pd-xl-x-30">
                        <h3 className="tx-gray-800 tx-normal mg-b-5">
                          Payment details
                        </h3>
                        <br />
                        <br />

                        <p>Enter the payment amount.</p>
                        <form onSubmit={(e) => this.generateQrCode(e)}>
                          <input
                            type="text"
                            name="amount"
                            step=".01"
                            required
                            className="form-control pd-y-12 mg-b-10"
                            style={{ borderRadius: "50px" }}
                            value={this.state.txnAmount}
                            onChange={(e) =>
                              this.setState({ txnAmount: e.target.value })
                            }
                            placeholder="0.00"
                          />
                          <p>Select QRCODE type</p>
                          <select
                            id="originwallet"
                            className="form-control select2 payment_wizard_input mb-3"
                            name="wallet"
                            onChange={(e) =>
                              this.setState({
                                originWallet: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="COMBI">TRANSPORT</option>
                            <option value="NORMAL">NORMAL</option>
                          </select>
                          <button
                            type="submit"
                            className="btn btn-primary pd-y-12 btn-block btn-oblong"
                          >
                            Generate QRCode
                          </button>
                        </form>

                        <div className="row  mt-4 d-lg-none"></div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.props.txnStage === 2 && !this.state.isLoading ? (
                      <>
                        <div
                          className="form-group pd-y-60 pd-xl-x-30 text-center"
                          ref={ref}
                        >
                          <div></div>
                          <img
                            style={{
                              marginBottom: "1em",
                              maxWidth: "65%",
                            }}
                            alt="Dumela Pay logo"
                            src="/assets/img/dumelapay-clear.png"
                          />
                          <p style={{ marginBottom: "0em" }}>
                            Pay To{" "}
                            {this.props.profile.profileType === "merchant"
                              ? this.props.profile.businessName
                              : this.props.profile.name}
                          </p>

                          <br />
                          <br />
                          <QRCodeSVG
                            size="254"
                            value={this.state.qrcodeValue}
                          />
                        </div>
                        <Pdf
                          targetRef={ref}
                          options={{
                            orientation: "portrate",
                            unit: "cm",
                            format: [30, 25],
                          }}
                          scale={2}
                          x={2.5}
                          y={2}
                          filename="dumelapayqrcode.pdf"
                        >
                          {({ toPdf }) => (
                            <button
                              onClick={toPdf}
                              className="btn btn-outline-primary pd-y-12 btn-block btn-oblong"
                            >
                              Download QRCode
                            </button>
                          )}
                        </Pdf>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.walletData.wallet,
    wallets: state.walletData.wallets,
    destProfiles: state.account.destProfiles,
    profile: state.account.targetProfile,
    txnId: state.walletData.txnId,
    txnStage: state.walletData.txnStage2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (pType) => dispatch(fetchProfileWallets(pType)),
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    startTxn: (walletId, dNumber, amount, reference) =>
      dispatch(createTransfer(walletId, dNumber, amount, reference)),
    verify: (txnId, txnCode) => dispatch(completePayment(txnId, txnCode)),
    updateTxnStage2: (stage) => dispatch(updateInheritTxnStage2(stage)),
    updateTProfile: (profile) => dispatch(updateDestProfile(profile)),
    resetDestProfiles: () => dispatch(clearDestProfiles()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceivePaymentWizard);
