import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchPProfiles,
  updateProfileSignatory,
  updateProfileSignatoryRequest,
  updateSignatorySearch,
  updateSignatoryState,
} from "../store/actions/usersActions";
import SuccessAnim from "./SuccessAnim";
import FailedAnim from "./FailedAnim";
import { BASE_URL } from "../api/client";
import { fetchProPic } from "../api/account";

class SignatoryWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    destProfiles: [],
    tProfile: {},
    txnAmount: 0.0,
    txnType: null,
    txnId: null,
    isLoading: false,
    otp: null,
    hasTransactionId: false,
    reference: "",
    originWallet: "TRADER",

    sig_number: "",
    sigProfiles: [],
  };

  componentDidMount() {}

  confirmTxn(e) {
    e.preventDefault();
    this.props.updateState({
      signatories_state: 4,
    });
    this.props
      .verify({
        sig_request: this.props.sig_id,
        otp: this.state.otp,
        profile: this.props.profile._id,
      })
      .then(() => {
        this.props.updateState({
          signatories_state: 3,
        });
      })
      .catch((err) => {
        this.props.updateState({
          signatories_state: 2,
        });
      });
  }

  selectTAcc(acc) {
    this.props.updateState({
      signatories_state: 4,
    });
    this.props
      .makeSignatoryRequest({
        acc: acc._id,
        profile: this.props.profile._id,
      })
      .then(() => {
        this.props.updateState({
          signatories_state: 1,
        });
      })
      .catch((err) => {
        this.props.updateState({
          signatories_state: 2,
        });
      });
  }

  getProfiles(e) {
    e.preventDefault();
    if (this.state.sig_number.length > 5) {
      this.props.updateSearch(true);
      this.props.fetchPProfiles(this.state.sig_number).then(() => {
        this.props.updateState({
          signatory_results: this.props.destProfiles,
          isSigSearchEmpty: this.props.destProfiles.length < 1,
        });
        this.props.updateSearch(false);
      });
    }
  }

  render() {
    return (
      <div id="signatoryModal" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-0" style={{ padding: "5em" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div className="modal__featured d-none d-xl-block">
                    <div className="modal__circle"></div>
                    <div className="modal__product hidden-xs">
                      <img
                        style={{
                          marginTop: "12em",
                          marginBottom: "2em",
                          maxWidth: "124%",
                        }}
                        alt=""
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p style={{ fontSize: "17px", color: "white" }}>
                        Securing your wallet 2
                      </p>
                    </div>
                  </div>
                  <div className="modal__content">
                    <h2>Add account signatory</h2>
                    {this.props.signatoryState === 4 ? (
                      <div className="text-center">
                        <p>Proccessing</p>
                      </div>
                    ) : this.props.signatoryState === 3 ? (
                      <div className="text-center">
                        <SuccessAnim />
                        <p>Successful</p>
                      </div>
                    ) : this.props.signatoryState === 2 ? (
                      <div className="text-center">
                        <FailedAnim />
                        <p>Transaction Failed</p>
                      </div>
                    ) : this.props.signatoryState === 1 ? (
                      <form
                        onSubmit={(e) => this.confirmTxn(e)}
                        style={{ marginTop: "7em" }}
                      >
                        <ul className="form-list">
                          <li className="form-list__row">
                            <label>OTP</label>
                            <div id="input--otp">
                              <input
                                className="payment_wizard_input"
                                type="number"
                                required
                                value={this.state.otp}
                                onChange={(e) =>
                                  this.setState({
                                    otp: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </li>
                          <li>
                            <div className="summary-item">
                              <button
                                type="submit"
                                className="btn btn-oblong btn-primary btn-block mg-b-10"
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                }}
                              >
                                submit
                              </button>
                            </div>
                          </li>
                        </ul>
                      </form>
                    ) : (
                      <form
                        onSubmit={(e) => this.getProfiles(e)}
                        style={{ marginTop: "7em" }}
                      >
                        <ul className="form-list">
                          <li className="form-list__row">
                            <label>Number:</label>
                            <div id="input--signatory">
                              <input
                                className="payment_wizard_input"
                                type="number"
                                required
                                value={this.state.sig_number}
                                onChange={(e) =>
                                  this.setState({
                                    sig_number: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </li>
                          {this.props.isSearchingSignatory ? (
                            <li className="form-list__row">
                              <p>Searching....</p>
                            </li>
                          ) : this.props.signatory_results.length > 0 ? (
                            <li className="form-list__row">
                              {this.props.signatory_results.map((profile) => (
                                <div
                                  key={profile.account._id}
                                  className="col-lg-6 p-1"
                                  onClick={() =>
                                    this.selectTAcc(profile.account)
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      padding: "0.2em 1em",
                                      paddingLeft: "0.2em",
                                      backgroundColor: "white",
                                      borderRadius: "50px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "2.6rem",
                                        height: "2.6rem",
                                        margin: "auto 0",
                                      }}
                                    >
                                      {profile.propic ? (
                                        <img
                                          src={
                                            BASE_URL +
                                            fetchProPic() +
                                            `?picId=${profile.propic}&size=480x480`
                                          }
                                          className="img-fluid rounded-circle"
                                          alt=""
                                          style={{
                                            objectFit: "cover",
                                            width: "2.6rem",
                                            height: "2.6rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={"/assets/img/img0.jpg"}
                                          className="img-fluid rounded-circle"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div
                                      className="mg-l-15"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        className="tx-bold tx-black"
                                        style={{
                                          marginTop: "0",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {profile.profileType === "merchant"
                                          ? profile.businessName
                                          : profile.account.firstName +
                                            " " +
                                            profile.account.lastName}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </li>
                          ) : this.props.isSigSearchEmpty ? (
                            <li className="form-list__row">
                              <p>No results found....</p>
                            </li>
                          ) : (
                            ""
                          )}

                          <li>
                            <div className="summary-item">
                              <button
                                type="submit"
                                className="btn btn-oblong btn-primary btn-block mg-b-10"
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </li>
                        </ul>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destProfiles: state.account.destProfiles,
    profile: state.account.targetProfile,
    signatoryState: state.account.signatories_state,
    isSearchingSignatory: state.account.isSearchingSignatory,
    signatory_results: state.account.signatory_results,
    isSigSearchEmpty: state.account.isSigSearchEmpty,
    sig_id: state.account.signatory_request._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    updateSearch: (state) => dispatch(updateSignatorySearch(state)),
    updateState: (body) => dispatch(updateSignatoryState(body)),
    makeSignatoryRequest: (body) =>
      dispatch(updateProfileSignatoryRequest(body)),
    verify: (body) => dispatch(updateProfileSignatory(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatoryWizard);
