import React, { Component } from "react";
import MerchantSignupWizard from "../components/MerchantSignupWizard";
import SignupWizard from "../components/SignupWizard";
import Cookies from "js-cookie";

class Signup extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    stage: 1,
    accType: "",
    wizard: null,
    uId: "",
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      if (profileInfo.tprofile) {
        profileInfo.tprofile.profileType === "personal"
          ? this.selectAcountType("merchant", 3)
          : this.selectAcountType("personal", 2);
      }
    }
  }
  selectAcountType(acc, stage) {
    this.setState({ stage: stage, accType: acc });
  }

  render() {
    return (
      <div className="d-md-flex flex-row-reverse">
        <div className="signin-right">
          {this.state.stage === 1 ? (
            <div className="signin-box signup" style={{ width: "60%" }}>
              <h3 className="signin-title-secondary ">Select account type</h3>

              <div className="row row-xs mg-b-10">
                <div className="col-sm" style={{ minHeight: "50vh" }}>
                  <div
                    onClick={() => this.selectAcountType("personal", 2)}
                    className="card"
                    style={{
                      cursor: "pointer",
                      padding: ".6em",
                      borderWidth: "2px",
                      borderColor: "#94989d",
                      borderRadius: "30px",
                      width: "13em",
                      height: "13em",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "40%",
                    }}
                  >
                    <h4 style={{ marginTop: "37%" }}>Personal account</h4>
                  </div>
                </div>
                <div
                  className="col-sm"
                  style={{ borderLeft: "solid 1px #d3d2d2", minHeight: "50vh" }}
                >
                  <div
                    onClick={() => this.selectAcountType("merchant", 3)}
                    className="card"
                    style={{
                      cursor: "pointer",
                      padding: ".5em",
                      borderWidth: "2px",
                      borderColor: "#94989d",
                      borderRadius: "30px",
                      width: "13em",
                      height: "13em",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "40%",
                    }}
                  >
                    <h4 style={{ marginTop: "37%" }}>Merchant Account</h4>
                  </div>
                </div>
              </div>
            </div>
          ) : this.state.stage === 2 ? (
            <SignupWizard />
          ) : (
            // <TestWizard/>
            <MerchantSignupWizard />
          )}
        </div>
        {/* <!-- signin-right --> */}
        <div
          className="signin-left"
          style={{ background: "#0b4381", color: "white" }}
        >
          <div className="signin-box">
            <img
              className="slim-logo"
              width="200px"
              src="/assets/img/dumelapay-clear.png"
            />

            <p>
              No more queues to send money or make payments - simplicity at your
              fingertips!!!
            </p>

            <p>Browse our site and see for yourself why you need DumelaPay.</p>

            <p className="tx-12">&copy; Copyright 2022. All Rights Reserved.</p>
          </div>
        </div>
        {/* <!-- signin-left --> */}
      </div>
    );
  }
}

export default Signup;
