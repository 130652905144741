// get wallets
// create wallet
import { client } from "./client";

export function getPWallets(pType) {
  return client.get(`/api/zeus/v1/wallets?profile=${pType}`);
}

export function getPWallet(walletId) {
  return client.post(`/api/zeus/v1/profile/wallet`, walletId);
}
