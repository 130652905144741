const initState = {
  wallet: { walletId: "" },
  wallets: [],
  transactions: [],
  scheduledTxn: [],
  targetScheduledTxn: { signitories: [] },
  // txnId: null,
  txnStage: 0,
  txnStage2: 0,
  isWalletTopUp: false,
  isCardTopUp: false,
  isSuccess: false,
  isFailure: false,
  otpStage: false,
  isValidNumber: false,
  scheduleStage: 0,
  destProfile: { account: {} },
};

const walletReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_WALLETS":
      return {
        ...state,
        wallets: action.data,
        wallet: action.data[0] ? action.data[0] : {},
      };
    case "GET_WALLET":
      var index = 0;
      let wallets = state.wallets.map((wallet, idx) => {
        if (wallet.walletId.walletType === action.data.walletId.walletType) {
          index = idx;
        }
        return wallet;
      });

      wallets[index] = action.data;

      return {
        ...state,
        wallet: action.data,
        wallets: wallets,
      };
    case "SWITCH_WALLET_CONTEXT":
      return {
        ...state,
        wallet: action.data,
        transactions: [],
      };
    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.data,
      };
    case "GET_TRANSACTION_RECORD":
      return {
        ...state,
        ...action.data,
      };
    case "UPDATE_TXN_STAGE":
      return {
        ...state,
        txnStage: action.data,
      };
    case "UPDATE_W_UTILITY_STATE":
      return {
        ...state,
        ...action.data,
      };
    case "RESET_W_UTILITY_STATE":
      return {
        ...state,
        txnStage: 0,
        txnStage2: 0,
        isWalletTopUp: false,
        isCardTopUp: false,
        isSuccess: false,
        isFailure: false,
        otpStage: false,
        isValidNumber: false,
      };
    case "UPDATE_SCHEDULE_STAGE":
      return {
        ...state,
        scheduleStage: action.data,
      };
    case "GET_SCHEDULED_TXNS":
      return {
        ...state,
        scheduledTxn: action.data,
      };
    case "GET_SCHEDULED_TXN":
      let txns = state.scheduledTxn;
      txns.push(action.data);
      return {
        ...state,
        scheduledTxn: txns,
        targetScheduledTxn: action.data,
      };
    case "TRANSACTION_SCHEDULE_BATCH":
      let txn_s = state.scheduledTxn;
      txn_s.push(action.data);
      return {
        ...state,
        scheduledTxn: txn_s,
        targetScheduledTxn: action.data,
      };
    case "UPDATE_TXN_STAGE2":
      return {
        ...state,
        txnStage2: action.data,
      };
    case "UPDATE_DEST_PROFILE":
      return {
        ...state,
        destProfile: action.data,
      };
    default:
      break;
  }
  return state;
};

export default walletReducer;
