import React from "react";
import { Route, Redirect } from "react-router";
import Cookies from "js-cookie";
//has profiles
//is current or first Profile personal

export default function PrivateRoute({ component: Component, role, ...rest }) {
  try {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) profileInfo = JSON.parse(profileInfo);
    else {
      profileInfo = {
        tprofile: {},
      };
    }

    if (role === profileInfo.tprofile.profileType) {
      return (
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      );
    } else {
      return <Redirect to={{ pathname: "/" }} />;
      // console.log(role, profileInfo.tprofile.profileType);
    }
  } catch (err) {
    return <Redirect to={{ pathname: "/" }} />;
  }
}
