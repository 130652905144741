import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProfileWallets } from "../store/actions/walletActions";
import Cookies from "js-cookie";
import moment from "moment";

class Profile extends Component {
  state = {
    profile: {},
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileWallets(
        profileInfo.tprofile.profileType || this.props.profile.profileType
      );
    }
  }

  render() {
    return this.props.profile.profileType === "personal" ? (
      <>
        <div className="col-md-6 mg-lg-l-50">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">
              Customer Contact information
            </label>
            <p className="mg-b-20 mg-sm-b-20">
              Full name :{" "}
              {this.props.acc.firstName
                ? this.props.acc.firstName + " " + this.props.acc.lastName
                : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Date Of Birth :{" "}
              {this.props.acc.dob
                ? moment(this.props.acc.dob).format("DD-MM-YYYY")
                : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Mobile Number :{" "}
              {this.props.acc.number ? this.props.acc.number : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Primary email : {this.props.acc.email ? this.props.acc.email : ""}
            </p>
          </div>
          <div className="section-wrapper mg-b-20">
            <label className="section-title">Personal Address</label>
            <p className="mg-b-20 mg-sm-b-20">
              Country :{" "}
              {this.props.profile.country ? this.props.profile.country : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              City/Town/Village :{" "}
              {this.props.profile.city ? this.props.profile.city : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Physical Address :{" "}
              {this.props.profile.address ? this.props.profile.address : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Postal Address :{" "}
              {this.props.profile.postalAddress
                ? this.props.profile.postalAddress
                : ""}
            </p>
          </div>
        </div>
        <div className="col-md-5 col-md-3  mg-t-40 mg-md-t-0">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">Account Info</label>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Nationality :{" "}
              {this.props.acc.country ? this.props.acc.country : ""}
            </p>

            {this.props.profile.country === "botswana" ? (
              <p className="mg-b-20 mg-sm-b-20">
                National Id :{" "}
                {this.props.acc.nationalId ? this.props.acc.nationalId : ""}{" "}
              </p>
            ) : (
              <p className="mg-b-20 mg-sm-b-20">
                Passport Number :{" "}
                {this.props.acc.passportNumber
                  ? this.props.acc.passportNumber
                  : ""}{" "}
              </p>
            )}
          </div>
        </div>
        {/* <div className="col-md-6 mg-lg-l-50">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">WhatsApp Information</label>
            <p className="mg-b-20 mg-sm-b-20">
              WhatsApp pin :{" "}
              {this.props.acc.whatsAppPin ? this.props.acc.whatsAppPin : ""}
            </p>
          </div>
        </div> */}
      </>
    ) : (
      <>
        <div className="col-md-6 ">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">
              Customer Contact Information
            </label>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Full name :{" "}
              {this.props.acc.firstName
                ? this.props.acc.firstName + " " + this.props.acc.lastName
                : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Date Of Birth :{" "}
              {this.props.acc.dob
                ? moment(this.props.acc.dob).format("DD-MM-YYYY")
                : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Mobile Number :{" "}
              {this.props.acc.number ? this.props.acc.number : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Primary email :{" "}
              {this.props.profile.email ? this.props.profile.email : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Country :{" "}
              {this.props.profile.country ? this.props.profile.country : ""}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">Business Information</label>
            <p className="mg-b-20 mg-sm-b-20">
              Name :{" "}
              {this.props.profile.businessName
                ? this.props.profile.businessName
                : ""}
            </p>
            <p className="mg-b-20 mg-sm-b-20">
              Registration Number :{" "}
              {this.props.profile.regNumber ? this.props.profile.regNumber : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Country :{" "}
              {this.props.profile.country ? this.props.profile.country : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              City/Town/Village :{" "}
              {this.props.profile.city ? this.props.profile.city : ""}
            </p>
            <p
              className="mg-b-20 mg-sm-b-20"
              style={{ textTransform: "capitalize" }}
            >
              Physical Address :{" "}
              {this.props.profile.address ? this.props.profile.address : ""}
            </p>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="section-wrapper mg-b-20">
            <label className="section-title">WhatsApp Information</label>
            <p className="mg-b-20 mg-sm-b-20">
              WhatsApp pin :{" "}
              {this.props.acc.whatsAppPin ? this.props.acc.whatsAppPin : ""}
            </p>
          </div>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profile: state.account.targetProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    // fetchUser: (body) => dispatch(getCurrentUser(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
