import React, { Component } from "react";

class FailedAnim extends Component {
  render() {
    return (
      <>
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_ed9D2z.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px", margin: "0 auto" }}
          autoplay
        ></lottie-player>
        <h4>Failed</h4>
      </>
    );
  }
}
export default FailedAnim;
