import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchWalletTransactions } from "../store/actions/walletActions";
import { unbindSupportAgent } from "../store/actions/usersActions";
import moment from "moment";
import EmptyAnim from "./EmptyAnim";

class SupportAgentList extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isLoading: true,
  };

  componentDidMount() {}

  removeAgent = (agent) => {
    let body = {
      userId: agent._id,
    };

    this.setState({ isLoading: true });
    this.props.removeSupportAgent(body).then(() => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    return (
      <>
        {this.props.supportAgents.length > 0 ? (
          <>
            <label className="section-title mt-0">Support Agents</label>

            <div className="transaction-block">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
              <ul className="transaction-list">
                {this.props.supportAgents.map((sAgent) => (
                  <li key={sAgent._id}>
                    <div className="wd-50 t-destination">
                      {sAgent.number != this.props.parent ? (
                        <div className="wd-150 t-reference">
                          <i
                            class="fa-solid fa-trash"
                            onClick={() => this.removeAgent(sAgent)}
                          ></i>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="wd-150 t-destination">
                      {sAgent.firstName + " " + sAgent.lastName}
                    </div>
                    <div className="wd-150 t-reference">{sAgent.number}</div>
                    <div className="wd-150 t-reference">{sAgent.email}</div>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          </>
        ) : (
          <div>
            <label className="section-title mt-0">Support Agents</label>

            <div
              className="transaction-block"
              style={{ textAlign: "center", padding: "3em" }}
            >
              <EmptyAnim />
              No Support Agents found
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supportAgents: state.account.supportAgents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSupportAgent: (data) => dispatch(unbindSupportAgent(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportAgentList);
