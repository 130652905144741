const initState = {
  uploadProgress: { percent: 0, total: 0, uploaded: 0 },
  files: [],
};

const kycReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPLOAD_PROGRESS_EVENT":
      return {
        ...state,
        uploadProgress: { ...action.data },
      };
    case "GET_KYC":
      return {
        ...state,
        files: action.data,
      };
    default:
      break;
  }
  return state;
};

export default kycReducer;
