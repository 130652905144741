import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = "https://dumelapay.co.bw/";

export const client = axios.create({
  baseURL: BASE_URL,
  useCredentials: true,
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((req) => {
  var access_token = Cookies.get("dumelapay");
  req.headers = { ...req.headers, Authorization: `Bearer ${access_token}` };
  // Important: request interceptors **must** return the request.
  return req;
});
