import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Kyc from "./Kyc";
import Profile from "./Profile";
import Settings from "./Settings";
import Transactions from "./Transactions";
import { connect } from "react-redux";
import {
  clearDestProfiles,
  getCurrentUser,
  getProfileInfo,
} from "../store/actions/usersActions";
import moment from "moment";
import Cookies from "js-cookie";
import PaymentWizard from "../components/PaymentWizard";
import TransferWizard from "../components/TransferWizard";
import TopupWizard from "../components/TopupWizard";
import LoanApplication from "./LoanApplication";
import { getPropic } from "../store/actions/usersActions";
import { BASE_URL } from "../api/client";
import { fetchProPic } from "../api/account";
import {
  resetWalletUtilityState,
  updateDestProfile,
} from "../store/actions/walletActions";
import ReceivePaymentWizard from "../components/ReceivePaymentWizard";

class PersonalAccWrapper extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    acc: {},
    targetProfile: {},
    inheritTxnStage: 0,
  };

  componentDidMount() {
    this.props
      .fetchUser()
      .then(() => this.setState({ acc: this.props.acc }))
      .catch((e) => console.log(e));

    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.fetchProfile(profileInfo.tprofile._id).then(() => {
        this.setState({ targetProfile: this.props.targetProfile });
      });
    } else {
      profileInfo = {
        tprofile: {},
      };
    }
  }

  getProfilepic = () => {
    return;
  };

  updatePaymentState = () => {
    // this.props.resetWState();
    this.props.resetUtilitiesState();
    this.props.resetDestProfile();
    this.props.resetDestProfiles();
  };

  updateTransferState = () => {
    this.props.resetWState();
    this.props.resetDestProfile();
    this.props.resetDestProfiles();
  };

  switchProfile = () => {
    let acc = this.state.acc;
    let profiles = acc.profiles.filter((profile) => {
      return (
        profile.profileType === "merchant" &&
        profile._id !== this.state.targetProfile._id
      );
    });

    let dpp = Cookies.get("dpp");
    dpp = JSON.parse(dpp);
    //
    let cookieObj = {
      tprofile: profiles[0],
      profiles: dpp.profiles,
    };

    delete cookieObj.tprofile.accessScopes;
    delete cookieObj.tprofile.wallets;
    delete cookieObj.tprofile.supportAgents;

    Cookies.set("dpp", JSON.stringify(cookieObj));

    window.location.href = "/merchant/transactions";
  };

  signOut = () => {
    Cookies.remove("dpp");
  };

  render() {
    return (
      <>
        <link rel="stylesheet" href="/assets/css/slim.header-one.css" />

        <div className="dashboard-5 slim-header-one">
          <div className="slim-header">
            <div className="container">
              <div className="slim-header-left">
                <img
                  className="slim-logo mr-0"
                  width="200px"
                  src="/assets/img/dumelapay-clear.png"
                  alt="dumelapay  logo"
                />
                <p style={{ marginBottom: "-10px", fontWeight: "bold  " }}>
                  Personal
                </p>
              </div>
              <div className="slim-header-right">
                <div className="dropdown dropdown-c">
                  <a
                    href="/"
                    className="logged-user"
                    data-toggle="dropdown"
                    style={{ color: "black" }}
                  >
                    {this.props.targetProfile.propic ? (
                      <img
                        src={
                          BASE_URL +
                          fetchProPic() +
                          `?picId=${this.props.targetProfile.propic}&size=480x480`
                        }
                        className="img-fluid rounded-circle"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <img
                        src={"/assets/img/img0.jpg"}
                        className="img-fluid rounded-circle"
                        alt=""
                      />
                    )}
                    <span>
                      {this.props.acc.firstName
                        ? this.props.acc.firstName +
                          " " +
                          this.props.acc.lastName
                        : this.props.acc.number}
                    </span>
                    <i className="fa fa-angle-down"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <nav className="nav">
                      {this.props.profiles.length > 1 ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.switchProfile()}
                          className="nav-link"
                        >
                          <i className="icon ion-person"></i> Switch Profile
                        </a>
                      ) : (
                        <a href="/signup/profile" className="nav-link">
                          <i className="icon ion-person"></i> Get Merchant
                          Account
                        </a>
                      )}
                      <NavLink to="/terms" className="nav-link">
                        <i className="icon ion-ios-gear"></i> Terms and
                        Conditions
                      </NavLink>
                      <NavLink to="/privacy-policy" className="nav-link">
                        <i className="icon ion-ios-gear"></i> Privacy Policy
                      </NavLink>
                      <a
                        href="/"
                        className="nav-link"
                        onClick={() => this.signOut()}
                      >
                        <i className="icon ion-forward"></i> Sign Out
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slim-mainpanel">
            <div className="container pd-t-30">
              <div className="dash-headline-two">
                <div className="d-flex">
                  <div className=" mg-md-t-0" style={{ display: "table" }}>
                    {this.props.targetProfile.propic ? (
                      <img
                        src={
                          BASE_URL +
                          fetchProPic() +
                          `?picId=${this.props.targetProfile.propic}&size=480x480`
                        }
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                        className=" rounded-circle"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/img/img0.jpg"
                        className="img-fluid rounded-circle"
                        alt="avatar"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div className="mg-l-15">
                    <h4 className="tx-inverse mg-b-5 mg-t-2">
                      {this.props.acc.firstName
                        ? this.props.acc.firstName +
                          " " +
                          this.props.acc.lastName
                        : this.props.acc.number}
                    </h4>
                    <p className="mg-b-0">
                      Today is {moment().format("MMM ddd D, YYYY")}
                    </p>
                  </div>
                </div>
                <div className="d-h-t-right">
                  <div className="summary-item">
                    <button
                      onClick={() => this.updateTransferState()}
                      data-toggle="modal"
                      data-target="#transfermodal"
                      className="btn btn-oblong btn-outline-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Send Money
                    </button>
                  </div>

                  <div className="summary-item">
                    <button
                      onClick={() => this.updatePaymentState()}
                      data-toggle="modal"
                      data-target="#paymentmodal"
                      className="btn btn-oblong btn-outline-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Make Payment
                    </button>
                  </div>

                  <div className="summary-item">
                    <button
                      onClick={() => this.updatePaymentState()}
                      data-toggle="modal"
                      data-target="#receivepaymentmodal"
                      className="btn btn-oblong btn-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Receive Payment
                    </button>
                  </div>
                </div>
              </div>

              <div className="nav-statistics-wrapper">
                <nav className="nav">
                  <NavLink to="/personal" exact className="nav-link">
                    Profile
                  </NavLink>
                  <NavLink
                    to="/personal/transactions"
                    exact
                    className="nav-link"
                  >
                    Transactions
                  </NavLink>
                  {/* <NavLink to="/acc/loans" className="nav-link">Loans</NavLink> */}
                </nav>
                <nav className="nav">
                  <NavLink to="/personal/settings" className="nav-link">
                    Profile Settings
                  </NavLink>
                  <NavLink to="/personal/kyc" className="nav-link">
                    Document uploads
                  </NavLink>
                </nav>
              </div>
              <div className="row row-statistics mg-b-30">
                <Switch>
                  <Route path={"/personal/transactions"} exact>
                    {<Transactions profile={this.state.targetProfile} />}
                  </Route>
                  <Route path={"/personal/settings"} exact>
                    <Settings profile={this.state.targetProfile} />
                  </Route>
                  <Route path={"/personal/kyc"} component={Kyc} exact />
                  {/* <Route path={"/acc/loans"} component={Loans} exact /> */}
                  <Route
                    path={"/personal/loanapplication"}
                    component={LoanApplication}
                    exact
                  />
                  <Route path={"/personal"} exact>
                    <Profile profile={this.state.targetProfile} />
                  </Route>
                  {/* <Route path={"/merchant"} exact ><MerchantProfile profile={this.state.targetProfile}/></Route>
                <Route path={"/merchant/transactions"}  exact ><Transactions profile={this.state.targetProfile}/></Route> */}
                </Switch>
              </div>
            </div>
          </div>
          <PaymentWizard />
          <TransferWizard />
          <TopupWizard stage={this.state.inheritTxnStage} />
          <ReceivePaymentWizard />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profiles: state.account.profiles,
    targetProfile: state.account.targetProfile,
    targetProfileImage: state.account.targetProfileImage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(getCurrentUser()),
    fetchProfile: (pid) => dispatch(getProfileInfo(pid)),
    fetchProPic: (proPicId, size) => dispatch(getPropic(proPicId, size)),
    resetWState: () => dispatch(resetWalletUtilityState()),
    resetUtilitiesState: () => dispatch(resetWalletUtilityState()),
    resetDestProfile: () => dispatch(updateDestProfile({ account: {} })),
    resetDestProfiles: () => dispatch(clearDestProfiles()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonalAccWrapper));
