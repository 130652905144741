import {
    getLoans
} from "../../api/loans";

 export function fetchLoans() {
    return dispatch => {
        return getLoans()
            .then(response => {
                let loans;
                try {
                    loans =
                        typeof response.data === "string" ?
                            JSON.parse(response.data) :
                            response.data;
                            
                } catch (error) {
                    loans = response.data;
                } finally {
                    dispatch({
                        type: "GET_LOANS",
                        data: loans
                    })
                    return Promise.resolve(loans)
                }
            })
            .catch(err => Promise.reject(err));
    };
}

