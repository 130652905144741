import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Kyc from "./Kyc";
import Profile from "./Profile";
import Settings from "./Settings";
import Transactions from "./Transactions";
import { connect } from "react-redux";
import {
  clearDestProfiles,
  getCurrentUser,
  getProfileInfo,
} from "../store/actions/usersActions";
import Cookies from "js-cookie";
import PaymentWizard from "../components/PaymentWizard";
import TransferWizard from "../components/TransferWizard";
import TopupWizard from "../components/TopupWizard";
import LoanApplication from "./LoanApplication";
import { fetchProPic } from "../api/account";
import { BASE_URL } from "../api/client";
import AgentDock from "./AgentDock";
import ScheduledTxn from "./ScheduledTxn";
import ScheduledTxnDetails from "./ScheduledTxnDetails";
import {
  resetWalletUtilityState,
  updateDestProfile,
} from "../store/actions/walletActions";
import ReceivePaymentWizard from "../components/ReceivePaymentWizard";
import SupportDevices from "./SupportDevices";

class MerchantAccWrapper extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    acc: {},
    targetProfile: {},
    inheritTxnStage: 0,
    isSuccess: false,
    isFailed: false,
  };

  componentDidMount() {
    this.props
      .fetchUser()
      .then(() => this.setState({ acc: this.props.acc }))
      .catch((e) => console.log(e));

    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props
        .fetchProfile(profileInfo.tprofile._id)
        .then(this.setState({ targetProfile: this.props.targetProfile }));
    } else {
      profileInfo = {
        tprofile: {},
      };
    }
  }

  updateTransferState = () => {
    this.props.resetWState();
    this.props.resetDestProfile();
    this.props.resetDestProfiles();
  };

  updatePaymentState = () => {
    // this.props.resetWState();
    this.props.resetUtilitiesState();
    this.props.resetDestProfile();
    this.props.resetDestProfiles();
  };

  updateInheritTxnStage = (stage) => {
    this.setState({ inheritTxnStage: stage });
  };

  updateUtilityState = (state) => {
    this.setState(state);
  };

  switchProfile = () => {
    let acc = this.state.acc;
    let profiles = acc.profiles.filter((profile) => {
      return (
        profile.profileType === "personal" &&
        profile._id !== this.state.targetProfile._id
      );
    });

    //
    let cookieObj = {
      profiles: this.state.acc.profiles,
      tprofile: profiles[0],
    };

    Cookies.set("dpp", JSON.stringify(cookieObj));

    window.location.href = "/personal/transactions";
  };

  signOut = () => {
    Cookies.remove("dpp");
  };

  render() {
    return (
      <>
        <link rel="stylesheet" href="/assets/css/slim.header-one.css" />

        <div className="dashboard-5 slim-header-one">
          <div className="slim-header">
            <div className="container">
              <div className="slim-header-left">
                <img
                  className="slim-logo mr-0"
                  width="200px"
                  src="/assets/img/dumelapay-clear.png"
                  alt="dumelapay logo"
                />
                <p style={{ marginBottom: "-10px", fontWeight: "bold  " }}>
                  Merchant
                </p>
              </div>
              <div className="slim-header-right">
                <div className="dropdown dropdown-c">
                  <a
                    href="/"
                    className="logged-user"
                    data-toggle="dropdown"
                    style={{ color: "black" }}
                  >
                    {this.props.targetProfile.propic ? (
                      <img
                        src={
                          BASE_URL +
                          fetchProPic() +
                          `?picId=${this.props.targetProfile.propic}&size=480x480`
                        }
                        className="img-fluid rounded-circle"
                        alt="profile avatar"
                        style={{
                          objectFit: "cover",
                          width: "2.6rem",
                          height: "2.6rem",
                        }}
                      />
                    ) : (
                      <img
                        src={"/assets/img/img0.jpg"}
                        className="img-fluid rounded-circle"
                        alt="profile avatar"
                      />
                    )}
                    <span>
                      {this.props.acc.firstName
                        ? this.props.acc.firstName +
                          " " +
                          this.props.acc.lastName
                        : this.props.acc.number}
                    </span>
                    <i className="fa fa-angle-down"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <nav className="nav">
                      {this.props.profiles.length > 1 ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => this.switchProfile()}
                          className="nav-link"
                        >
                          <i className="icon ion-person"></i> Switch Profile
                        </a>
                      ) : (
                        <a href="/signup/profile" className="nav-link">
                          <i className="icon ion-person"></i> Get Personal
                          Account
                        </a>
                      )}

                      <NavLink to="/terms" className="nav-link">
                        <i className="icon ion-ios-gear"></i>Terms and
                        Conditions
                      </NavLink>
                      <NavLink to="/privacy-policy" className="nav-link">
                        <i className="icon ion-ios-gear"></i>Privacy Policy
                      </NavLink>
                      {this.props.targetProfile ? (
                        this.props.targetProfile.isAgent ? (
                          <NavLink
                            to="/merchant/supportdevices"
                            className="nav-link"
                          >
                            <i className="icon ion-ios-gear"></i> Support
                            Devices
                          </NavLink>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      <a
                        href="/"
                        className="nav-link"
                        onClick={() => this.signOut()}
                      >
                        <i className="icon ion-forward"></i> Sign Out
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slim-mainpanel">
            <div className="container pd-t-30">
              <div className="dash-headline-two">
                <div className="d-flex">
                  <div className="mg-md-t-0">
                    {this.props.targetProfile.propic ? (
                      <img
                        src={
                          BASE_URL +
                          fetchProPic() +
                          `?picId=${this.props.targetProfile.propic}&size=480x480`
                        }
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                        className=" rounded-circle"
                        alt="profile avatar"
                      />
                    ) : (
                      <img
                        src="/assets/img/img0.jpg"
                        className="img-fluid rounded-circle"
                        alt="profile avatar"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div className="mg-l-15">
                    <h4 className="tx-inverse mg-b-5 mg-t-2">
                      {this.props.targetProfile.businessName
                        ? this.props.targetProfile.businessName
                        : "Merchant"}
                    </h4>
                    <p className="mg-b-0">
                      {this.props.acc.firstName
                        ? this.props.acc.firstName +
                          " " +
                          this.props.acc.lastName
                        : this.props.acc.number}
                    </p>
                  </div>
                </div>
                <div className="d-h-t-right">
                  <div className="summary-item">
                    <button
                      data-toggle="modal"
                      data-target="#transfermodal"
                      onClick={() => this.updateTransferState()}
                      className="btn btn-oblong btn-outline-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Send Money
                    </button>
                  </div>

                  <div className="summary-item">
                    <button
                      onClick={() => this.updatePaymentState()}
                      data-toggle="modal"
                      data-target="#paymentmodal"
                      className="btn btn-oblong btn-outline-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Make Payment
                    </button>
                  </div>

                  <div className="summary-item">
                    <button
                      onClick={() => this.updatePaymentState()}
                      data-toggle="modal"
                      data-target="#receivepaymentmodal"
                      className="btn btn-oblong btn-primary btn-block mg-b-10"
                      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                      Receive Payment
                    </button>
                  </div>
                </div>
              </div>

              <div className="nav-statistics-wrapper">
                <nav className="nav">
                  <NavLink to="/merchant" className="nav-link">
                    Profile
                  </NavLink>
                  <NavLink to="/merchant/transactions" className="nav-link">
                    Transactions
                  </NavLink>
                  {/* <NavLink to="/merchant/loans" className="nav-link">Loans</NavLink> */}
                </nav>
                <nav className="nav">
                  <NavLink to="/merchant/scheduledTxn" className="nav-link">
                    Scheduled Bulk Payments
                  </NavLink>
                  {this.props.targetProfile ? (
                    this.props.targetProfile.isAgent ? (
                      <NavLink to="/merchant/agent" className="nav-link">
                        Support Employees
                      </NavLink>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  <NavLink to="/merchant/settings" className="nav-link">
                    Profile Settings
                  </NavLink>
                  <NavLink to="/merchant/kyc" className="nav-link">
                    Document uploads
                  </NavLink>
                </nav>
              </div>
              <div className="row row-statistics mg-b-30">
                <Switch>
                  <Route path={"/merchant/transactions"} exact>
                    {
                      <Transactions
                        utilityStateUpdate={this.updateUtilityState}
                        txnStageUpdate={this.updateInheritTxnStage}
                        profile={this.state.targetProfile}
                      />
                    }
                  </Route>
                  <Route path={"/merchant/agent"} exact>
                    {
                      <AgentDock
                        utilityStateUpdate={this.updateUtilityState}
                        txnStageUpdate={this.updateInheritTxnStage}
                        profile={this.state.targetProfile}
                      />
                    }
                  </Route>
                  <Route path={"/merchant/scheduledTxn"} exact>
                    {
                      <ScheduledTxn
                        utilityStateUpdate={this.updateUtilityState}
                        txnStageUpdate={this.updateInheritTxnStage}
                        profile={this.state.targetProfile}
                      />
                    }
                  </Route>
                  <Route path={"/merchant/scheduledTxn/:id"} exact>
                    {
                      <ScheduledTxnDetails
                        utilityStateUpdate={this.updateUtilityState}
                        txnStageUpdate={this.updateInheritTxnStage}
                        profile={this.state.targetProfile}
                      />
                    }
                  </Route>
                  <Route path={"/merchant/settings"} exact>
                    <Settings profile={this.state.targetProfile} />
                  </Route>
                  <Route path={"/merchant/supportdevices"} exact>
                    <SupportDevices profile={this.state.targetProfile} />
                  </Route>
                  <Route path={"/merchant/kyc"} component={Kyc} exact />
                  {/* <Route path={"/acc/loans"} component={Loans} exact /> */}
                  <Route
                    path={"/merchant/loanapplication"}
                    component={LoanApplication}
                    exact
                  />
                  <Route path={"/merchant"} exact>
                    <Profile profile={this.state.targetProfile} />
                  </Route>
                  {/* <Route path={"/merchant"} exact ><MerchantProfile profile={this.state.targetProfile}/></Route>
                <Route path={"/merchant/transactions"}  exact ><Transactions profile={this.state.targetProfile}/></Route> */}
                </Switch>
              </div>
            </div>
          </div>
          <PaymentWizard />
          <TransferWizard />
          <TopupWizard />
          <ReceivePaymentWizard />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profiles: state.account.profiles,
    targetProfile: state.account.targetProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(getCurrentUser()),
    fetchProfile: (pid) => dispatch(getProfileInfo(pid)),
    resetWState: () => dispatch(resetWalletUtilityState()),
    resetUtilitiesState: () => dispatch(resetWalletUtilityState()),
    resetDestProfile: () => dispatch(updateDestProfile({ account: {} })),
    resetDestProfiles: () => dispatch(clearDestProfiles()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MerchantAccWrapper));
