import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchProfileWallet,
  fetchProfileWallets,
  fetchWalletTransactions,
  resetWalletUtilityState,
  switchTargetWallet,
} from "../store/actions/walletActions";
import Cookies from "js-cookie";
import TransactionsList from "../components/TransactionsList";
import TransactionsListSkeleton from "../components/TransactionsListSkeleton";

class Transactions extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    destProfiles: [],
    tProfile: {},
    otp: null,
    isFetchingTransactions: true,
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props
        .getProfileWallets(
          profileInfo.tprofile.profileType || this.props.profile.profileType
        )
        .then(() => {
          this.setState({ isFetchingTransactions: true });
          this.props
            .getTransactions(this.props.wallet.walletId, 0, 10)
            .finally(() => {
              this.setState({ isFetchingTransactions: false });
            });
        });
    } else {
      window.location.href = "/signup";
    }
  }

  updateState = () => {
    this.props.resetWState();
  };
  changeTargetWallet = (wallet) => {
    this.props.getProfileWallet(wallet.walletId);
    this.props.changeWalletContext(wallet).then(() => {
      this.setState({ isFetchingTransactions: true });
      this.props.getTransactions(wallet.walletId, 0, 10).finally(() => {
        this.setState({ isFetchingTransactions: false });
      });
    });
  };

  changeTransactionFilter(wallet, idx, size = 25) {}

  render() {
    return (
      <>
        <div
          className=" col-md-4 mg-md-t-0 tx-12"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="mg-b-20">
            <h3 className="tx-inverse tx-18 tx-lato tx-bold">Wallets</h3>
            {this.props.wallets.length < 1 ? (
              <p>No wallets found!!!</p>
            ) : (
              <ul className="wallet-list x-15">
                {this.props.wallets.map((wallet, idx) => (
                  <li
                    key={idx}
                    className="wallet-item"
                    onClick={() => this.changeTargetWallet(wallet)}
                  >
                    <i
                      className="demo-icon icon-wallet"
                      style={{ fontSize: "large" }}
                    ></i>
                    <p className="wallet-content">
                      {wallet.walletId.walletType}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="col-md-7">
          <div className="row">
            <div className="col-lg-6">
              <label className="section-title mg-t-0">Wallet Information</label>
              <div className="wallet-card">
                <div className="wallet-card__front wallet-card__part">
                  <span className="wallet-card__label">
                    Wallet Name:{" "}
                    {this.props.wallet.walletId
                      ? this.props.wallet.walletId.walletType
                      : ""}
                  </span>
                  <p
                    className="wallet-card__label mg-b-0 mg-t-2"
                    style={{ textAlign: "right" }}
                  >
                    Balance
                  </p>
                  <p className="wallet-card_numer">
                    {this.props.wallet.friendlyBalance}
                  </p>
                  <div className="wallet-card__space-75">
                    <span className="wallet-card__label">Wallet number</span>
                    <p className="tx-16 tx-white">
                      {this.props.wallet.walletId
                        ? this.props.wallet.walletId.cellphone
                        : ""}
                    </p>
                  </div>
                  {/* <!-- <div className="wallet-card__space-25">
                    <span className="wallet-card__label">Expires</span>
                          <p className="wallet-card__info">10/25</p>
                  </div> --> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                id="topup-btn"
                data-toggle="modal"
                onClick={() => this.updateState()}
                data-target="#topupmodal"
                style={{
                  border: "3px dashed #0F7ABF",
                  color: "#0F7ABF",
                  width: "10em",
                  height: "10em",
                  borderRadius: "50%",
                  cursor: "pointer",
                  margin: "auto",
                  marginTop: "17%",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "40%",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Top Up
                </p>
              </div>
            </div>
          </div>
          {this.state.isFetchingTransactions ? (
            <TransactionsListSkeleton />
          ) : (
            <TransactionsList />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wallet: state.walletData.wallet,
    wallets: state.walletData.wallets,
    destProfiles: state.account.destProfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    changeWalletContext: (wallet) => dispatch(switchTargetWallet(wallet)),
    getTransactions: (wId, idx, size) =>
      dispatch(fetchWalletTransactions(wId, idx, size)),
    resetWState: () => dispatch(resetWalletUtilityState()),
    getProfileWallet: (pType) => dispatch(fetchProfileWallet(pType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
