import { createDevice, getDevices, updateDevice } from "../../api/devices";

export function fetchProfileSupportDevices(body) {
  return (dispatch) => {
    return getDevices(body)
      .then((response) => {
        let devices;
        try {
          devices =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          devices = response.data;
        } finally {
          dispatch({
            type: "GET_DEVICES",
            data: devices,
          });
          return Promise.resolve(devices);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function createProfileSupportDevice(body) {
  return (dispatch) => {
    return createDevice(body)
      .then((response) => {
        let device;
        try {
          device =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          device = response.data;
        } finally {
          dispatch({
            type: "CREATE_DEVICE",
            data: device,
          });
          return Promise.resolve(device);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function changeProfileDevice(body) {
  return (dispatch) => {
    return updateDevice(body)
      .then((response) => {
        let device;
        try {
          device =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          device = response.data;
        } finally {
          dispatch({
            type: "UPDATE_DEVICE",
            data: device,
          });
          return Promise.resolve(device);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function updateDevicesState(body) {
  return (dispatch) => {
    return dispatch({
      type: "UPDATE_DEVICES_STATE",
      data: body,
    });
  };
}
