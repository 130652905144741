// get wallets
// create wallet
import {
    client
} from "./client";

export function getLoans() {
    return client.get(`/api/zeus/v1/loans`);
}

