const initState = {
  device: {},
  devices: [],
  devices_state: 0,
};

const deviceReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_DEVICES":
      return {
        ...state,
        devices: action.data,
      };
    case "CREATE_DEVICE":
      let devices = state.devices;
      devices.push(action.data);

      return {
        ...state,
        device: action.data,
        devices: devices,
      };
    case "ENABLE_DEVICE":
      return {
        ...state,
        device: action.data,
      };
    case "DISABLE_DEVICE":
      return {
        ...state,
        device: action.data,
      };
    case "UPDATE_DEVICES_STATE":
      return {
        ...state,
        devices_state: action.data.devices_state,
      };
    default:
      break;
  }
  return state;
};

export default deviceReducer;
