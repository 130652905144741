import React, { Component } from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import TransactionsListSkeleton from "../components/TransactionsListSkeleton";
import ScheduledTxnList from "../components/ScheduledTxnList";
import moment from "moment";
import { fetchScheduleTxnGroup } from "../store/actions/walletActions";
import { withRouter } from "react-router";

class ScheduledTxnDetails extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isFetchingTxnInfo: true,
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);

      let scheduleLink = this.props.history.location.pathname.split("/")[3];
      // console.log(profileInfo.tprofile._id);
      this.props
        .getTxnGroupDetails(profileInfo.tprofile._id, scheduleLink)
        .then(() => {
          this.setState({ isFetchingTxnInfo: false });
        })
        .finally(() => this.setState({ isFetchingTxnInfo: false }));
    } else {
      window.location.href = "/signup";
    }
  }

  render() {
    return (
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-3 mb-2">
                <p>Title: {this.props.txnGroup.title}</p>
              </div>
              <div className="col-2 mb-2">
                <p>Total Amount: {this.props.txnGroup.totalFriendlyAmount}</p>
              </div>
              <div className="col-3">
                <p>
                  Creation Date:{" "}
                  {moment(this.props.txnGroup.createdAt).format(
                    "ddd D MMM, YYYY"
                  )}
                </p>
              </div>
              <div className="col-4">
                <p>
                  Schedule Date:{" "}
                  {moment(this.props.txnGroup.scheduledExecutionTime).format(
                    "ddd D MMM, YYYY  :  hh:mm"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8" style={{ justifyContent: "flex-start" }}>
            {this.state.isFetchingTxnInfo ? (
              <TransactionsListSkeleton />
            ) : (
              <ScheduledTxnList />
            )}
          </div>
          <div className="col-md-4" style={{ justifyContent: "flex-start" }}>
            {this.props.txnGroup.signitories.map((sig) =>
              this.props.profile._id === sig._id ? (
                sig.verificationStatus ? (
                  <></>
                ) : (
                  <button
                    data-toggle="modal"
                    data-target="#transfermodal"
                    className="btn btn-oblong btn-outline-primary btn-block mg-t-50 mg-b-50"
                    style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                  >
                    Approve
                  </button>
                )
              ) : (
                <></>
              )
            )}

            <ul className="transaction-list">
              {this.props.txnGroup.signitories.map((sig) => (
                <li key={sig._id}>
                  <div className="wd-200 t-destination">{sig.name}</div>
                  <div className="wd-100 t-reference">
                    {sig.verificationStatus ? "Approved" : "Pending"}
                  </div>
                  {/* <div className="wd-150 t-reference">
                  {moment().format("MMM ddd D, YYYY")}
                </div> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    txnGroup: state.walletData.targetScheduledTxn,
    profile: state.account.targetProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTxnGroupDetails: (profile, scheduleLink) =>
      dispatch(fetchScheduleTxnGroup(profile, scheduleLink)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduledTxnDetails));
