import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import EmptyAnim from "./EmptyAnim";

class ScheduledTxnGroupList extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isLoading: true,
  };

  componentDidMount() {}

  navigateToDetails = (id) => {
    this.props.history.push("/merchant/scheduledTxn/" + id);
  };

  render() {
    return (
      <>
        {this.props.txnGroups.length > 0 ? (
          <>
            <label className="section-title mt-0">
              Scheduled Bulk Payments
            </label>

            <div className="transaction-block">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
              <ul className="transaction-list">
                {this.props.txnGroups.map((group) => (
                  <li
                    key={group.scheduleLink}
                    onClick={() => this.navigateToDetails(group.scheduleLink)}
                  >
                    <div className="wd-150 t-destination">{group.title}</div>
                    <div className="wd-150 t-reference">
                      {moment(group.scheduledExecutionTime).format(
                        " ddd D MMM, YYYY"
                      )}
                    </div>
                    <div className="wd-150 t-reference">
                      {group.signitories} Signatories
                    </div>
                    <div className="wd-150 t-reference">
                      {group.approvals} Approved
                    </div>
                    <div className="wd-150 t-reference">
                      Status:{" "}
                      {group.isApproved
                        ? "Approved"
                        : "Pending Complete Approval"}
                    </div>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          </>
        ) : (
          <div>
            <label className="section-title mt-0">
              Scheduled Bulk Payments
            </label>

            <div
              className="transaction-block"
              style={{ textAlign: "center", padding: "3em" }}
            >
              <EmptyAnim />
              No Scheduled payments found
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    txnGroups: state.walletData.scheduledTxn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduledTxnGroupList));
