import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchWalletTransactions } from "../store/actions/walletActions";
import moment from "moment";

class TransactionsList extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isLoading: true,
  };

  componentDidMount() {}

  render() {
    return (
      <>
        {this.props.transactions.length > 0 ? (
          <>
            <hr />
            <label className="section-title">Transactions</label>

            <div className="transaction-block">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="mg-b-6">
                  <i className="icon ion-calendar mg-r-3"></i>
                  {moment(this.props.transactions[0].txnDate).format(
                    "D MMM YYYY"
                  )}
                </p>
                <p className="mg-b-6">
                  Balance: {this.props.transactions[0].friendlyBalance}
                </p>
              </div>
              <ul className="transaction-list">
                {this.props.transactions.map((txn) => (
                  <li key={txn.key}>
                    <div className="wd-50 t-destination">
                      {txn.txnType === "REVERAL" ? (
                        <i className="icon ion-refresh"></i>
                      ) : txn.txnType === "CREDIT" ? (
                        <i className="icon ion-refresh"></i>
                      ) : (
                        <i className="icon  tx-danger"></i>
                      )}
                    </div>
                    <div className="wd-150 t-destination">
                      {moment(txn.txnDate).format("D MMM YYYY")}
                    </div>
                    {/* <div className="wd-100 t-type">
                      {txn.txnType}
                    </div> */}
                    <div className="wd-300 t-destination">{txn.txnParty}</div>
                    {/* <div className="wd-150 t-reference">{txn.reference}</div> */}

                    {txn.txnType === "CREDIT" ? (
                      <div className="wd-150 t-amount tx-success">
                        {txn.friendlyAmount}
                      </div>
                    ) : (
                      <div className="wd-150 t-amount tx-danger">
                        - {txn.friendlyAmount}
                      </div>
                    )}
                    <div className="wd-150 t-amount tx-danger">
                      Fee - {txn.friendlyFee}
                    </div>
                    <div className="wd-150 t-amount">{txn.friendlyBalance}</div>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          </>
        ) : (
          <div>
            <hr />
            <label className="section-title">Transactions</label>

            <div
              className="transaction-block"
              style={{ textAlign: "center", padding: "3em" }}
            >
              No transactions found
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.walletData.transactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactions: (wId, idx, size) =>
      dispatch(fetchWalletTransactions(wId, idx, size)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
