// user account
// users profile
// create personal profile
// create business profile
// activate business profile

// update personalinfo
// update account  info
// update address

import { client } from "./client";
import store from "../index";

export function getUser() {
  return client.get("/api/account/v1/me");
}

export function getProfile() {
  return client.get("/api/account/v1/profile", {
    Authorization: `Bearer `,
  });
}

export function createBasicAccount(body) {
  return client.post("/api/account/v1/acc/create/basic", body);
}

export function createPersonalProfile(body) {
  return client.post("/api/account/v1/profile/create/personal", body);
}

export function createMerchantProfile(body) {
  return client.post("/api/account/v1/profile/create/merchant", body);
}

export function updateAccount(body) {
  return client.put("/api/account/v1/acc", body);
}

export function searchPersonal(number) {
  return client.get(`/api/zeus/v1/search/personal?number=${number}`);
}

export function searchBusiness(number) {
  return client.get(`/api/zeus/v1/search/business?number=${number}`);
}

export function fetchProfile(profileId) {
  return client.get(`/api/account/v1/me/profile?pId=${profileId}`);
}

export function updatePropic(body) {
  let options = {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      store.dispatch({
        type: "UPLOAD_PROGRESS_EVENT",
        data: { percent: percent, total: total, uploaded: loaded },
      });
    },
  };

  return client.put(`/api/account/v1/profile/pic`, body, options);
}

export function fetchProPic() {
  return `api/account/v1/profile/pic`;
}

export function getProfileSupportAgents(profile) {
  return client.get(
    `/api/account/v1/merchant/self/supportagents?pId=${profile}`
  );
}

export function newSupportAgent(body) {
  return client.post(`/api/account/v1/merchant/supportagent/self/create`, body);
}

export function linkSupportAgent(body) {
  return client.put(`/api/account/v1/merchant/supportagent/link/self`, body);
}

export function unlinkSupportAgent(body) {
  return client.put(`/api/account/v1/merchant/supportagent/unlink/self`, body);
}

export function pushSignatoryRequest(body) {
  return client.put(`/api/account/v1/profile/signatories/request`, body);
}

export function verifySignatory(body) {
  return client.put(`/api/account/v1/profile/signatories/verify`, body);
}
