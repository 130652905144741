import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchLoans } from "../store/actions/loanActions";


class LoanApplication extends Component {


  constructor( props ){
    super(props);
    
  }

  state = {
    providersList:[],
    selectedLoan:{},
    applicationStage:0,
    isLoading:false,
  };

  componentDidMount() {
   
  }
  


  applyLoan(){
    // fire apply loan action

  }


  selectLoanProvider(loanInfo){
    this.setState({selectedLoan:loanInfo, applicationStage:1})
  }



  render() {
    
    return (
      <>
      {
        this.state.applicationStage === 0 ?
        <div className="col-md-12">
          <label className="section-title mg-t-10 mg-b-25">Loan Providers</label>
          <div className="row">
            {
              this.props.loans.map( (loanInfo,  idx) => (
                <div key={idx} className="col-md-4 mg-b-20" style={{cursor:"pointer"}} onClick={() => this.selectLoanProvider(loanInfo)}>
                  <div className="card card-blog">
                    <figure className="card-item-img bg-mantle">
                      <img className="img-fluid" src="http://via.placeholder.com/1000x667" alt="Image"/>
                    </figure>
                    <div className="card-body">
                      <p className="blog-category">{loanInfo.provider}</p>
                      <h5 className="blog-title"><a href="">{loanInfo.programName}</a></h5>
                      <p className="blog-text">{loanInfo.summary}</p>
                    </div>
                  </div>
                </div>
              ))
            }
            
          </div>
        </div>
        :

        <div className="col-md-12">
          <label className="section-title mg-b-25">Loan Information</label>
          <div className="row flex-row-reverse no-gutters">
            <div className="col-xl-8">
              <div className="pd-30"  style={{backgroundColor:"White"}}>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button> */}
                <div className="pd-xs-x-30 pd-y-10">
                  <h6 className="tx-xs-28 tx-inverse mg-b-5 tx-gray-600">Your Loan Application</h6>
                  <br/>
                  <div className="form-group">
                    <input type="text" name="names" className="form-control" placeholder="Full Names"/>
                  </div>
                  <div className="form-group">
                    <input type="text" name="names" className="form-control" placeholder="Full Names"/>
                  </div>
                  <div className="form-group">
                    <input type="text" name="names" className="form-control" placeholder="Full Names"/>
                  </div>
                  <div className="form-group">
                    <input type="text" name="names" className="form-control" placeholder="Full Names"/>
                  </div>
                  <div className="form-group">
                    <input type="text" name="names" className="form-control" placeholder="Full Names"/>
                  </div>
                  <div className="form-group mg-b-20">
                    <input type="email" name="password" className="form-control" placeholder="Enter your password"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 bg-primary">
              <div className="pd-40">
                <h1 className="tx-white mg-b-20">{this.state.selectedLoan.provider}  {this.state.selectedLoan.programName}</h1>
                <p className="tx-white op-7 mg-b-30 tx-13">{this.state.selectedLoan.summary}</p>

                <p className="mg-t-30 mg-b-20 tx-13  tx-white">More information? <a href="">{this.state.selectedLoan.programName}</a></p >
              </div>
            </div>
          </div>
                    
            
        </div>
      }
        
        
         
          
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loans: state.loanData.loans,
    loan: state.loanData.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoans: () => dispatch(fetchLoans()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanApplication);
