import React, { Component } from "react";
import { connect } from "react-redux";
import { pushResetRequest, resetpassword } from "../store/actions/authActions";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

class ForgotPassword extends Component {
  state = {
    stage: 1,
    number: null,
    password: null,
    isReseting: false,
    isResetFailed: false,
    cpassword: null,
    user: null,
    token: null,
  };

  componentDidMount() {}

  submitResetRequest = (e) => {
    e.preventDefault();
    this.setState({ isReseting: true });
    this.props
      .resetPass({ number: this.state.number })
      .then((res) => {
        this.setState({ stage: 2, user: res.user, isReseting: false });
      })
      .catch((err) => {
        this.setState({ isReseting: false, isResetFailed: true });
      });
  };
  resetPassword = (e) => {
    e.preventDefault();
    this.setState({ isReseting: true });
    this.props
      .reset({
        user: this.state.user,
        otp: this.state.token,
        password: this.state.password,
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch((err) => {
        this.setState({ isReseting: false, isResetFailed: true });
      });
  };

  onSignInFailure(result) {
    console.log(result);
  }

  render() {
    return (
      <div>
        <div className="d-md-flex flex-row-reverse">
          <div className="signin-right">
            {this.state.stage === 1 ? (
              <div className="signin-box">
                <h2 className="signin-title-primary">Forgot Your Password!</h2>
                <h5 className="signin-title-secondary">
                  {this.state.isReseting
                    ? "Sending reset OTP, if account exists."
                    : this.state.isResetFailed
                    ? "Failed, invalid number"
                    : "Provide your number to reset password."}
                </h5>
                <form onSubmit={(e) => this.submitResetRequest(e)}>
                  <div className="form-group">
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="BW"
                      countries={["BW"]}
                      value={this.state.number}
                      onChange={(data) => this.setState({ number: data })}
                    />
                  </div>

                  <button
                    className="btn btn-primary btn-block btn-signin"
                    type="submit"
                  >
                    Send Reset Request
                  </button>
                </form>
              </div>
            ) : (
              <form onSubmit={(e) => this.resetPassword(e)}>
                <h5 className="signin-title-secondary">
                  {this.state.isReseting
                    ? "Reseting your password"
                    : this.state.isResetFailed
                    ? "Failed to  reset your password"
                    : "Enter your new password"}
                </h5>
                <div className="form-group mg-b-30">
                  <p className="mb-0">Reset Pin</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Reset PIN"
                    required
                    value={this.state.token}
                    onChange={(e) => this.setState({ token: e.target.value })}
                  />
                </div>
                <p className="mb-0">Passwords</p>
                <div className="form-group mg-b-20">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                    required
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <div className="form-group mg-b-20">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                    required
                    value={this.state.cpassword}
                    onChange={(e) =>
                      this.setState({ cpassword: e.target.value })
                    }
                  />
                </div>
                <button
                  className="mb-1 btn btn-primary btn-block btn-signin"
                  type="submit"
                >
                  Reset password
                </button>

                <p
                  style={{ cursor: "pointer", color: "#2a7cd9" }}
                  onClick={(e) => this.submitResetRequest(e)}
                >
                  Click to resend OTP
                </p>
              </form>
            )}
          </div>
          <div
            className="signin-left"
            style={{ background: "#0b4381", color: "white" }}
          >
            <div className="signin-box">
              <img
                className="slim-logo"
                width="200px"
                src="/assets/img/dumelapay-clear.png"
              />
              <p>
                No more queues to send money or make payments - simplicity at
                your fingertips!!!
              </p>

              <p>
                Browse our site and see for yourself why you need Dumela Pay.
              </p>

              <p className="tx-12">
                &copy; Copyright 2022. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPass: (body) => dispatch(pushResetRequest(body)),
    reset: (body) => dispatch(resetpassword(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
