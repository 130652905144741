// get wallet transactions
// make transaction
// search transaction contacts
import axios from "axios";
// const request = require('request')

import { client } from "./client";

export function getWalletTransactions(body) {
  return client.post("/api/zeus/v1/wallet/transactions", body);
}

export function initPTransaction(body) {
  return client.post("/api/zeus/v1/payment", body);
}
export function initBillPaymentTransaction(body) {
  return client.post("/api/zeus/v1/billpayment", body);
}

export function initTTransaction(body) {
  return client.post("/api/zeus/v1/transfer", body);
}

export function initTSelfTransaction(body) {
  return client.post("/api/zeus/v1/transfer/self", body);
}

export function initTopUpTransaction(body) {
  return client.post("/api/zeus/v1/paygate-topup", body);
}

export function verifyTransaction(body) {
  return client.post("/api/zeus/v1/verify", body);
}

export function scheduleSingle(body) {
  return client.post("/api/zeus/v1/txn/schedule", body);
}

export function scheduleBatchTxn(body) {
  return client.post("/api/zeus/v1/txn/schedule/batch", body);
}

export function verifyScheduleTxn(body) {
  return client.put("/api/zeus/v1/txn/schedule/auth", body);
}

export function txnCheck(body) {
  return client.post("/api/zeus/v1/txn/txnCheck", body);
}

export function getProfileScheduleGroups(profileId) {
  return client.get(
    `/api/zeus/v1/txn/schedules/profile?profileId=${profileId}`
  );
}

export function getProfileScheduleGroup(profileId, scheduleLink) {
  return client.get(
    `/api/zeus/v1/txn/schedules/group/profile?profileId=${profileId}&scheduleLink=${scheduleLink}`
  );
}

export function cancelProfileScheduleGroup(body) {
  return client.put(`/api/zeus/v1/txn/schedule/group/cancel`, body);
}

export function cancelProfileScheduleItem(body) {
  return client.put(`/api/zeus/v1/txn/schedule/item/cancel`, body);
}

export function vcs(body) {
  var myHeaders = new Headers();

  var requestOptions = {
    mode: "no-cors",
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };

  fetch("https://www.vcs.co.za/vvonline/vcspay.aspx", requestOptions)
    .then((response) => {})
    .catch((error) => console.log("error", error));
}
