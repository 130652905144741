// get wallets
// create wallet
import { client } from "./client";

export function createDevice(body) {
  return client.post(`/api/account/v1/supportDevice`, body);
}

export function getDevices(profile) {
  return client.get(`/api/account/v1/supportDevice?profile=${profile}`);
}

export function updateDevice(body) {
  return client.put(`/api/account/v1/supportDevice`, body);
}
