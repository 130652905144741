import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import OTP from "../components/OTP";
import { connect } from "react-redux";
import { issueNewBasicAccount } from "../store/actions/usersActions";
import IntlTelInput from "react-bootstrap-intl-tel-input";
import PasswordStrengthBar from "react-password-strength-bar";
import PhoneInput from "react-phone-number-input";

class Register extends Component {
  state = {
    stage: 1,
    number: null,
    pass: "1",
    cpass: null,
    isLoading: false,
    isAmatch: false,
    hasFailed: false,
    errorMessage: "",
  };

  componentDidMount() {}

  submit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let body = {
      number: this.state.number,
      password: this.state.pass,
      confirmPassword: this.state.cpass,
    };

    this.props
      .createAccount(body)
      .then(() => {
        this.setState({ stage: 2 });
      })
      .catch((err) => {
        this.setState({
          errorMessage: err.data,
          hasFailed: true,
          isLoading: false,
        });
        console.log(err);
      });
    //submit info
    //create account
  };

  reset = () => {
    this.setState({
      errorMessage: "",
      hasFailed: false,
      isLoading: false,
    });
  };

  updateConfirmPwd = (pwd) => {
    this.setState({ cpass: pwd, isAmatch: this.state.pass === pwd });
  };

  render() {
    return (
      <div>
        <div className="d-md-flex flex-row-reverse">
          <div className="signin-right">
            {this.state.stage === 1 ? (
              this.state.hasFailed ? (
                <div className="signin-box">
                  {/* <h2 className="signin-title-primary">Welcome back!</h2> */}
                  <h3 className="signin-title-secondary">
                    {this.state.errorMessage}
                  </h3>
                  <button
                    onClick={() => this.reset()}
                    className="btn btn-primary btn-block btn-signin"
                    type="submit"
                  >
                    Try Again
                  </button>
                </div>
              ) : this.state.isLoading ? (
                <div className="signin-box">
                  {/* <h2 className="signin-title-primary">Welcome back!</h2> */}
                  <h3 className="signin-title-secondary">
                    Creating your account...
                  </h3>
                </div>
              ) : (
                <div className="signin-box">
                  {/* <h2 className="signin-title-primary">Welcome back!</h2> */}
                  <h3 className="signin-title-secondary">
                    Lets get you setup...
                  </h3>
                  <form onSubmit={(e) => this.submit(e)}>
                    <div className="form-group">
                      {/* <IntlTelInput
                preferredCountries={['BW']}
                defaultCountry={'BW'}
                // defaultValue={'+267 77777777'}
                // placeholder={'77777777'}
                onChange={(data) => this.setState({number:data.intlPhoneNumber})}
              /> */}
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="BW"
                        value={this.state.number}
                        onChange={(data) => this.setState({ number: data })}
                      />
                      {/* <input type="number" className="form-control" placeholder="Enter your mobile number" required onChange={(e) => this.setState({number:e.target.value})}/> */}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                        required
                        onChange={(e) =>
                          this.setState({ pass: e.target.value })
                        }
                      />
                      <PasswordStrengthBar
                        minLength={8}
                        password={this.state.pass}
                      />
                    </div>
                    <div className="form-group mg-b-20">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm your password"
                        required
                        style={
                          this.state.isAmatch ? {} : { borderColor: "red" }
                        }
                        onChange={(e) => this.updateConfirmPwd(e.target.value)}
                      />
                    </div>
                    {this.state.isAmatch ? (
                      <button
                        className="btn btn-primary btn-block btn-signin"
                        type="submit"
                      >
                        Submit
                      </button>
                    ) : (
                      <>
                        <p style={{ color: "red" }}>Passwords do not match</p>
                        <button
                          className="btn btn-primary btn-block "
                          style={{
                            backgroundColor: "#2e51688a",
                            borderColor: "#2e51688a",
                          }}
                        >
                          Submit
                        </button>
                      </>
                    )}

                    <p className="mg-b-0 mg-t-20">
                      Already have an account? <NavLink to="/">Login</NavLink>
                    </p>
                  </form>
                </div>
              )
            ) : (
              <OTP number={this.state.number} />
            )}
          </div>
          <div
            className="signin-left"
            style={{ background: "#0b4381", color: "white" }}
          >
            <div className="signin-box">
              <img
                className="slim-logo"
                width="200px"
                src="/assets/img/dumelapay-clear.png"
              />

              <p>
                No more queues to send money or make payments - simplicity at
                your fingertips!!!
              </p>

              <p>
                Browse our site and see for yourself why you need Dumela Pay.
              </p>

              <p className="tx-12">
                &copy; Copyright 2022. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAccount: (body) => dispatch(issueNewBasicAccount(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
