const initState = {
  acc: {},
  profiles: [],
  destProfiles: [],
  targetProfile: { signatories: [] },
  targetProfileImage: "",
  supportAgents: [],
  supportAgent: {},
  signatories: [],
  signatory_results: [],
  signatory_r_id: "",
  signatories_state: 0,
  isSearchingSignatory: false,
  isSigSearchEmpty: false,
  signatory_request: {},
};

const accountReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_USER_ACCOUNT":
      return {
        ...state,
        acc: action.data,
      };
    case "GET_CURRENT_USER":
      return {
        ...state,
        acc: action.data,
        profiles: action.data.profiles,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        acc: action.data,
      };
    case "CREATE_PROFILE":
      return {
        ...state,
        profiles: [action.data],
      };
    case "SIGNUP_USER":
      return {
        ...action.data,
      };
    case "SET_USER":
      return {
        ...action.user,
      };
    case "RECOVER_PASSWORD":
      return {
        ...action.data,
      };
    case "GET_DEST_PROFILES":
      return {
        ...state,
        destProfiles: action.data,
      };
    case "CLEAR_DEST_PROFILES":
      return {
        ...state,
        destProfiles: action.data,
      };
    case "GET_PROFILE":
      return {
        ...state,
        targetProfile: action.data,
      };
    case "GET_PROFILE_PIC":
      return {
        ...state,
        targetProfileImage: action.data,
      };
    case "GET_SUPPORT_AGENTS":
      return {
        ...state,
        supportAgents: action.data,
      };
    case "CREATE_SUPPORT_AGENT":
      return {
        ...state,
        supportAgent: action.data,
        supportAgents: state.supportAgents.push(action.data),
      };
    case "LINK_SUPPORT_AGENT":
      return {
        ...state,
        supportAgent: action.data,
        supportAgents: state.supportAgents.push(action.data),
      };
    case "REMOVE_SUPPORT_AGENT":
      return {
        ...state,
        supportAgent: {},
        supportAgents: state.supportAgents.filter(
          (agent) => agent._id !== action.data._id
        ),
      };
    case "UPDATE_SIGNATORY_SEARCH":
      return {
        ...state,
        isSearchingSignatory: action.data,
      };
    case "RESET_SIGNATORY_STATE":
      return {
        ...state,
        ...action.data,
      };
    case "UPDATE_SIGNATORY_STATE":
      return {
        ...state,
        ...action.data,
      };
    case "UPDATE_PROFILE_SIGNATORY":
      return {
        ...state,
        targetProfile: action.data,
        signatories: action.data.signatories,
      };
    case "UPDATE_PROFILE_SIGNATORY_REQUEST":
      return {
        ...state,
        signatory_request: action.data,
      };
    default:
      break;
  }
  return state;
};

export default accountReducer;
