import React, { Component } from "react";

class TransactionsListSkeleton extends Component {
  render() {
    return (
      <>
        <label className="section-title"></label>

        <div className="transaction-block">
          <ul className="transaction-list">
            <li>
              <div className="wd-50 t-destination"></div>
              <div className="wd-150 t-destination"></div>
              <div className="wd-150 t-reference"></div>
              <div className="wd-100 t-type"></div>
              <div className="wd-150 t-amount tx-success"></div>
            </li>
            <li>
              <div className="wd-50 t-destination"></div>
              <div className="wd-150 t-destination"></div>
              <div className="wd-150 t-reference"></div>
              <div className="wd-100 t-type"></div>
              <div className="wd-150 t-amount tx-danger"></div>
            </li>
            <li>
              <div className="wd-50 t-destination"></div>
              <div className="wd-150 t-destination"></div>
              <div className="wd-150 t-reference"></div>
              <div className="wd-100 t-type"></div>
              <div className="wd-150 t-amount tx-success"></div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default TransactionsListSkeleton;
