import {
  verifyOTP,
  loginBasic,
  loginG,
  resetRequest,
  reset,
  resendOTP,
} from "../../api/auth";

import Cookies from "js-cookie";

export function checkOTP(body) {
  return (dispatch) => {
    return verifyOTP(body)
      .then((response) => {
        let acc;
        try {
          Cookies.set("dumelapay", response.data);

          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          return acc;
          // getCurrentUser()
        } catch (error) {
        } finally {
          dispatch({
            type: "GET_OTP_VERIFICATION",
            data: acc,
          });
          return Promise.resolve(acc);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}

export function pushLogin(body) {
  return (dispatch) => {
    return loginBasic(body)
      .then((response) => {
        return response.data;
      })
      .catch((err) => Promise.reject(err));
  };
}

export function pushOTPResend(body) {
  return (dispatch) => {
    return resendOTP(body)
      .then((response) => {
        return response.data;
      })
      .catch((err) => Promise.reject(err));
  };
}

export function pushResetRequest(body) {
  return (dispatch) => {
    return resetRequest(body)
      .then((response) => {
        return response.data;
      })
      .catch((err) => Promise.reject(err));
  };
}

export function resetpassword(body) {
  return (dispatch) => {
    return reset(body)
      .then((response) => {
        return response.data;
      })
      .catch((err) => Promise.reject(err));
  };
}

export function pushLoginG(body) {
  return (dispatch) => {
    return loginG(body)
      .then((response) => {
        let acc;
        try {
          acc =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
        } catch (error) {
          acc = response.data;
        } finally {
          return Promise.resolve(acc);
        }
      })
      .catch((err) => Promise.reject(err));
  };
}
