import React, { Component } from "react";
import { connect } from "react-redux";
import {
  authScheduleTxn,
  changeScheduleStage,
  scheduleSingleTxn,
  scheduleTxnBatch,
  updateWalletUtilityState,
} from "../store/actions/walletActions";
import {
  clearDestProfiles,
  searchPProfiles,
} from "../store/actions/usersActions";
import SuccessAnim from "./SuccessAnim";
import FailedAnim from "./FailedAnim";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import { withRouter } from "react-router";

class SchedulePaymentWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    tProfile: {},
    isLoading: false,
    countries: [
      { value: "botswana", label: "Botswana" },
      { value: "south_africa", label: "South Africa" },
      { value: "namibia", label: "Namibia" },
      { value: "india", label: "India" },
      { value: "zimbabwe", label: "Zimbabwe" },
      { value: "china", label: "China" },
    ],
    nationality: "botswana",
    dob: "",
    firstName: "",
    lastName: "",
    gender: "",
    nationalId: "",
    passportNumber: "",
    searchResults: [],
    file: {},
    scheduleTitle: "",
    number: 0,
    txnAmount: null,
    accountType: "business",
    txnOTP: null,
    scheduleTime: "",
    errorMsg: "",
  };

  componentDidMount() {}

  updateNumber = (number) => {
    this.setState({ number: number });
    if (number && number.length >= 10) {
      this.props.fetchPProfiles(number.replace("+", "").trim());
    } else {
      this.props.emptySearchResults();
    }
  };

  changeFile(file, ctx) {
    if (!ctx) return;

    ctx.setState({
      file: {
        path: file.path,
        file: file,
        name: file.name,
      },
    });
  }

  submitSingle = (e) => {
    e.preventDefault();

    this.props.updateScheduleStage(4);
    let body = {
      recieverNumber: this.state.number,
      amount: this.state.txnAmount,
      scheduledTime: this.state.scheduleTime,
      scheduleTitle: this.state.scheduleTitle,
      originNumber: this.props.profile.number,
      txnType: this.state.accountType,
      accountType: this.state.accountType,
    };

    this.props
      .pushSingle(body)
      .then(() => {
        this.props.updateScheduleStage(3);
      })
      .catch((err) => {
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: true,
        });
        console.log(err);
        this.setState({ errorMsg: err.err });
      });
  };

  submitBatch = (e) => {
    this.props.updateScheduleStage(4);
    e.preventDefault();
    var body = new FormData();
    body.append("file", this.state.file.file, this.state.file.path);
    body.append("scheduledTime", this.state.scheduleTime);
    body.append("scheduleTitle", this.state.scheduleTitle);
    body.append("originNumber", this.props.profile.number);
    body.append("txnChannel", "web");
    body.append("profileId", this.props.profile._id);

    this.props
      .pushBatch(body)
      .then(() => {
        this.props.updateScheduleStage(5);
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: false,
        });
      })
      .catch((err) => {
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: true,
        });
        this.setState({ errorMsg: err });
      });
  };

  authorizeTxn = (e) => {
    this.props.updateScheduleStage(4);
    e.preventDefault();
    let body = {
      scheduleLink: this.props.targetScheduledTxn.scheduleLink,
      verificationCode: this.state.txnOTP,
      profileId: this.props.profile._id,
    };

    this.props
      .verify(body)
      .then(() => {
        //TODO:  update target txn
        this.props.updateUtilityState({
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch((err) => {
        this.props.updateUtilityState({
          isSuccess: false,
          isFailure: true,
        });
      });
  };

  viewTxnDetails = () => {
    this.props.updateUtilityState({
      isSuccess: false,
      isFailure: false,
    });

    window.location.href = `/${this.props.profile.profileType}/scheduledTxn/${this.props.targetScheduledTxn.scheduleLink}`;
  };

  render() {
    return (
      <div id="SchedulePaymentWizard" className="modal fade">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content bd-0 bg-transparent rounded overflow-hidden">
            <div className="modal-body pd-0" style={{ padding: "5em" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ padding: "0.5em", marginLeft: "-2em" }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row no-gutters" style={{ minHeight: "60vh" }}>
                <div className="modal__container">
                  <div className="modal__featured d-none d-xl-block">
                    <div className="modal__circle"></div>
                    <div className="modal__product hidden-xs">
                      <img
                        style={{
                          marginTop: "12em",
                          marginBottom: "2em",
                          maxWidth: "124%",
                        }}
                        alt="dumelapayLogo"
                        src="/assets/img/dumelapay-clear.png"
                      />
                      <p style={{ fontSize: "17px", color: "white" }}>
                        Lets help you help yourself.
                      </p>
                    </div>
                  </div>
                  <div className="modal__content">
                    <h2 className="mb-4">Schedule Bulk payments</h2>
                    {this.props.isSuccess ? (
                      <div className="text-center">
                        <SuccessAnim />
                        <p>Authorization Successful</p>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => this.viewTxnDetails()}
                        >
                          Click to view Details
                        </p>
                      </div>
                    ) : this.props.isFailure ? (
                      <div className="text-center">
                        <FailedAnim />
                        <p>{this.state.errorMsg}</p>
                      </div>
                    ) : this.props.scheduleStage === 0 ? (
                      <>
                        <div>
                          <p>Schedule from CSV</p>
                          <p>
                            Schedule batch payments by uploading a csv file of
                            the payments you need
                          </p>
                          <button
                            onClick={() => this.props.updateScheduleStage(1)}
                            className="btn btn-oblong btn-primary btn-block mg-b-10"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                            }}
                          >
                            Schedule From File
                          </button>
                        </div>
                        <hr className="mt-5 mb-5" />
                        <div>
                          <p>Schedule individual Payment</p>
                          <p>Customize individual payment</p>
                          <button
                            onClick={() => this.props.updateScheduleStage(2)}
                            className="btn btn-oblong btn-outline-primary btn-block mg-b-10"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                            }}
                          >
                            Schedule Single Payment
                          </button>
                        </div>
                      </>
                    ) : this.props.scheduleStage === 1 ? (
                      <div>
                        <form
                          onSubmit={(e) => this.submitBatch(e)}
                          className="mt-4"
                        >
                          <ul className="form-list">
                            <li className="form-list__row">
                              <label>Title</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="text"
                                  required
                                  value={this.state.scheduleTitle}
                                  onChange={(e) =>
                                    this.setState({
                                      scheduleTitle: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Payment Time</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="datetime-local"
                                  required
                                  value={this.state.scheduleTime}
                                  onChange={(e) =>
                                    this.setState({
                                      scheduleTime: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                          </ul>
                          <div className="wrapper mb-2">
                            <div
                              className="file-upload"
                              style={{ height: "100px" }}
                            >
                              <input
                                type="file"
                                required
                                onChange={(e) =>
                                  this.changeFile(e.target.files[0], this)
                                }
                                style={{ height: "100px" }}
                              />
                              <div style={{ textAlign: "center" }}>
                                <i
                                  className="icon-upload-cloud"
                                  style={{
                                    display: "block",
                                    fontSize: "28px",
                                    color: "#0F7ABF",
                                  }}
                                ></i>
                                <span style={{ display: "block" }}>
                                  {" "}
                                  Click or drag file here
                                </span>
                              </div>
                            </div>
                          </div>
                          <p>{this.state.file.name}</p>

                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-block"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                              borderRadius: "20px",
                            }}
                          >
                            Upload
                          </button>
                        </form>
                        <hr className="mt-5 mb-5" />
                        <p>
                          First row contains the column titles, every other row
                          contains coresponding data
                        </p>

                        <a href="/assets/csv/samplecsv.csv">
                          download sample CSV file
                        </a>
                        {/* <p>Example format</p>
                          <code>
                            recieverNumber, amount,
                            <br />
                            26777777777,200.00,
                            <br />
                            26777777712,500.00,
                          </code> */}
                      </div>
                    ) : this.props.scheduleStage === 2 ? (
                      <>
                        <form
                          id="singleSechudleSubForm"
                          onSubmit={(e) => this.submitSingle(e)}
                          className="mt-4"
                        >
                          <ul className="form-list">
                            <li className="form-list__row">
                              <label>Receiver Number</label>
                              <div>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="BW"
                                  countries={["BW"]}
                                  value={this.state.number}
                                  onChange={(e) => this.updateNumber(e)}
                                />
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Receiver accountType</label>
                              <div>
                                <select
                                  id="accountType"
                                  className="form-control select2 payment_wizard_input"
                                  name="accountType"
                                  onChange={(e) =>
                                    this.setState({
                                      accountType: e.target.value,
                                    })
                                  }
                                  required
                                >
                                  <option value="business">MERCHANT</option>
                                  <option value="personal">PERSONAL</option>
                                </select>
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Title</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="text"
                                  required
                                  value={this.state.scheduleTitle}
                                  onChange={(e) =>
                                    this.setState({
                                      scheduleTitle: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Payment Time</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="datetime-local"
                                  max={moment().format("d-MM-yyyy hh:mm")}
                                  required
                                  value={this.state.scheduleTime}
                                  onChange={(e) =>
                                    this.setState({
                                      scheduleTime: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                            <li className="form-list__row">
                              <label>Amount</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="number"
                                  step=".01"
                                  required
                                  value={this.state.txnAmount}
                                  onChange={(e) =>
                                    this.setState({
                                      txnAmount: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                          </ul>

                          <button
                            id="singleSechudleSub"
                            type="submit"
                            className="form-group btn btn-outline-primary btn-block"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                              borderRadius: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </>
                    ) : this.props.scheduleStage === 3 ? (
                      <>
                        <form
                          onSubmit={(e) => this.authorizeTxn(e)}
                          className="pd-y-40"
                        >
                          <div>
                            <p>
                              Title:
                              {this.props.targetScheduledTxn.scheduleTitle}
                            </p>
                            <p>
                              Execution Time:
                              {moment(
                                this.props.targetScheduledTxn
                                  .scheduledExecutionTime
                              ).format("D MMM YYYY")}
                            </p>
                            <p>
                              Pay to :
                              {this.props.targetScheduledTxn.destination.name}
                            </p>
                          </div>
                          <ul className="form-list mt-5">
                            <li className="form-list__row">
                              <label>Authorization (enter OTP)</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="number"
                                  required
                                  value={this.state.txnOTP}
                                  onChange={(e) =>
                                    this.setState({
                                      txnOTP: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                          </ul>

                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-block"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                              borderRadius: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </>
                    ) : this.props.scheduleStage === 4 ? (
                      <div>
                        <h4>Processing transaction ...</h4>
                      </div>
                    ) : this.props.scheduleStage === 5 ? (
                      <>
                        <form
                          onSubmit={(e) => this.authorizeTxn(e)}
                          className="pd-y-40"
                        >
                          <div>
                            <p>
                              Title:
                              {this.props.targetScheduledTxn.title}
                            </p>
                            <p>
                              Execution Time:
                              {moment(
                                this.props.targetScheduledTxn
                                  .scheduledExecutionTime
                              ).format("D MMM YYYY, hh:mm")}
                            </p>
                            <p>
                              Number of transactions:
                              {this.props.targetScheduledTxn.totalTxns}
                            </p>
                            <p>
                              Total:
                              {this.props.targetScheduledTxn.totalAmount}
                            </p>
                          </div>
                          <ul className="form-list mt-5">
                            <li className="form-list__row">
                              <label>Authorization (enter OTP)</label>
                              <div>
                                <input
                                  className="payment_wizard_input"
                                  type="number"
                                  required
                                  value={this.state.txnOTP}
                                  onChange={(e) =>
                                    this.setState({
                                      txnOTP: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </li>
                          </ul>

                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-block"
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "2rem",
                              borderRadius: "20px",
                            }}
                          >
                            Submit
                          </button>
                        </form>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    destProfiles: state.account.destProfiles,
    profile: state.account.targetProfile,
    isSuccess: state.walletData.isSuccess,
    isFailure: state.walletData.isFailure,
    scheduleStage: state.walletData.scheduleStage,
    targetScheduledTxn: state.walletData.targetScheduledTxn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPProfiles: (number) => dispatch(searchPProfiles(number)),
    emptySearchResults: () => dispatch(clearDestProfiles()),
    pushBatch: (body) => dispatch(scheduleTxnBatch(body)),
    pushSingle: (body) => dispatch(scheduleSingleTxn(body)),
    updateScheduleStage: (stage) => dispatch(changeScheduleStage(stage)),
    verify: (body) => dispatch(authScheduleTxn(body)),
    updateUtilityState: (state) => dispatch(updateWalletUtilityState(state)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SchedulePaymentWizard));
