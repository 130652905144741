import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProPic } from "../api/account";
import { BASE_URL } from "../api/client";
import {
  disableProfileDevice,
  enableProfileDevice,
  fetchProfileSupportDevices,
  updateDevicesState,
} from "../store/actions/deviceActions";
import Cookies from "js-cookie";
import SignatoryWizard from "../components/SignatoryWizard";
import { fetchProfileWallets } from "../store/actions/walletActions";
import SupportDeviceWizard from "../components/SupportDeviceWizard";
import { updateSignatoryState } from "../store/actions/usersActions";
import moment from "moment";
import SupportDeviceUpdateWizard from "../components/SupportDeviceUpdateWizard";

class SupportDevices extends Component {
  state = {
    targetDevice: {},
  };

  componentDidMount() {
    let profileInfo = Cookies.get("dpp");
    if (profileInfo) {
      profileInfo = JSON.parse(profileInfo);
      this.props.getProfileWallets(
        profileInfo.tprofile.profileType || this.props.profile.profileType
      );
      this.props.getDevices(profileInfo.tprofile._id);
    }

    this.setState({ profile: this.props.profile, acc: this.props.acc });
  }

  selectDevice = (device) => {
    this.setState({ targetDevice: device });
    this.props.updateState({ devices_state: 0 });
  };
  render() {
    return (
      <>
        <div className="col-md-12 mg-t-40 mg-md-t-0">
          <div className="section-wrapper mg-b-20 d-none d-lg-block d-xl-block">
            <label className="section-title">Support Devices</label>
            <ul className="signatory_list pd-l-0" style={{ listStyle: "none" }}>
              {this.props.devices ? (
                this.props.devices.length < 1 ? (
                  <p
                    className="
                  mt-3"
                    style={{ textTransform: "capitalize" }}
                  >
                    No devices found
                  </p>
                ) : (
                  this.props.devices.map((device) => (
                    <li
                      key={device._id}
                      className="mb-1 mt-1"
                      style={{ borderBottom: "solid 1px #c9c9c9" }}
                    >
                      <div
                        style={{ display: "flex", textTransform: "capitalize" }}
                      >
                        <p className="wd-150 mb-2 mt-2">
                          Status: {device.status ? "active" : "disabled"}
                        </p>
                        <p className="wd-250 mb-2 mt-2">Name: {device.name}</p>
                        <p className="wd-200 mb-2 mt-2">
                          Number: {device.number}
                        </p>
                        <p className="wd-200 mb-2 mt-2">
                          Device Type: {device.machineType}
                        </p>
                        <p className="wd-250 mb-2 mt-2">
                          Created at:{" "}
                          {moment(device.createdAt).format("D MMM YYYY, hh:mm")}
                        </p>
                        <button
                          onClick={() => this.selectDevice(device)}
                          className="wd-100 btn btn-oblong btn-danger btn-block mb-2 mt-2 p-1"
                          data-toggle="modal"
                          data-target="#supportDeviceUpdateModal"
                        >
                          {device.status ? "Disable" : "Enable"}
                        </button>
                      </div>
                    </li>
                  ))
                )
              ) : (
                ""
              )}
            </ul>

            <div
              className="form-layout-footer mg-t-30"
              style={{ textAlign: "end" }}
            >
              <button
                onClick={() => this.props.updateState({ devices_state: 0 })}
                className="btn btn-primary bd-0 mg-r-20"
                style={{ borderRadius: "25px" }}
                data-toggle="modal"
                data-target="#supportDeviceModal"
              >
                Add device
              </button>
            </div>
            <SupportDeviceWizard />
            <SupportDeviceUpdateWizard device={this.state.targetDevice} />
          </div>
          <div className="d-sm-block d-md-block d-lg-none d-xl-none">
            <label className="section-title mt-1">Support Devices</label>
            <div className="row">
              {this.props.devices ? (
                this.props.devices.length < 1 ? (
                  <p
                    className="
                  mt-2"
                    style={{ textTransform: "capitalize" }}
                  >
                    No devices found
                  </p>
                ) : (
                  this.props.devices.map((device) => (
                    <div
                      className="col-sm-12 col-md-6 mb-3"
                      key={device._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-2 mt-2">
                            Status: {device.status ? "active" : "disabled"}
                          </p>
                          <br />
                          <p className="mb-2 mt-2">Name: {device.name}</p>
                          <p className="mb-2 mt-2">Number: {device.number}</p>
                          <p className="mb-2 mt-2">
                            Device Type: {device.machineType}
                          </p>
                          <p className="mb-2 mt-2">
                            Created at:{" "}
                            {moment(device.createdAt).format(
                              "D MMM YYYY, hh:mm"
                            )}
                          </p>
                          <br />
                          <button
                            onClick={() => this.selectDevice(device)}
                            className="wd-100 btn btn-oblong btn-danger btn-block mb-2 mt-2 p-1"
                            data-toggle="modal"
                            data-target="#supportDeviceUpdateModal"
                          >
                            {device.status ? "Disable" : "Enable"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    acc: state.account.acc,
    profile: state.account.targetProfile,
    devices: state.deviceData.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileWallets: (wallet) => dispatch(fetchProfileWallets(wallet)),
    getDevices: (profile) => dispatch(fetchProfileSupportDevices(profile)),
    updateState: (body) => dispatch(updateDevicesState(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportDevices);
