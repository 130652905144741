import { searchBillingUtilities , getAllBillingUtilities} from "../../api/utilities";

export function searchUtilities(searchInput) {
    return dispatch => {
        return searchBillingUtilities(searchInput)
            .then(response => {
                let utilities;
                try {
                    utilities =
                        typeof response.data === "string" ?
                            JSON.parse(response.data) :
                            response.data;
                } catch (error) {
                    
                } finally {
                    dispatch({
                        type: "GET_DEST_UTILITIES",
                        data: utilities
                    })
                    return Promise.resolve(utilities)
                }
            })
            .catch(err => Promise.reject(err));
    };
}

export function getAllUtilities(searchInput) {
    return dispatch => {
        return getAllBillingUtilities(searchInput)
            .then(response => {
                let utilities;
                try {
                    utilities =
                        typeof response.data === "string" ?
                            JSON.parse(response.data) :
                            response.data;
                } catch (error) {
                    
                } finally {
                    dispatch({
                        type: "GET_DEST_UTILITIES",
                        data: utilities
                    })
                    return Promise.resolve(utilities)
                }
            })
            .catch(err => Promise.reject(err));
    };
}