import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory as history } from "history";
import Login from "./containers/Login";
import PersonalAccWrapper from "./containers/PersonalAccWrapper";
import MerchantAccWrapper from "./containers/MerchantAccWrapper";

import Signup from "./containers/Signup";
import Register from "./containers/Register";
import PrivateRoute from "./components/PrivateRoute";
import VcsApproved from "./containers/VcsApproved";
import ForgotPassword from "./containers/ForgotPassword";
import TsAndCs from "./containers/TsAndCs";
import Privacy from "./containers/Privacy";
import PaymentPage from "./containers/PaymentPage";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/deposit" component={PaymentPage} />
            <Route path="/topup/status" component={VcsApproved} />
            <PrivateRoute
              role={"personal"}
              component={PersonalAccWrapper}
              path="/personal"
            />
            <PrivateRoute
              role={"merchant"}
              component={MerchantAccWrapper}
              path="/merchant"
            />
            <Route exact path="/signup" component={Register} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/signup/profile" component={Signup} />
            <Route exact path="/terms" component={TsAndCs} />
            <Route exact path="/privacy-policy" component={Privacy} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
