import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import OTP from "../components/OTP";
import GoogleLogin from "react-google-login";
import googleIcon from "../img/Google-Symbol.png";
import { connect } from "react-redux";
import { pushLogin, pushLoginG } from "../store/actions/authActions";
import IntlTelInput from "react-bootstrap-intl-tel-input";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

class Login extends Component {
  state = {
    stage: 1,
    number: null,
    password: null,
    isLoging_in: false,
    isLoginFailed: false,
    user: null,
  };

  componentDidMount() {}

  submitLogin = (e) => {
    e.preventDefault();
    this.setState({ isLoging_in: true });
    this.props
      .login({ number: this.state.number, password: this.state.password })
      .then((res) => {
        this.setState({ stage: 2, user: res.user });
      })
      .catch((err) => {
        this.setState({ isLoging_in: false, isLoginFailed: true });
      });
  };

  onSignIn = (googleUser) => {
    var profile = googleUser.getBasicProfile();
    // This is null if the 'email' scope is not present.
    let body = {
      userId: profile.getId(),
      name: profile.getName(),
      propic: profile.getImageUrl(),
      email: profile.getEmail(),
      token: googleUser.getAuthResponse(),
    };
    // TODO::
    // Forward user info to backend
    this.props
      .glogin(body)
      .then(() => {
        this.setState({ isLoging_in: true });
        this.setState({ stage: 2 });
      })
      .catch((err) => {
        this.setState({
          isLoging_in: false,
          // isLoginFailed:true
        });
        console.log(err);
      });
  };

  onSignInFailure(result) {
    // console.log(result);
  }

  render() {
    return (
      <div>
        <div className="d-md-flex flex-row-reverse">
          <div className="signin-right">
            {this.state.stage === 1 ? (
              <div className="signin-box">
                <h2 className="signin-title-primary">Welcome back!</h2>
                <h5 className="signin-title-secondary">
                  {this.state.isLoging_in
                    ? "Signin you in..."
                    : this.state.isLoginFailed
                    ? "Failed, invalid number or password"
                    : "Sign in to continue."}
                </h5>
                <form onSubmit={(e) => this.submitLogin(e)}>
                  <div className="form-group">
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="BW"
                      countries={["BW"]}
                      value={this.state.number}
                      onChange={(data) => this.setState({ number: data })}
                    />
                    {/* <IntlTelInput
                preferredCountries={['BW']}
                defaultCountry={'BW'}
                // defaultValue={'+267 '}
                // placeholder={'77777777'}
                onChange={(data) => this.setState({number:data.intlPhoneNumber})}
              /> */}
                    {/* <input type="number" className="form-control" placeholder="Enter your mobile number" required onChange={e => this.setState({number:e.target.value})}/> */}
                  </div>
                  <div className="form-group mg-b-50">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter your password"
                      required
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                  <button
                    className="btn btn-primary btn-block btn-signin"
                    type="submit"
                  >
                    Sign In
                  </button>
                  <GoogleLogin
                    clientId="535839903901-guo2e1qa4a05nr5hklesfem22mi44o5b.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <button
                        className="p-0 btn"
                        onClick={() => renderProps.onClick()}
                        disabled={renderProps.disabled}
                        style={{
                          width: "-webkit-fill-available",
                          cursor: "pointer",
                          backgroundColor: "#1a73e8",
                          display: "inline-flex",
                          alignItems: "center",
                          color: "rgba(0, 0, 0, 0.54)",
                          boxShadow:
                            "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
                          padding: "0px",
                          borderRadius: "2px",
                          border: "1px solid transparent",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <div>
                          <span>
                            <img
                              src={googleIcon}
                              alt="logo"
                              width="55px"
                              style={{ background: "white" }}
                            ></img>
                          </span>
                          <span
                            style={{
                              color: "white",
                              padding: ".2em 1em",
                              marginLeft: "5em",
                            }}
                          >
                            Login with Google
                          </span>
                        </div>
                      </button>
                    )}
                    onSuccess={(e) => this.onSignIn(e)}
                    onFailure={(e) => this.onSignInFailure(e)}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={true}
                  ></GoogleLogin>
                  <p className="mg-b-0 mg-t-20">
                    Don't have an account?{" "}
                    <NavLink to="/signup">Sign Up</NavLink> |{" "}
                    <NavLink to="/forgotpassword">Forgot password</NavLink>
                  </p>
                </form>
              </div>
            ) : (
              <OTP number={this.state.number} user={this.state.user} />
            )}
          </div>
          <div
            className="signin-left"
            style={{ background: "#0b4381", color: "white" }}
          >
            <div className="signin-box">
              <img
                className="slim-logo"
                width="200px"
                src="/assets/img/dumelapay-clear.png"
              />
              {/* <h2 className="slim-logo"><NavLink to="#">Dumela<span style={{color:"#2ABF6B"}}>Pay.</span></NavLink></h2> */}
              <p>
                No more queues to send money or make payments - simplicity at
                your fingertips!!!
              </p>

              <p>
                Browse our site and see for yourself why you need Dumela Pay.
              </p>

              <p className="tx-12">
                &copy; Copyright 2022. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (body) => dispatch(pushLogin(body)),
    glogin: (body) => dispatch(pushLoginG(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
