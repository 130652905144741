//  google signin
//  basic auth signin
//  sign out
//  verify otp

import { client } from "./client";

export function verifyOTP(body) {
  return client.post("/api/account/v1/otp/verifybasic", body);
}

export function loginBasic(body) {
  return client.post("/api/account/v1/login", body);
}

export function loginG(body) {
  return client.post("/api/account/v1/login/google", body);
}

export function resetRequest(body) {
  return client.post("/api/account/v1/resetrequest", body);
}

export function reset(body) {
  return client.post("/api/account/v1/reset", body);
}

export function resendOTP(body) {
  return client.post("/api/account/v1/otp/resend", body);
}
